import React, { useEffect } from 'react';
//import ReactExport from 'react-export-excel';

export function RowExcelExporter(props) {
    const { dataSet, reset, filename } = props;

    useEffect(() => {
        if (dataSet) {
            reset()
        }
    })

    //NOTE: Change the excel export npm package
    // return dataSet && (
    //     <React.Fragment>
    //         <ReactExport.ExcelFile filename={filename} hideElement={true} >
    //             <ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={filename} />
    //         </ReactExport.ExcelFile>
    //     </React.Fragment>
    // )

    return dataSet
}