import axios from "axios";
import React, { useState, useEffect, useContext, useSearchParams } from "react";
import { Col, Row, Button, Alert, Modal } from "react-bootstrap";
import ErrorHandler from "../../core/ErrorHandler";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import Accordion from "../../Accordion";
import ManualRemittanceForm from "./ManualRemittanceForm";
import ClaimEntry from "./ClaimEntry";
import { Link } from "react-router-dom";
import SharedMainSelectableGrid from "../../grid/Kendo/SharedMainSelectableGrid";
import { DefaultColumnWidth, DefaultWideColumnWidth, DefaultBooleanOrShortWidthColumnWidth } from "../../grid/Kendo/KendoGridAndColumnConstants";
import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import { constants } from "./Constants";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { UserContext } from '../../context/UserContext'
import SingleCheckExcelExportWrapper from "../../export/SingleCheckExcelExportWrapper";
import SingleCheckPdfExportWrapper from "../../export/SingleCheckPdfExportWrapper";
import SharedMainSelectableGridNewImplementation, {
  AddGridButton,
} from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";
import { OrganizationTypes } from "../../../constants/OrganizationTypes";
import { Organization } from "../../layout/ScreenHeader/Organization";

export default function ManualRemittance(props) {

  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [submittedFormData, setSubmittedFormData] = useState(false);
  const [submittedClaimFormData, setSubmittedClaimFormData] = useState(false);
  const [showMessagePaymentMaster, setShowMessagePaymentMaster] = useState(true);
  const [incompleteManualRemittanceId, setIncompleteManualRemittanceId] = useState(null);
  const [orginalPaymentId, setOrginalPaymentId] = useState(0);
  const [pyId, setPyId] = useState(null);
  const [clearPaymentMaster, setClearPaymentMaster] = useState(false);
  const [clearClaimDetail, setClearClaimDetail] = useState(false);
  const [forceValue, setForceValue] = useState("0");
  const [enableAddClaimEntryButton, setEnableAddClaimEntryButton] = useState(false);
  const [createCheckData, setCreateCheckData] = useState(null);
  const [selectedClaimDetailIds, setSelectedClaimDetailIds] = useState("");
  const [claimDetail, setClaimDetail] = useState("");
  const [entryOption, setEntryOption] = useState({
    includePatientName: false,
    includePaymenttype: false,
  });
  const [mainGridData, setMainGridData] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);
  const [mainGridSelectedState, setMainGridSelectedState] = useState([]);
  const [showResetClaimsModal, setShowResetClaimsModal] = useState(false);
  const [showUnableLocateClaimsModal, setShowUnableLocateClaimsModal] = useState(false);
  const [createRemittanceConfirmationModal, setCreateRemittanceConfirmationModal] = useState(false);
  const [paymentExportModel, setPaymentExportModel] = useState(false);
  const [currentCheckData, setCurrentCheckData] = useState();
  const [querystringPmid, setQueryStringPmid] = useState();
  const [existingManualRemittancePmid, setExistingManualRemittancePmid] = useState();

  const userConfig = useContext(UserContext);

  const sendAggregatesToParent = (aggregatesFromSharedMainAgingGrid) => {
    setMainGridAggregates(aggregatesFromSharedMainAgingGrid);
  };

  const sendSelectablesToParent = async (selectablesFromSharedGrid) => {
    setMainGridSelectedState(selectablesFromSharedGrid);

    var getOnlyTrueKeys = selectablesFromSharedGrid;
    if (getOnlyTrueKeys.length < 1) {
      alert("No rows selected.");

      return null;
    }
    var allSelectedpmidCodes = "";
    getOnlyTrueKeys.forEach(function (key) {
      allSelectedpmidCodes += key + ",";
    });
    setSelectedClaimDetailIds(allSelectedpmidCodes);
    DeleteClaimDetails(allSelectedpmidCodes);
  };

  const getIncompleteManualRemittanceIfExists = () => {
    let accessToken = passedProps.auth.getAccessToken();

    axios
      .get("api/ManualRemittance/GetIncompleteManualRemittanceForUserEmail", {
        params: {
          email: userConfig.email,
          organizationId: userConfig.currentOrganization.value,
          organizationType: userConfig.currentOrganization.type
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(
        function (response) {
          if(response.data && response.data.mpmPmid){
            setExistingManualRemittancePmid(response.data.mpmPmid);
            setIncompleteManualRemittanceId(response.data.mpmPmid);
            GetClaimsForIncompleteManualRemittance(response.data.mpmPmid);

            // setEnableAddClaimEntryButton(true);
          }
        })
      .catch(onFailure);
  };

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let pmid = params.get('pmid');
    setQueryStringPmid(pmid);

    let retrievedIncompleteManualRemittance = null;

    if(!pmid){
      getIncompleteManualRemittanceIfExists();
    }

  }, []);


  const RemittanceLookupActionCell = (props) => {
    async function EditClick(event) {
      setPyId(props.dataItem.id)
    }

    return (
      <td className="noPadding" {...props.style}>
        <Row className="reducedHeight">
          <div className="col-sm-3">
            <Button variant="link" onClick={EditClick}>Edit</Button>
          </div>
        </Row>
      </td>
    );
  };


  useEffect(() => {
    if (querystringPmid != null) {
      getPMIDDetails(querystringPmid);
    }
  }, [querystringPmid]);

  useEffect(() => {
    if (existingManualRemittancePmid != null) {
      getPMIDDetails(existingManualRemittancePmid);
    }
  }, [existingManualRemittancePmid]);

  function getPMIDDetails(pmid) {
    setIsLoading(true);
    try {
      let accessToken = passedProps.auth.getAccessToken();

      axios
        .get(`api/ManualRemittance/GetManualRemittanceByPMID`, {
          params: {
            user: user,
            pMID: pmid,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(function (response) {
          setIsLoading(false);
          setCurrentCheckData(response.data);
        })
        .catch(onFailure);
    } catch (exception) {
    }
  }

  useEffect(() => {
    if (pyId) {
      // Post Data to API
      GetClaimData()
    }
  }, [pyId]);

  useEffect(() => {
    if (orginalPaymentId > 0) {
      // open popup for download pdf and excel
      setPaymentExportModel(true);
    }
  }, [orginalPaymentId]);

  useEffect(() => {
    if (submittedClaimFormData) {
      // Post Data to API
      if (incompleteManualRemittanceId != null && incompleteManualRemittanceId > 0 && pyId == 0) {
        SaveClaims(submittedClaimFormData, "0");
      }

      if (incompleteManualRemittanceId != null && incompleteManualRemittanceId > 0 && pyId > 0) {
        UpdateClaims(submittedClaimFormData, "0");
      }
    }
  }, [submittedClaimFormData]);


  function handleResetClaimsOkModal() {
    if (submittedFormData) {
      ResetCheckClaims(submittedFormData);
    }
    setShowResetClaimsModal(false);
  }

  async function downloadClick(type) {
    const accessToken = passedProps.auth.getAccessToken();

    if (type === "Excel") {
      await SingleCheckExcelExportWrapper(orginalPaymentId, accessToken, setIsLoading);
    }
    else if (type === "PDF") {
      await SingleCheckPdfExportWrapper(orginalPaymentId, accessToken, setIsLoading);
    }
  }

  function handleCreateRemittanceOkModal() {
    setCreateRemittanceConfirmationModal(false)
    GeneratePayments(submittedFormData);
  }

  function handleCreateRemittanceCancelModal() {
    setCreateRemittanceConfirmationModal(false)

  }
  function handleResetClaimsCancelModal() {
    setShowResetClaimsModal(false)
  }

  function handleUnableClaimsOkModal() {
    setForceValue("1");
    SaveClaims(submittedClaimFormData, "1");
    setShowUnableLocateClaimsModal(false);
  }
  function handleUnableClaimsCancelModal() {
    setForceValue("0");
    setShowUnableLocateClaimsModal(false);
  }

  function HandleClaimEntryFormSubmit(formInput) {
    setSubmittedClaimFormData(formInput);
    setPyId(formInput.id);
  }

 //NOTE: This is what fires off when the "Create Check" button is clicked.
  function handleFormSubmit(formInput) {
    setShowMessagePaymentMaster(true);
    setSubmittedFormData(formInput);
    setEnableAddClaimEntryButton(false);

    setCreateCheckData(formInput);
    CreateOrUpdateCheck(formInput);
  }

  function onlyUpdateSubmittedFormDataFromChild(input){
    setSubmittedFormData(input);
  }

  //NOTE: This is what fires off when the "Update Check" button is clicked
  function HandleUpdateCheckClick(formInput) {
    setCreateCheckData(formInput);
    CreateOrUpdateCheck(formInput);
  }

  function handleEntryOptionsChange(formInput) {
    setEntryOption(formInput);
  }

  function handleResetCheck(formInput) {
    DeletePaymentMaster(formInput);
  }

  function handleResetClaimOnClick() {
    setShowResetClaimsModal(true)
  }
  function handleCreateRemittanceOnClick() {
    setCreateRemittanceConfirmationModal(true)
  }

  const sendAccordionParent = (props) => {
    setExpanded(props);
  };

  function GetClaimData() {
    if (pyId > 0 && incompleteManualRemittanceId > 0) {
      try {
        setIsLoading(true);
        let accessToken = passedProps.auth.getAccessToken();

        axios
          .get(`api/ManualRemittance/GetClaimById`, {
            params: {
              user: user,
              pyId: pyId,
              pMID: incompleteManualRemittanceId,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(function (response) {
            setIsLoading(false);
            setClaimDetail(response.data.claimDetails);
          })
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
        setIsLoading(false);
      }
    }
  }

  //NOTE: This is when the user "creates an incomplete manual remittance, adds row to dbo.ManualPaymentMaster table"
  function CreateOrUpdateCheck(formInput) {
    if (formInput) {
      setIsLoading(true);
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios.post("api/ManualRemittance/CreateOrUpdateIncompleteManualRemittance", {
          user: user,
          checkAmount: formInput.checkAmount,
          checkDate: formInput.checkDate,
          checkNumber: formInput.checkNumber,
          endDate: formInput.endDate,
          paymentDate: formInput.paymentDate,
          selectedLocations: formInput.selectedLocations,
          payerId: formInput.selectedPayers,
          startDate: formInput.startDate,
          includePatientName: formInput.includePatientName,
          includePaymenttype: formInput.includePaymenttype,
          includeDisabledWarning: formInput.disableClaimWarning,
          pMID: formInput.paymentId,
          shouldUpdateCheck: formInput.shouldUpdateCheck,
          shouldUpdateClaims: formInput.shouldUpdateClaims,
          gPID:
          (
            (
            userConfig.contactType == 'GCT' || userConfig.contactType == 'UCT'
            )
          && userConfig.currentOrganization.type == OrganizationTypes.Group
        ) ? userConfig.currentOrganization.value : null,
        }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function (response) {
          setIsLoading(false);
          if (response.data) {
            setIncompleteManualRemittanceId(response.data);
            setShowMessagePaymentMaster(false);
            setSubmittedFormData(formInput);
            if (formInput.paymentId == null) {
              setError({ status: 200, Message: "New Check Information created." });
            }
            else {
              setError({ status: 200, Message: "Check Information updated." });
            }

            setIncompleteManualRemittanceId(response.data);
            GetClaimsForIncompleteManualRemittance(response.data);
          }
        }).catch(onFailure);
      } catch (exception) {
        onFailure(exception);
        setIsLoading(false);
      }
    }

  }

  function GetClaimsForIncompleteManualRemittance(manualRemittancePmid) {
    setIsLoading(true);
    try {
      let accessToken = passedProps.auth.getAccessToken();

      axios.get("api/ManualRemittance/GetClaimsForIncompleteManualRemittance", {
        params: {
          user: user,
          pmid: manualRemittancePmid
        },
        headers: { Authorization: `Bearer ${accessToken}` }
      }
      )
      .then(onSuccess)
      .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
      setIsLoading(false);
    }
  }

  function ResetCheckClaims(formInput) {
    setIsLoading(true);
    if (formInput) {
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios.post("api/ManualRemittance/CreateOrUpdateIncompleteManualRemittance", {
          user: user,
          checkAmount: formInput.checkAmount,
          checkDate: formInput.checkDate,
          checkNumber: formInput.checkNumber,
          endDate: formInput.endDate,
          paymentDate: formInput.paymentDate,
          selectedLocations: formInput.selectedLocations,
          payerId: formInput.selectedPayers,
          startDate: formInput.startDate,
          includePatientName: formInput.includePatientName,
          includePaymenttype: formInput.includePaymenttype,
          includeDisabledWarning: formInput.disableClaimWarning,
          pMID: incompleteManualRemittanceId,
          shouldUpdateCheck: false,
          shouldUpdateClaims: true
        }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function (response) {
          setIsLoading(false);
          if (response.data) {
            setIncompleteManualRemittanceId(response.data);
            GetClaimsForIncompleteManualRemittance(response.data);
            setError({ status: 200, Message: "Claim reset successfully." });
          }
        }).catch(onFailure);
      } catch (exception) {
        onFailure(exception);
        setIsLoading(false);
      }
    }

  }

  useEffect(() => {
    if (clearPaymentMaster) {
      setClearPaymentMaster(false);
    }
  }, [clearPaymentMaster]);

  function clearAllForm() {
    setIncompleteManualRemittanceId(0);
    setPyId(0);
    setClearClaimDetail(true);
    setClearPaymentMaster(true);
    setMainGridData([]);
    setMainGridAggregates([]);
    setMainGridSelectedState([]);
    setEnableAddClaimEntryButton(false);
  }

  function GeneratePayments(formInput) {
    if (formInput && userConfig.currentOrganization) {
      setIsLoading(true);
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios.post(`api/ManualRemittance/GeneratePaymentEntry`, {
          user: user,
          checkAmount: formInput.checkAmount,
          selectedLocations: formInput.selectedLocations,
          mMID: '0', // As per legacy code, we pass 0 for mMID
          pMID: incompleteManualRemittanceId,
          gPID: (userConfig.contactType == 'GCT' || userConfig.contactType == 'UCT') ? userConfig.currentOrganization.value : null,
          total: getTotal("paidAmount"),
          adjustment: formInput.checkAmount - getTotal("paidAmount"),
          paidAmount: 0, // As per legacy code, we pass 0 for paidAmount
          pYID: 0 // As per legacy code, we pass 0 for pYID
        }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function (response) {
          if (response.data > 0) {
            setIsLoading(false);
            setOrginalPaymentId(response.data);
            setError({ status: 200, Message: "New payment generated successfully." });
            clearAllForm();
          } else {
            setError({ status: 500, Message: "Unable to generate payment." });
          }
        }).catch(onFailure);
      } catch (exception) {
        onFailure(exception);
        setIsLoading(false);
      }
    }
  }

  function SaveClaims(formInput, force) {
    if (formInput) {
      setIsLoading(true);
      try {
        let accessToken = passedProps.auth.getAccessToken();

        let apiProps = {
          user: user,
          pmid: incompleteManualRemittanceId,
          mmids: submittedFormData.selectedLocations,
          pbid: submittedFormData.selectedPayers,
          ncpdp: formInput.selectedLocations,
          rxNum: formInput.script,
          serviceDate: formInput.dispDate,
          fee: formInput.fee,
          paidAmount: formInput.paidAmount,
          force: force,
          firstName: formInput.firstName,
          middleName: formInput.middleName,
          lastName: formInput.lastName,
          paymentTypeId: formInput.paymentType,
          disableClaimWarn: submittedFormData.disableClaimWarning,
        };

        console.log(apiProps);

        axios.post("api/ManualRemittance/InsertClaimEntry",
          apiProps,
          {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function (response) {
          setIsLoading(false);
          setClearClaimDetail(true)
          if (response.data) {

            setError({ status: 200, Message: "Claim records created." });
            GetClaimsForIncompleteManualRemittance(incompleteManualRemittanceId);
          }
          else {
            //confirm popup
            //setForce("0");
            setShowUnableLocateClaimsModal(true);
          }
        }).catch(onFailure);

      } catch (exception) {
        console.log("hey see this here");
        onFailure(exception);
        setIsLoading(false);
      }
    }
    setClearClaimDetail(false);
  }

  function UpdateClaims(formInput, force) {
    if (formInput) {
      setIsLoading(true);
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios.post(`api/ManualRemittance/UpdateClaimEntry`, {
          user: user,
          pMID: incompleteManualRemittanceId,
          pyid: pyId,
          pBID: submittedFormData.selectedPayers,
          nCPDP: formInput.selectedLocations,
          rXNUM: formInput.script,
          serviceDate: formInput.dispDate,
          fee: formInput.fee,
          paidAmount: formInput.paidAmount,
          force: force,
          firstName: formInput.firstName,
          middleName: formInput.middleName,
          lastName: formInput.lastName,
          paymentTypeId: formInput.paymentType,

        }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function (response) {
          if (response.data) {
            setIsLoading(false);
            GetClaimsForIncompleteManualRemittance(incompleteManualRemittanceId);
            setClearClaimDetail(true);
            setError({ status: 200, Message: "Claim records Updated." });
          }
          else {
            setError({ status: 200, Message: "Unable to update the Claim records." });
          }
        }).catch(onFailure);
      } catch (exception) {
        onFailure(exception);
        setIsLoading(false);
      }
    }
    setClearClaimDetail(false);
  }

  //NOTE: This is the original logic
  function DeleteClaimDetails(manualPaymentIds) {
    if (manualPaymentIds != "" && incompleteManualRemittanceId != 0) {
      setIsLoading(true);
      try {
        let accessToken = passedProps.auth.getAccessToken();

        console.log(submittedFormData);
        
        axios.post(`api/ManualRemittance/DeleteManualRemittanceDetail`, {
          user: user,
          pMID: incompleteManualRemittanceId,
          selectedLocations: submittedFormData.selectedLocations,
          selectedManualPaymentDetailIds: manualPaymentIds,

        }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
        setIsLoading(false);
      }
    }
  }

  function GetGridButtons() {
    let buttonsArray = [];

    buttonsArray = AddGridButton(
      buttonsArray,
      "Remove Selected",
      sendSelectablesToParent
    );

    return buttonsArray;
  }
  const gridButtons = GetGridButtons();

  function DeletePaymentMaster(formInput) {
    if (incompleteManualRemittanceId > 0) {
      setIsLoading(true);
      try {
        let accessToken = passedProps.auth.getAccessToken();

        axios.post(`api/ManualRemittance/DeleteManualRemittanceAll`, {
          user: user,
          pMID: formInput.paymentId,
          selectedLocations: formInput.selectedLocations,
          selectedManualPaymentDetailIds: ""
        }, {
          headers: { Authorization: `Bearer ${accessToken}` },
        }).then(function (response) {
          setIsLoading(false);
          if (response.data) {
            setClearClaimDetail(true);
            setClearPaymentMaster(true);
            setIncompleteManualRemittanceId(null);
            setError({ status: 200, Message: "Manual payment master records deleted." });
          }
          else {
            setError({ status: 500, Message: "Unable to delete the manual payment master." });
          }
          setMainGridData([]);

        }).catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    } else {
      setClearPaymentMaster(true);
    }
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/ManualRemittance/GetClaimsForIncompleteManualRemittance":
        setIsLoading(false);
        if (response.data) {
          presentGridData(response.data);
        }
        setEnableAddClaimEntryButton(true);
        break;
      case "api/ManualRemittance/DeleteManualRemittanceDetail":
        if (response.data) {
          setError({ status: 200, Message: "Claim records deleted." });
        }
        else {
          setError({ status: 500, Message: "Unable to delete the claims." });
        }

        GetClaimsForIncompleteManualRemittance(incompleteManualRemittanceId);
        break;
      default:
        break;
    }
  };

  function onFailure(error) {
    setIsLoading(false);
    setError(error.response);

    switch (error.response.config.url) {
      case "api/api/ManualRemittance/GetClaimsForIncompleteManualRemittance":
        presentGridData([]);
        break;
      default:
        break;
    }

    return;
  }

  function presentGridData(data) {
    // let columns = [];

    if (data.length > 0) {
      let headerProps = constants.mainGridHeaderProps;

      let dataWithCleanedDates = data.map((t) => {
        return {
          ...t,
          dispensedDate: IsNullOrWhitespace(t.dispensedDate)
            ? ""
            : new Date(Date.parse(t.dispensedDate))
        };
      });

      setMainGridData(dataWithCleanedDates);
      let aggregateSettings = [
        { aggregate: "sum", field: "adjustment" },
        { aggregate: "sum", field: "paidAmount" },
        { aggregate: "sum", field: "outStandingAmount" },
        { aggregate: "sum", field: "transactionFee" },
      ];

      setMainGridMoneyColumnAggregateSettings(aggregateSettings);
    } else {
      setMainGridData([]);
    }

    setIsLoading(false);
  }
  const MainGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };
    return AggregateMoneyFooterCell(props, options);
  };

  function getTotal(field) {
    let displayAggregateValue = 0;
    if (field && mainGridAggregates) {
      if (mainGridAggregates[field]) {
        displayAggregateValue = mainGridAggregates[field].sum;
      }
    }
    return displayAggregateValue;
  }

  return (<React.Fragment>
    <div className="feature">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"ManualRemittance"}
      />
      <canvas
        id="hiddenCanvasForSingleCheckPdf"
        style={{ display: "none" }}
        width={185}
        height={70}
      />

      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />

      <Accordion
        defaultExpanded
        label="Check Information"
        sendAccordionParentCallback={sendAccordionParent}
      >
        <Row>
          <Col>
            <ManualRemittanceForm
            handleFormSubmit={handleFormSubmit} 
            handleEntryOptionsChange={handleEntryOptionsChange} 
            paymentId={incompleteManualRemittanceId}
            handleUpdateCheck={HandleUpdateCheckClick}
            handleResetCheck={handleResetCheck}
            clearAll={clearPaymentMaster}
            passedProps={passedProps}
            currentCheckData={currentCheckData}
            onlyUpdateSubmittedFormDataFromChild={onlyUpdateSubmittedFormDataFromChild}
            />
          </Col>
        </Row>
      </Accordion>

      <Accordion
        defaultExpanded
        label="Claim Entry"
      >
        <Row>
          <Col>
            <ClaimEntry handleFormSubmit={HandleClaimEntryFormSubmit} claimDetail={claimDetail} clearAll={clearClaimDetail} entryOptionProp={entryOption} enableAddClaimEntryButton={enableAddClaimEntryButton} />
          </Col>
        </Row>
      </Accordion>
      <Row className="justify-content-md-end">
        <Col md="auto">
          <Button variant="primary" onClick={handleResetClaimOnClick} disabled={!enableAddClaimEntryButton}>
            Reset Claims
          </Button>
        </Col>
        <Col md="auto" >
          <Button variant="primary" onClick={handleCreateRemittanceOnClick} disabled={!enableAddClaimEntryButton} >
            Create Remittance
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="tight-grid multi-line-filter">
          <SharedMainSelectableGridNewImplementation
            data={mainGridData}
            dataItemKey={"id"}
            aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
            sendAggregatesParentCallback={sendAggregatesToParent}
            sendSelectablesParentCallback={sendSelectablesToParent}
            // selectableButtonTitle={"Remove Selected"}
            exportFileNamePrefix={"ManualRemittance"}
            selectionColumntitle={"Remove"}
            gridButtons={gridButtons}
            pageSize={100}
          >
            <GridColumn
              field=""
              title="Action"
              filterable={false}
              sortable={false}
              groupable={false}
              width={DefaultWideColumnWidth()}
              cells={{ data: RemittanceLookupActionCell }}
            />
            <GridColumn field="script" title="Script" width={DefaultColumnWidth()} />
            <GridColumn
              key={"ncpdp"}
              field="ncpdp"
              title="NCPDP"
              width={DefaultColumnWidth()}
            />
            <GridColumn
              key={"dispensedDate"}
              field="dispensedDate"
              title="Dispensed Date"
              filter="date"
              format="{0:d}"
              width={DefaultColumnWidth()}
            />
            <GridColumn
              key={"adjustment"}
              field="adjustment"
              title="Promise to Pay"
              format="{0:c}"
              filter="numeric"
              cells={{
                data: MoneyCell,
                footerCell: MainGridAggregateMoneyFooterCell,
              }}
              width={DefaultColumnWidth()}
            />
            <GridColumn
              key={"paidAmount"}
              field="paidAmount"
              title="Paid Amount"
              format="{0:c}"
              filter="numeric"
              cells={{
                data: MoneyCell,
                footerCell: MainGridAggregateMoneyFooterCell,
              }}
              width={DefaultColumnWidth()}
            />
            <GridColumn
              key={"outStandingAmount"}
              field="outStandingAmount"
              title="Outstanding"
              filter="numeric"
              width={DefaultColumnWidth()}
              cells={{
                data: MoneyCell,
                footerCell: MainGridAggregateMoneyFooterCell,
              }}
            />
            <GridColumn
              key={"transactionFee"}
              field="transactionFee"
              title="Transaction Fee"
              filter="numeric"
              width={DefaultColumnWidth()}
              cells={{
                data: MoneyCell,
                footerCell: MainGridAggregateMoneyFooterCell,
              }}
            />
            {entryOption.includePatientName ? (
              <GridColumn
                key={"firstName"}
                field="firstName"
                title="First Name"
                width={DefaultColumnWidth()}
              />) : ("")}

            {entryOption.includePatientName ? (
              <GridColumn
                key={"middleName"}
                field="middleName"
                title="Middle Name"
                width={DefaultColumnWidth()}
              />) : ("")}

            {entryOption.includePatientName ? (
              <GridColumn
                key={"lastName"}
                field="lastName"
                title="Last Name"
                width={DefaultColumnWidth()}
              />) : ("")}

            {entryOption.includePaymenttype ? (
              <GridColumn
                key={"paymentType"}
                field="paymentType"
                title="Payment Type"
                width={DefaultColumnWidth()}
              />) : ("")}
          </SharedMainSelectableGridNewImplementation>
        </Col>
      </Row>
    </div>

    <Modal show={showResetClaimsModal} onHide={() => { setShowResetClaimsModal(false) }}>
      <Modal.Header closeButton>
        <h4>Reset Claim for manual remittance</h4>
      </Modal.Header>
      <Modal.Body>
        Clicking 'Ok' will reset claims for this manual remittance using date range and selected list of NCPDP's
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleResetClaimsOkModal}>
          Ok
        </Button>
        <Button variant="secondary" onClick={handleResetClaimsCancelModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showUnableLocateClaimsModal} onHide={() => { setShowUnableLocateClaimsModal(false) }}>
      <Modal.Header closeButton>
        <h4>Claim Warning</h4>
      </Modal.Header>
      <Modal.Body>
        Unable to locate claim. Click 'Ok' to continue loading
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleUnableClaimsOkModal}>
          Ok
        </Button>
        <Button variant="secondary" onClick={handleUnableClaimsCancelModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={createRemittanceConfirmationModal} onHide={() => { setCreateRemittanceConfirmationModal(false) }}>
      <Modal.Header closeButton>
        <h4>Post Remittance Advice</h4>
      </Modal.Header>
      <Modal.Body>
        {getTotal("paidAmount") > 0 ? "Click 'Ok' to post remittance with an adjustment of $" + (parseFloat(parseFloat(submittedFormData.checkAmount) - getTotal("paidAmount")).toFixed(2))
          : "Click 'Ok' to post remittance."}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCreateRemittanceOkModal}>
          Ok
        </Button>
        <Button variant="secondary" onClick={handleCreateRemittanceCancelModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={paymentExportModel} onHide={() => { setPaymentExportModel(false) }}>
      <Modal.Header closeButton>
        <h4>Print Remittance Advice</h4>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="link" onClick={() => downloadClick('Excel')}>
          Download Excel
        </Button>
        <Button variant="link" onClick={() => downloadClick('PDF')}>
          Download PDF
        </Button>
        <Button variant="secondary" onClick={() => {
          setPaymentExportModel(false)
        }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
  );
}