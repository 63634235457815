import React, { Component } from 'react';

import './LoadingSpinner.css';

export class LoadingSpinner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.controlsName + "-internal-spinner",
            overlayId: this.props.controlsName + "-internal-overlay"
        }
    }

    componentDidUpdate() {
        let overlay = document.getElementById(this.state.overlayId);

        if (this.props.isDataLoading) {
            overlay.style.display = 'block';
        }
        else {
            overlay.style.display = 'none';
        }
    }

    render() {

        return (
            <div className="loading-overlay" id={this.state.overlayId} role="loading-spinner">
                <div className="loading-dot-container" id={this.state.id}>
                    <div className="loading-dot-wrapper">
                        <div className="loading-dot"></div>
                    </div>
                    <div className="loading-dot-wrapper">
                        <div className="loading-dot delayed-animation"></div>
                    </div>
                    <div className="loading-dot-wrapper">
                        <div className="loading-dot slower-delayed-animation"></div>
                    </div>
                </div>
            </div>
        )

    }
}