import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';

import { loadScript, isLoaded } from '../SiSense/SiSenseScriptLoader'
import Dashboard from './Dashboard'


export function FeatureWidget(props) {
    const { title, iFrameId, filters, dashboard, widget, settings, hidden, size="sm" } = props

    const [scriptLoaded, setScriptLoaded] = useState(false)

    useEffect(() => {
        if (isLoaded()) {
            setScriptLoaded(true)
        } else {
            loadScript().then(() => {
                setScriptLoaded(true)
            }).catch(e => console.log(e))
        }
    }, [])

    //NOTE: This is the default size
    let cardStyle;
    let cardBodyStyle; 

    switch (size) {
        case "sm":
            cardStyle = { width: '30rem', height: '16rem', float: 'right' };
            cardBodyStyle = { padding: '5px' };
            //NOTE: Small widgets are the default
            break;
        case "md":
            cardStyle = { width: '40rem', height: '16rem', float: 'right' };
            cardBodyStyle = {};
            break;
        case "lg":
            cardStyle = { width: '50rem', height: '16rem', float: 'right' };
            cardBodyStyle = {};
            break;
        default:
            cardStyle = { width: '30rem', height: '16rem', float: 'right' };
            cardBodyStyle = { padding: '5px' };
            break;
    }

    return (
        <Card style={cardStyle}>
            <Card.Body style={cardBodyStyle} >
                <Card.Title className="text-center" >{title}</Card.Title>
                <div className="dashboard-widget-wrapper">
                    <Dashboard
                        scriptLoaded={scriptLoaded}
                        iFrameId={iFrameId}
                        filters={filters}
                        dashboard={dashboard}
                        widget={widget}
                        settings={settings}
                        hidden={hidden}
                    />
                </div>
            </Card.Body>
        </Card>
    )
}