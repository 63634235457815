import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover , Form} from 'react-bootstrap'
import classNames from 'classnames'
import './HeaderCell.css'

export default function HeaderCell(props) {
    const {
        title,
        accessor,
        sorted,
        desc,
        type,
        leftOffset,
        handleCellClick,
        headerInfo,
        hidden,
    } = props

    if (hidden) {
        return null;
    }

    function getContent() {
        if (type === 'custom') {
            return (
                <React.Fragment>{title}</React.Fragment>
            )
        }

        return (
            <React.Fragment>
                {title}
                <SortIcon sorted={sorted} desc={desc} />
            </React.Fragment>
        )
    }
    // for frozen columns
    const styles = leftOffset > 0 ? {
        transform: `translate(${leftOffset}px, 0px)`,
        position: "relative",
        zIndex: 1
    } : null

    let className = classNames('header-cell', { 'text': type === 'cell' }, { 'number': type === 'number' }, { 'header-cell--money': type === 'money' })

    let content = getContent()
    return (
        <div style={styles} className={className}>
            <span onClick={() => handleCellClick(accessor, type)}>
                {content}
            </span>
            <InfoIcon headerInfo={headerInfo} />

        </div>
    )
}

HeaderCell.propTypes = {
    title: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    sorted: PropTypes.bool.isRequired,
    desc: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'checkbox', 'date', 'custom']).isRequired,
    leftOffset: PropTypes.number.isRequired,
    handleCellClick: PropTypes.func.isRequired,
    headerInfo: PropTypes.string
}

function SortIcon(props) {
    const { sorted, desc } = props

    let className = sorted ? (desc ? ' fa fa-sort-down' : ' fa fa-sort-up') : ' fa fa-sort';
    return (
        <i className={'sort-icon' + className} />
    )
}

SortIcon.propTypes = {
    sorted: PropTypes.bool.isRequired,
    desc: PropTypes.bool.isRequired
}

function InfoIcon(props) {
    const { headerInfo } = props

    if (headerInfo === undefined) return null

    let overlay = (
        <Popover id="popover-basic">
            <Popover.Content>
                {headerInfo}
            </Popover.Content>
        </Popover>
    )
    return ( 
        <span>
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={overlay}
                rootClose
            >
                <i className="header-info-popover-icon fa fa-info-circle" />
            </OverlayTrigger>
        </span>
    )
}

InfoIcon.propTypes = {
    headerInfo: PropTypes.string
}