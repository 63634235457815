function IsNullOrWhitespace(input) {
  return !input || input.trim().length < 1;
}

function HasSpecialCharacters(val) {
  var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
  return pattern.test(val);
}

export { IsNullOrWhitespace, HasSpecialCharacters };
