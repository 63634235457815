export const constants = {
    headerProps:
    {
        payerName: { title: 'Payer' },
        checkNumber: { title: 'Check Number' },
        checkAmount: { title: 'Amount', type: 'money', showTotal: true, },
        checkDate: { title: 'Check Date', type: 'date' },
        remitDate: { title: 'Remit Date', type: 'date' },
        paymentDate: { title: 'Payment Date', type: 'date' },
        archiveDate: { title: 'Archive Date', type: 'date' },
        loadDate: { title: 'Load Date', type: 'date' },
        exportDate: { title: 'Export Date', type: 'date' },
        ncpdp: { title: 'NCPDP' },
        batchId: { title: 'BatchID' },
        exportStatus: { title: 'Export Status' },
        exportFileName: { title: 'Export File' },
    },
}