export function formatCheckDetailsForExport(checkData) {

    function formatter(value, type) {
        switch (type) {
            case 'date': return formatDate(value);
            case 'money': return formatMoney(value);
            case 'percent': return formatPercent(value);
            case 'number': return value.toString();
            default: return value;
        }
    }

    function formatMoney(cellValue) {
        let currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return currency.format(cellValue).toString();
    }

    function formatPercent(cellValue) {
        let percent = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return percent.format(cellValue).toString();
    }

    function formatDate(cellValue) {
        if (cellValue) {
            let dateValue = new Date(cellValue);
            let date = new Intl.DateTimeFormat('en-US').format(dateValue);
            return date.toString();
        }
        return { text: "" };
    }

    let multiDataSet = [];

    multiDataSet.push(
        {
            columns: ["Payer", "Payee", "Payment"],
            data: [
                [checkData.payerName, checkData.parentCompanyName, "Reference: " + checkData.checkNumber],
                [checkData.payerAddressLine1, checkData.parentCompanyAddressLine1, "Date: " + formatter(checkData.checkDate, 'date')],
                [
                    checkData.payerCity + ", " + checkData.payerState + " " + checkData.payerZip,
                    checkData.parentCompanyCity + ", " + checkData.parentCompanyState + " " + checkData.parentCompanyZip,
                    "Amount: " + formatter(checkData.checkAmount, 'money')
                ],
            ]
        },
    );

    let additionalPaymentDataGroupedByPyid = {};

    for (let i = 0; i < checkData.paymentDataSection.length; i++) {
        let currentRow = checkData.paymentDataSection[i];

        additionalPaymentDataGroupedByPyid[currentRow.pyid] = {};
    }

    let maxPaymentAmtSectionsPerPyid = 0;

    for (let j = 0; j < checkData.paymentAmtSection.length; j++) {
        let currentRow = checkData.paymentAmtSection[j];

        if (!additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentAmtSection) {
            additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentAmtSection = [];
        }

        additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentAmtSection.push(currentRow);

        if (maxPaymentAmtSectionsPerPyid < additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentAmtSection.length) {
            maxPaymentAmtSectionsPerPyid = additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentAmtSection.length;
        }
    }

    let maxPaymentSvcSectionsPerPyid = 0;

    for (let k = 0; k < checkData.paymentSvcSection.length; k++) {
        let currentRow = checkData.paymentSvcSection[k];

        if (!additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentSvcSection) {
            additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentSvcSection = [];
        }

        additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentSvcSection.push(currentRow);

        if (maxPaymentSvcSectionsPerPyid < additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentSvcSection.length) {
            maxPaymentSvcSectionsPerPyid = additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentSvcSection.length;
        }

    }

    let maxPaymentFeeSectionsPerPyid = 0;

    for (let m = 0; m < checkData.paymentFeeCodeSection.length; m++) {
        let currentRow = checkData.paymentFeeCodeSection[m];

        if (!additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentFeeCodeSection) {
            additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentFeeCodeSection = [];
        }

        additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentFeeCodeSection.push(currentRow);

        if (maxPaymentFeeSectionsPerPyid < additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentFeeCodeSection.length) {
            maxPaymentFeeSectionsPerPyid = additionalPaymentDataGroupedByPyid[currentRow.pyid].paymentFeeCodeSection.length;
        }

    }

    let paymentData = {
        ySteps: 1,
        columns: [
            "Rx Number",
            "Dispensed Date",
            "Type",
            "Billed Amount",
            "Copay Amount",
            "GC",
            "RC",
            "Adjustment Amount",
            "Paid Amount",
            "Claim Number",
            "NCPDP",
            "Patient Name"
        ],
        data: []
    }

    if (maxPaymentAmtSectionsPerPyid > 0) {
        for (let i = 0; i < maxPaymentAmtSectionsPerPyid; i++) {
            if (maxPaymentAmtSectionsPerPyid === 1) {
                paymentData.columns.push("AMT Code");
                paymentData.columns.push("AMT Amount");
            }
            else {
                //NOTE: I'm assuming the average pharmacy user isn't familiar with zero-based indexing
                paymentData.columns.push("AMT Code " + (i + 1));
                paymentData.columns.push("AMT Amount " + (i + 1));
            }
        }
    }

    paymentData.columns.push("Policy Number");

    if (maxPaymentSvcSectionsPerPyid > 0) {
        for (let i = 0; i < maxPaymentSvcSectionsPerPyid; i++) {
            if (maxPaymentSvcSectionsPerPyid === 1) {
                paymentData.columns.push("Service Payment Id");
                paymentData.columns.push("Service Billed Amount");
                paymentData.columns.push("Service Payment Amount");
                paymentData.columns.push("Service Payment Quantity");
            }
            else {
                paymentData.columns.push("Service Payment Id " + (i + 1));
                paymentData.columns.push("Service Billed Amount " + (i + 1));
                paymentData.columns.push("Service Payment Amount " + (i + 1));
                paymentData.columns.push("Service Payment Quantity " + (i + 1));
            }
        }
    }

    if (maxPaymentFeeSectionsPerPyid > 0) {
        for (let i = 0; i < maxPaymentFeeSectionsPerPyid; i++) {
            if (maxPaymentFeeSectionsPerPyid === 1) {
                paymentData.columns.push("Adjust Group");
                paymentData.columns.push("Adjust Reason");
                paymentData.columns.push("Adjust Amount");
                paymentData.columns.push("Adjust Quantity");
            }
            else {
                paymentData.columns.push("Adjust Group" + (i + 1));
                paymentData.columns.push("Adjust Reason" + (i + 1));
                paymentData.columns.push("Adjust Amount" + (i + 1));
                paymentData.columns.push("Adjust Quantity" + (i + 1));
            }
        }
    }

    let paymentDataRows = [];

    //NOTE: Unlike the PDF export, the numeric values in the Excel export are not cast to dollar value strings because the users typically do numeric calculations with the Excel export
    for (let i = 0; i < checkData.paymentDataSection.length; i++) {
        let currentRow = checkData.paymentDataSection[i];
        let additionalData = additionalPaymentDataGroupedByPyid[currentRow.pyid];

        //NOTE: If the payment has fee codes, the fee code values have to be combined together
        let potentialFeeCodes = additionalData.paymentFeeCodeSection;
        let groupString = "";
        let reasonString = "";

        if (potentialFeeCodes) {
            for (let j = 0; j < potentialFeeCodes.length; j++) {
                let currentFeeCode = potentialFeeCodes[j];

                if (j === potentialFeeCodes.length - 1) {
                    groupString = groupString + currentFeeCode.group;
                    reasonString = reasonString + currentFeeCode.reason;
                }
                else {
                    groupString = groupString + currentFeeCode.group + "; ";
                    reasonString = reasonString + currentFeeCode.reason + "; ";
                }

            }
        }

        let valuesForRow = [
            currentRow.rxNumber,
            formatter(currentRow.serviceDate, 'date'),
            currentRow.type,
            formatter(currentRow.billed, 'money'),
            formatter(currentRow.copay, 'money'),
            groupString,
            reasonString,
            formatter(currentRow.adjusted, 'money'),
            formatter(currentRow.paid, 'money'),
            currentRow.claimNumber,
            currentRow.ncpdp,
            currentRow.patientLastName + ", " + currentRow.patientFirstName,
        ];

        //NOTE: Here, we have to add data for EACH available PaymentAmt segment; add blanks as needed
        for (let j = 0; j < maxPaymentAmtSectionsPerPyid; j++) {
            if (additionalData.paymentAmtSection) {
                let potentialSegment = additionalData.paymentAmtSection[j];

                if (potentialSegment) {
                    valuesForRow.push(potentialSegment.amountCode);
                    valuesForRow.push(potentialSegment.amtAmount);
                }
                else {
                    valuesForRow.push("");
                    valuesForRow.push("");
                }

            }
            else {
                valuesForRow.push("");
                valuesForRow.push("");
            }

        }

        valuesForRow.push(currentRow.policyNumber);

        //NOTE: Here, we have to add data for EACH available PaymentSvc segment; add blanks as needed
        for (let k = 0; k < maxPaymentSvcSectionsPerPyid; k++) {
            if (additionalData.paymentSvcSection) {
                let potentialSegment = additionalData.paymentSvcSection[k];

                if (potentialSegment) {
                    valuesForRow.push(potentialSegment.svcId);
                    valuesForRow.push(potentialSegment.billedAmount);
                    valuesForRow.push(potentialSegment.paymentAmount);
                    valuesForRow.push(potentialSegment.paymentQuantity);
                }
                else {
                    valuesForRow.push("");
                    valuesForRow.push("");
                    valuesForRow.push("");
                    valuesForRow.push("");
                }

            }
            else {
                valuesForRow.push("");
                valuesForRow.push("");
                valuesForRow.push("");
                valuesForRow.push("");
            }

        }

        //NOTE: Here, we have to add data for EACH available fee code segment; add blanks as needed
        for (let j = 0; j < maxPaymentFeeSectionsPerPyid; j++) {
            if (additionalData.paymentFeeCodeSection) {
                let potentialSegment = additionalData.paymentFeeCodeSection[j];

                if (potentialSegment) {
                    valuesForRow.push(potentialSegment.group);
                    valuesForRow.push(potentialSegment.reason);
                    valuesForRow.push(potentialSegment.amount);
                    valuesForRow.push(potentialSegment.quantity);
                }
                else {
                    valuesForRow.push("");
                    valuesForRow.push("");
                    valuesForRow.push("");
                    valuesForRow.push("");
                }

            }
            else {
                valuesForRow.push("");
                valuesForRow.push("");
                valuesForRow.push("");
                valuesForRow.push("");
            }

        }

        paymentDataRows.push(valuesForRow);
    }

    paymentData.data = paymentDataRows;

    multiDataSet.push(paymentData);

    //NOTE: This is the check adjustment section
    let checkAdjustmentHeader = {
        ySteps: 1,
        columns: ["Check Adjustments"],
        //NOTE: It needs an empty data set, or else it just fails
        data: []
    }

    multiDataSet.push(checkAdjustmentHeader);

    let checkAdjustmentData = {
        columns: ["Facility", "Reason", "Amount"],
        data: []
    }

    let checkAdjustmentDataRows = []

    for (let j = 0; j < checkData.checkAdjustmentSection.length; j++) {
        let currentCheckAdjustment = checkData.checkAdjustmentSection[j];

        checkAdjustmentDataRows.push(
            [
                currentCheckAdjustment.memberName,
                currentCheckAdjustment.adjustmentCode,
                formatter(currentCheckAdjustment.amount, 'money')
            ]
        );
    }

    checkAdjustmentData.data = checkAdjustmentDataRows;

    multiDataSet.push(checkAdjustmentData);

    //NOTE: This is the check summary section
    let checkSummaryHeader = {
        ySteps: 1,
        columns: ["Check Summary"],
        data: []
    }

    multiDataSet.push(checkSummaryHeader);

    let checkSummaryData = {
        columns: ["Facility", "Paid", "Adjusted", "Total"],
        data: []
    }

    let checkSummaryDataRows = [];

    //NOTE: These values are used in the next section, but they have to be calculated here
    let totalIndividualMemberPaid = 0;
    let totalIndividualMemberAdjust = 0;
    let totalIndividualMemberTotal = 0;

    for (let k = 0; k < checkData.checkSummarySection.length; k++) {
        let currentCheckSummary = checkData.checkSummarySection[k];

        totalIndividualMemberPaid = totalIndividualMemberPaid + currentCheckSummary.paid;
        totalIndividualMemberAdjust = totalIndividualMemberAdjust + currentCheckSummary.adjusted;
        totalIndividualMemberTotal = totalIndividualMemberTotal + currentCheckSummary.total;

        checkSummaryDataRows.push(
            [
                currentCheckSummary.memberName,
                formatter(currentCheckSummary.paid, 'money'),
                formatter(currentCheckSummary.adjusted, 'money'),
                formatter(currentCheckSummary.total, 'money'),
            ]
        );
    }

    checkSummaryData.data = checkSummaryDataRows;

    multiDataSet.push(checkSummaryData);

    //NOTE: The rest of this are the total values, it's easier to pass a set of columns because it's all bolded
    let totalPaidRow = {
        ySteps: 1,
        columns: ["Total Paid", formatter(totalIndividualMemberPaid, 'money')],
        data: []
    }

    multiDataSet.push(totalPaidRow);

    let totalAdjustedRow = {
        columns: ["Total Adjusted", formatter(totalIndividualMemberAdjust, 'money')],
        data: []
    }

    multiDataSet.push(totalAdjustedRow);

    let totalTotalRow = {
        columns: ["Total", formatter(totalIndividualMemberTotal, 'money')],
        data: []
    }

    multiDataSet.push(totalTotalRow);

    return multiDataSet;
}