import React from 'react';


//NOTE: It's totally possible to replace the "props.onClick" here with some different onClick functionality, or just completely change the hooks below
export default function ActionCell(props) {
    return (
        <span className="action-cell" onClick={() => props.onClick("viewReport")} data-testid={"action-" + props.feeCode}>
            View Report
        </span>
    )

}
