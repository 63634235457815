import React, { useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import FormValidation from "../../form/validation/FormValidation";
import { ProgramSubscriptions } from "../../../constants/ProgramSubscriptions";

export default function MissingRemittancesActiveForm(props) {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations]);

  function handleFormSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  function submitForm() {
    let parameters = {
      selectedLocations: selectedLocations,
    };
    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        locations: selectedLocations.join(","),
      });
    }
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={ProgramSubscriptions.Rec}
          />
        </Form.Group>

        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Form.Group>
      </Row>
    </Form>
  );
}
