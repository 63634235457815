import React from 'react'
 
let brandedBGValues = ["B", "BO", "MB", "BG", "OB", "OBO", "OMB", "OBG"];
let genericBGValues = ["G", "MG", "BG", "OG", "OMG", "OBG"];

export const constants = {
    mainGridHeaderProps: {
        Manufacturer: { title: 'Manufacturer', fixedLeft: true },
        NDC: { title: 'NDC', fixedLeft: true },
        DrugName: { title: 'Drug Name', fixedLeft: true },
        Strength: { title: 'Strength', fixedLeft: true },
        PackageQuantity: { title: 'Package Size', type: 'number' },
        UMIndicator: { title: 'UOM' },
        BGIndicator: { title: 'B/G' },
        CurrentAWP: { title: 'Current AWP', type: 'money' },
        CurrentAWPEffectiveDate: { title: 'Current AWP Eff. Date', type: 'date' },
        PreviousAWP: { title: 'Previous AWP', type: 'money' },
        PreviousAWPEffectiveDate: { title: 'Previous AWP Eff. Date', type: 'date' },
        AWPChangeDollar: {
            title: 'AWP $ Change',
            type: 'money',
            isCellAlert: function(row, column) {
                if (column === "AWPChangeDollar") {
                    let isOver100Dollars = row[column] > 100;
                    let isNegative = row[column] < 0;

                    return (isOver100Dollars || isNegative) && brandedBGValues.includes(row["BGIndicator"]);
                }
            }
        },
        AWPChangePercent: {
            title: 'AWP % Change',
            type: 'percent',
            isCellAlert: function(row, column) {
                if (column === "AWPChangePercent") {
                    let isOver10Percent = row[column] > 0.1;
                    let isNegative = row[column] < 0;

                    return ((isOver10Percent || isNegative) && brandedBGValues.includes(row["BGIndicator"]));
                }
            }
        },
        CurrentWAC: { title: 'Current WAC', type: 'money' },
        CurrentWACEffectiveDate: { title: 'Current WAC Eff. Date', type: 'date' },
        PreviousWAC: { title: 'Previous WAC', type: 'money' },
        PreviousWACEffectiveDate: { title: 'Previous WAC Eff. Date', type: 'date' },
        WACChangeDollar: {
            title: 'WAC $ Change',
            type: 'money',
            isCellAlert: function(row, column) {
                if (column === "WACChangeDollar") {
                    let isOver10Dollars = row[column] > 10;
                    let isNegative = row[column] < 0;

                    return ((isOver10Dollars || isNegative) && genericBGValues.includes(row["BGIndicator"]));
                }
            }
        },
        WACChangePercent: {
            title: 'WAC % Change',
            type: 'percent',
            isCellAlert: function(row, column) {
                if (column === "WACChangePercent") {
                    let isOver30Percent = row[column] > 0.3;
                    let isNegative = row[column] < 0;

                    return ((isOver30Percent || isNegative) && genericBGValues.includes(row["BGIndicator"]))
                }
            }
        }
    },
    featureInfo:
        <React.Fragment>
            <b>AWP $ Change</b>
            <p>Red indicates AWP has either increased by over $100.00, or has decreased, on a Branded drug</p>
            <b>AWP % Change</b>
            <p>Red indicates AWP has either increased by over 10%, or has decreased, on a Branded drug</p>
            <b>WAC $ Change</b>
            <p>Red indicates WAC has either increased by over $10, or has decreased, on a Generic drug</p>
            <b>WAC % Change</b>
            <p>Red indicates WAC has either increased by over 30%, or has decreased, on a Generic drug</p>
        </React.Fragment>
}