import React, { useState, useEffect } from "react";
import AccordionHeader from "./AccordionHeader";
import AccordionBody from "./AccordionBody";
import { Row, Col } from "react-bootstrap";

export default function Accordion(props) {
  const {
    defaultExpanded,
    label,
    children,
    position = "bottom",
    popoverLabel = null,
    popoverBody = null,
    sendAccordionParentCallback,
  } = props;

  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {}, [expanded]);

  return position === "bottom" ? (
    <div>
      <AccordionHeader
        expanded={expanded}
        onClick={() => {
          sendAccordionParentCallback && sendAccordionParentCallback(!expanded);
          setExpanded(!expanded);
        }}
        label={label}
        popoverLabel={popoverLabel}
        popoverBody={popoverBody}
      />
      <AccordionBody expanded={expanded}>{children}</AccordionBody>
    </div>
  ) : (
    <Row className="no-gutters">
      <Col md="auto">
        <AccordionHeader
          expanded={expanded}
          onClick={() => {
            sendAccordionParentCallback &&
              sendAccordionParentCallback(!expanded);
            setExpanded(!expanded);
          }}
          label={label}
          popoverLabel={popoverLabel}
          popoverBody={popoverBody}
        />
      </Col>
      <Col>
        <AccordionBody expanded={expanded}>{children}</AccordionBody>
      </Col>
    </Row>
  );
}
