import React, { useState, useEffect, useRef } from "react";
import CSVFormatter from "./csvFormatter";
import ReactExport from "react-csv-downloader";

import { GetExportFileNameDated } from "./ExportFileName";

export default function CsvExportDirect(props) {
  const {
    columns = [],
    rows = [],
    custom = {},
    name = "csvExport",
    button = "CSV",
    handleActionBarStartAction,
    handleActionBarCompleteAction,
    exportCompletedCallback,
    exportFileNamePrefix,
  } = props;

  const _dummyExport = useRef(null);
  const [csvColumns, setCSVColumns] = useState([]);
  const [csvRows, setCSVRows] = useState([]);

  const exportButton = (
    <span ref={_dummyExport}>
      {/* <i className="fa fa-file-o" aria-hidden></i>  */}
      {button}
    </span>
  );

  const exportFileName = exportFileNamePrefix
    ? GetExportFileNameDated(exportFileNamePrefix, "csv")
    : GetExportFileNameDated("NetRxExport", "csv");

  useEffect(() => {
    if (custom) {
      switch (custom.PrimaryKey) {
        case "pmid":
          setCSVRows(filterByPaymentID());
          break;
        case "clid":
          setCSVRows(filterByClaimID());
          break;
        default:
          setCSVRows(custom.rows);
          break;
      }
      setCSVColumns(custom.columns);
    } else {
      setCSVColumns(columns);
      setCSVRows(rows);
    }
  }, [rows]);

  useEffect(() => {
    //if csvRows is empty, then return

    if (!csvRows || csvRows.length === 0) return;

    //alert("csvExportDirect");

    _dummyExport.current.click();
    // dataExport();
    exportCompletedCallback();
  }, [csvRows]);

  function filterByPaymentID() {
    let data = [];
    for (let i = 0; i < rows.length; i++) {
      let currentRow = rows[i];
      let customRow = custom.rows[currentRow.pmid];
      if (customRow) {
        customRow.forEach((row) => {
          data.push(row);
        });
      }
    }
    return data;
  }

  function filterByClaimID() {
    let data = [];
    for (let i = 0; i < rows.length; i++) {
      let currentRow = rows[i];
      let customRow = custom.rows[currentRow.clid];
      if (customRow) {
        customRow.forEach((row) => {
          data.push(row);
        });
      }
    }
    return data;
  }

  const data = CSVFormatter(csvColumns, csvRows);

  return (
    <ReactExport
      wrapColumnChar='"'
      filename={exportFileName}
      datas={data.rows}
      columns={data.columns}
      separator=","
    >
      {exportButton}
    </ReactExport>
  );
}
