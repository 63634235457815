import { useContext, useState, useEffect } from "react";

import { UserContext } from "../context/UserContext";

import { ProgramSubscriptions } from "../../constants/ProgramSubscriptions";

export default function useUserLocations(programSubscription) {
  const userContext = useContext(UserContext);

  const [userLocations, setUserLocations] = useState(null);

  useEffect(() => {
    switch (programSubscription) {
      case ProgramSubscriptions.Rec:
        setUserLocations(userContext.recLocations);
        break;
      case ProgramSubscriptions.Metric:
        setUserLocations(userContext.metricLocations);
        break;
      case ProgramSubscriptions.Default:
        setUserLocations(userContext.locations);
        break;
      default:
        throw new Error(`Invalid program subscription: ${programSubscription}`);
    }
  }, [userContext, programSubscription]);

  return { userLocations };
}
