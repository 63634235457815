import React, { useState, useEffect } from 'react';
import MultiSelectDropdown from '../../MultiSelectDropdown';
import SingleSelectDropdown from '../../SingleSelectDropdown';

import { Form, Col, Row, Button } from 'react-bootstrap';

import 'font-awesome/css/font-awesome.min.css';
import './QueryBuilder.css';

export default function QueryBuilderSelection(props) {

    //NOTE: We can have the QueryBuilder manage all of the individual rows, and have it send down an id
    const { onQueryBuilderChange, optionsFromForm, controlIdFromParent, manageAddClick, manageDeleteClick, shouldShowAddOption, shouldShowDeleteOption, externalDataReference } = props;

    const [selectedType, setSelectedType] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(null);

    const [availableOptions, setAvailableOptions] = useState([]);
    const [isMultiSelect, setIsMultiSelect] = useState(true);

    const [optionsControls, setOptionsControls] = useState(null);
    const [needToResetOptions, setNeedToResetOptions] = useState(false);

    //NOTE: Whenever selectedType or selectedOptions changes, it will call onQueryBuilderChange() and send the updated values up to Component and then to the Form
    useEffect(() => {
        onQueryBuilderChange(selectedType, selectedOptions, controlIdFromParent);
    }, [selectedType, selectedOptions])

    //NOTE: These 2 useEffect() hooks ensure that "options are cleared when the type is changed"
    //without the "needToResetOptions" state object and it's use here, if the "type" is changed from one multiselect to another multiselect, then the options aren't cleared
    useEffect(() => {
        setOptionsControls(null);
        setNeedToResetOptions(true);
    }, [selectedType])

    useEffect(() => {
        if (setNeedToResetOptions) {
            updateOptionsControls();
            setNeedToResetOptions(false);
        }

    }, [needToResetOptions])

    function handleTypeChange(type) {
        setSelectedType(type);
        setSelectedOptions(null);
        changeOptionsBasedOnType(type);
    }

    //NOTE: This changes the "set of options" on the right side OR sets the input to a free-form text
    function changeOptionsBasedOnType(type) {
        let typeAndOptionsDetails = null;

        for (let i = 0; i < optionsFromForm.length; i++) {
            if (optionsFromForm[i].id === type) {
                typeAndOptionsDetails = optionsFromForm[i];
            }
        }

        if (typeAndOptionsDetails.controlCategory === "multiSelectDropdown" && typeAndOptionsDetails.values) {
            setAvailableOptions(typeAndOptionsDetails.values);
            setIsMultiSelect(true);
        }
        else {
            setAvailableOptions([]);
            setIsMultiSelect(false);
        }

        updateOptionsControls();
    }

    function handleOptionsChange(options) {
        setSelectedOptions(options);
    }

    let reformattedTypes = optionsFromForm.map(obj => {
        let rObj = {};
        rObj['value'] = obj.id;
        rObj['label'] = obj.id;
        return rObj;
    })

    function handleAddAction() {
        manageAddClick(controlIdFromParent);
    }

    function handleDeleteAction() {
        manageDeleteClick(controlIdFromParent);
    }

    function handleTextBoxChange(event) {
        let textboxValue = event.target.value;

        setSelectedOptions(textboxValue);
    }

    //NOTE: Although similar to "possibleAddOption" and "possibleDeleteOption", since the contents of the "optionsControl" can change based on internal changes in THIS component,
    //I'm using this function inside a useEffect to manage the display of the "optionsControl"
    function updateOptionsControls() {
        let newControls = (
            <Form.Group as={Col} md="2" className="d-flex flex-column">
                {isMultiSelect ?
                (<MultiSelectDropdown options={availableOptions} onChange={(selectedOptions) => handleOptionsChange(selectedOptions)} selectAllOption filterable />)
                :
                (<Form.Control type="text" placeholder="Normal text" onChange={handleTextBoxChange} />)}
            </Form.Group>
        )

        setOptionsControls(newControls);
    }

    let possibleAddOption = shouldShowAddOption && (
        <Form.Group as={Col} md="auto" className="d-flex flex-column" onClick={handleAddAction} >
            <div className="fa fa-plus-square blue-icon fa-lg"></div>
        </Form.Group>
    )

    let possibleDeleteOption = shouldShowDeleteOption && (
        <Form.Group as={Col} md="auto" className="d-flex flex-column" onClick={handleDeleteAction} >
            <div className="fa fa-trash blue-icon fa-lg"></div>
        </Form.Group>
    )

    return (
        <Row className="justify-content-start reduce-vertical-margin">
            <Form.Group as={Col} md="2" sm="2" className="d-flex flex-column">
                <SingleSelectDropdown options={reformattedTypes} onChange={(selectedType) => handleTypeChange(selectedType)} externalDataReference={externalDataReference} isDisableOptionsFromExternalDataReference />
            </Form.Group>
            {optionsControls}
            {possibleDeleteOption}
            {possibleAddOption}
        </Row>
    );

}