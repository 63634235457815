import React from "react";

export default function NoAccess(props) {
  const { featureName, displayMessage = null } = props;

  let featureNameMessage = (
    <span>
      You've clicked on a solution and our records indicate that your
      subscription to {featureName} is currently inactive. As a result, access
      to our premium features/content is currently unavailable. If you have just
      been granted access, you may need re-login first.
    </span>
  );

  return (
    <div>
      <br />
      <div className="bordered-control">
        <h4
          style={{
            color: "#034681",
            textAlign: "center",
          }}
        >
          No access
        </h4>
        <hr />
        <div
          style={{
            textAlign: "center",
          }}
        >
          {displayMessage === null || displayMessage === undefined
            ? ""
            : displayMessage}
          {featureName === null || featureName === undefined
            ? ""
            : featureNameMessage}
        </div>
      </div>
    </div>
  );
}
