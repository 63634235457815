import React from 'react';


export const constants = {

    residenceCodeDropDown: [
        { label: '0 Not Specified', value: 0 },
        { label: '01 Home', value: 1 },
        { label: '02 SNF Medicare Part B Use Only', value: 2 },
        { label: '03 SNF', value: 3 },
        { label: '04 ALF', value: 4 },
        { label: '05 Custodial Care/Part B', value: 5 },
        { label: '06 Group Home', value: 6 },
        { label: '07 Inpatient Psychiatric Facility/non-pharmacy', value: 7 },
        { label: '08 Psychiatric Facility Partial Hospitalization/non-pharmacy', value: 8 },
        { label: '09 ICF/MR', value: 9 },
        { label: '10 Substance Abuse Facility/non-pharmacy', value: 10 },
        { label: '11 Hospice', value: 11 },
        { label: '12 Psychiatric Residential Treatment Facility/non-pharmacy', value: 12 },
        { label: '13 Rehabilitation/non-pharmacy', value: 13 },
        { label: '14 Homeless Shelter/non-pharmacy', value: 14 },
        { label: '15 Correctional Institution', value: 15 },


    ],
    planTypeDropDown: [
        { label: 'Med D', value: 'MED D' },
        { label: 'Medicaid', value: 'MEDICAID' },
        { label: 'Commercial', value: 'COMMERCIAL' },
        { label: 'Med A', value: 'MED A' },
        { label: 'Other Plan Types', value: 'OTHER PLAN TYPE' },

    ],
    featureInfo:
    <React.Fragment>
        <p>Generic Effective Rate (GER) is the overall, combined average rate of payment for all generic drugs, per payer. Some payers service multiple classes of trade (COT). Select Payer Type(s) for each graph to carve out and compare COT by payer.</p>
        <p>All of the information presented in the graphs below, with the exception of the chart labeled “Chart 1”, corresponds to the Plan Type(s) selected for Chart 2.</p>
        <p>The data presented in Grid View mode corresponds to the Plan Type(s) selected for Chart 2.</p>
    </React.Fragment>

}

