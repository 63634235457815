import React from 'react';

export default function UploadDataTabStep2Panel(props) {

    return (
        <div>
            <h4>Step 2: Fill in the template with your data</h4>
            <hr className={"neutral-hr"} />
            <div>
                Fill in the template with your data and save it in the same Excel Spreadsheet format. You can use any filename you wish to help you manage these files, as long as you include the original filename extension.
            </div>
            <div>
                When your completed template is ready, you can proceed to step 3 to upload the file. Please note that once uploaded, you will not be able to change or delete this data except through the option of overwriting it with new data.
            </div>
        </div>
    )
}
