import React, { useState, useEffect } from "react";
import { Grid, GridToolbar, getSelectedState } from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
import 'font-awesome/css/font-awesome.min.css';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getter } from "@progress/kendo-react-common";
import {FILTER_OPERATOR} from "../../grid/Kendo/CustomGridFilterOperator";

export default function SharedContactAdminGrid(props) {

    const INITIAL_MAIN_GRID_DATA_STATE = { sort:[],skip: 0, take: 10 };
    const [mainGridData, setMainGridData] = useState(props.data ? props.data : []);
    const DATA_ITEM_KEY  = props.DATA_ITEM_KEY;
    const [mainGridDataState, setMainGridDataState] = useState(INITIAL_MAIN_GRID_DATA_STATE);
    const [mainGridResultState, setMainGridResultState] = React.useState(mainGridData);

    useEffect(() => {
        setMainGridData(props.data);
        let processedData = process(props.data, INITIAL_MAIN_GRID_DATA_STATE);
        setMainGridResultState(processedData);
        setMainGridDataState(INITIAL_MAIN_GRID_DATA_STATE);
    }, [props.data]);

    const onMainGridDataStateChange = (event) => {
        setMainGridDataState(event.dataState);
        var processedDatasource = process(mainGridData, event.dataState);
        setMainGridResultState(processedDatasource);
    };

    return(
        <Grid
        style={{
            height: "500px",
        }}
        data={mainGridResultState}
        filterable={true}
        sortable={false}
        groupable={false}
        pageable={true}
        dataItemKey={DATA_ITEM_KEY}
        onDataStateChange={onMainGridDataStateChange}
        {...mainGridDataState}
    >
        
        {props.children}
    </Grid>

    )
}