import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

export default function ErrorHandler(props) {

    const { error, onClose } = props;

    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        if (Object.keys(error).length > 0) {
            switch (error.status) {
                case 200: setError('success', error.Message); break;
                case 201: setError('warning', error.Message); break;
                case 204: setError('warning', error.Message); break;
                case 422: setError('danger', error.data[0]); break;
                case 500: setError('danger', (error.data.Message) ? error.data.Message : error.data); break;
                default: setError('danger', 'An error has occurred'); break;
            }
        }
        else { onReset() }
    });

    function onReset() {
        setShowAlert(false);
        setAlertType('');
        setAlertMessage('');
    };

    function setError(type, message) {
        setShowAlert(true);
        setAlertType(type);
        setAlertMessage(message);
    }

    return (<div><Alert show={showAlert} variant={alertType} onClose={onClose} dismissible>{alertMessage}</Alert></div>);
}