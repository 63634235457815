import React, { useState, useEffect, useReducer } from "react";
import { DetailsModal } from "../../grid/DetailsModal";
import { Row, Col } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { MoneyCell } from "../../grid/Kendo/CustomKendoGridCells";
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import "./FeeAndAdjustmentDetailsModal.css";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import ErrorHandler from "./../../core/ErrorHandler";
import { StringToDate } from "../../../helpers/DateHelpers";
import axios from "axios";

import {DefaultColumnWidth, DefaultBooleanOrShortWidthColumnWidth, DefaultDateColumnWidth} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function FeeAndAdjustmentDetailsModal(props) {
  const { parameter, show, onClose, auth } = props;

  const [allDetailsGridData, setAllDetailsGridData] = useState([]);
  const [adjustmentGridData, setAdjustmentGridData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [noGridAggregates, setNoGridAggregates] = useState([]);
  const sendAggregatesToParent = (aggregatesFromSharedMain) => {
    setNoGridAggregates(aggregatesFromSharedMain.data);
  };

  useEffect(() => {
    setError({});
    setAllDetailsGridData([]);
    setAdjustmentGridData([]);
  }, [onClose]);

  useEffect(() => {
    setError({});
    setAllDetailsGridData([]);
    setAdjustmentGridData([]);
    if (parameter.length != 0) {
      getReport();
    }
  }, [parameter]);

  //console.log("FeeAndAdjustmentDetailsModal data", data);

  //add a function that does a calculation
  const getAdjustmentsByPayerData = (data) => {
    const result = data.reduce((acc, item) => {
      const payer = item.summary.payer;
      const adjustmentAmount = item.summary.adjustmentAmount;
      const existingItem = acc.find((x) => x.payer === payer);
      if (!existingItem) {
        acc.push({ payer, totalAdjustmentAmountPerPayer: adjustmentAmount });
      } else {
        existingItem.totalAdjustmentAmountPerPayer += adjustmentAmount;
      }
      return acc;
    }, []);

    //order the result by payer name
    const orderedResult = result.sort((a, b) => {
      if (a.payer < b.payer) {
        return -1;
      }
      if (a.payer > b.payer) {
        return 1;
      }
      return 0;
    });

    //get the unique payers from data.summary

    //setAdjustmentsByPayer(orderedResult);

    return orderedResult;
  };

  const getAllDetailData = (data) => {
    let detailDataRows = [];

    let dateColumnsToApplyDateFormat = [
      "checkDate",
      "fillDate",
      "transactionDate",
    ];

    data.forEach((item) => {
      item.details.forEach((detail) => {
        //if the checkdate detail item has a value make sure it is a date
        dateColumnsToApplyDateFormat.forEach((column) => {
          if (detail[column])
            detail = { ...detail, [column]: StringToDate(detail[column]) };
        });

        detailDataRows.push(detail);
      });
    });

    //remove all duplicates
    let detailDataRowsUnique = detailDataRows.filter(
      (v, i, a) =>
        a.findIndex(
          (t) =>
            t.checkNumber === v.checkNumber &&
            t.payer === v.payer &&
            t.checkAmount === v.checkAmount &&
            t.checkDate === v.checkDate
        ) === i
    );

    return detailDataRowsUnique;
  };

  async function getReport() {
    try {
      setIsLoading(true);

      let accessToken = auth.passedProps.auth.getAccessToken();

      let parameters = parameter;

      //NOTE TODO: This is the data fetch for the details, fix this

      let response = await axios.get(`api/FeeAndAdjustment/GetDetails`, {
        params: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.data.length > 0) {
        setAdjustmentGridData(getAdjustmentsByPayerData(response.data));
        setAllDetailsGridData(getAllDetailData(response.data));
      } else {
        setError({ status: 201, Message: "No data found for given params" });
      }
    } catch (e) {
      console.log(e);
      setError(e.response);
    }
    setIsLoading(false);
  }

  return (
    <DetailsModal
      title="Fee and Adjustment Research Detail"
      show={show}
      handleClose={onClose}
    >
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"FeeAndAdjustmentDetailsModel"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      <div className="fee-and-adjustment-detail-summary-wrapper">
        <Row>
          <Col md={6} className="tight-grid multi-line-filter">
            <Grid
              style={{
                maxHeight: "10em",
              }}
              data={adjustmentGridData}
            >
              <GridColumn
                key={"payer"}
                field={"payer"}
                title={"Payer"}
                filter={"text"}
              />

              <GridColumn
                key={"totalAdjustmentAmountPerPayer"}
                field={"totalAdjustmentAmountPerPayer"}
                title={"Adjustments"}
                filter={"numeric"}
                cells={{
                  data: MoneyCell,
                }}
              />
            </Grid>
          </Col>
        </Row>
      </div>

      <div className="fee-and-adjustment-detail-summary-wrapper">
        <br />

        <Row>
          <Col md={12} className="tight-grid multi-line-filter">
            <SharedMainGrid
              styleOverride={{
                maxHeight: "30em",
              }}
              data={allDetailsGridData}
              dataItemKey="id"
              aggregateColumnSettings={null}
              sendAggregatesParentCallback={sendAggregatesToParent}
              exportFileNamePrefix={"Fee&AdjustmentDetails"}
            >
              <GridColumn field="payer" title="Payer" width={DefaultColumnWidth()} />
              <GridColumn
                field="checkNumber"
                title="Check Number"
                width={DefaultColumnWidth()}
              />
              <GridColumn
                key={"ncpdp"}
                field={"ncpdp"}
                title={"NCPDP"}
                filter={"text"}
                width={DefaultBooleanOrShortWidthColumnWidth()}
              />

              <GridColumn
                key={"checkDate"}
                field={"checkDate"}
                title={"Check Date"}
                filter={"date"}
                format="{0:d}"
                width={DefaultDateColumnWidth()}
              />

              <GridColumn
                key={"checkAmount"}
                field={"checkAmount"}
                title={"Check Amount"}
                filter={"text"}
                width={DefaultColumnWidth()}
                cells={{
                  data: MoneyCell,
                }}
              />
              <GridColumn
                key={"rxNumber"}
                field={"rxNumber"}
                title={"Rx #"}
                filter={"text"}
                width={DefaultColumnWidth()}
              />
              <GridColumn
                key={"fillDate"}
                field={"fillDate"}
                title={"Fill Date"}
                filter={"date"}
                format="{0:d}"
                width={DefaultDateColumnWidth()}
              />
              <GridColumn
                key={"transactionDate"}
                field={"transactionDate"}
                title={"Transaction Date"}
                filter={"date"}
                format="{0:d}"
                width={DefaultDateColumnWidth()}
              />
              <GridColumn
                key={"patientName"}
                field={"patientName"}
                title={"Patient Name"}
                filter={"text"}
                width={DefaultColumnWidth()}
              />
              <GridColumn
                key={"feeAdjustmentAmount"}
                field={"feeAdjustmentAmount"}
                title={"Fee/Adjustment Amount"}
                filter={"numeric"}
                width={DefaultColumnWidth()}
                cells={{
                  data: MoneyCell,
                }}
              />
              <GridColumn
                key={"reasonCode"}
                field={"reasonCode"}
                title={"Reason Code"}
                filter={"text"}
                width={DefaultColumnWidth()}
              />
              <GridColumn
                key={"reasonDescription"}
                field={"reasonDescription"}
                title={"Reason Description"}
                filter={"text"}
                width={DefaultColumnWidth()}
              />
            </SharedMainGrid>
          </Col>
        </Row>
      </div>

      {/* <button onClick={setAdjustmentsByPayerData}>Set Data</button> */}

      {/* <NavDropdown
        title={
          <span>
            <i className="fa fa-download" aria-hidden></i> Download As...
          </span>
        }
        style={{ marginBottom: "7px" }}
        id="basic-nav-dropdown"
      > */}
      {/* <NavDropdown.Item>
          <KendoExcelExporter
            data={exportData && exportData.rows ? exportData.rows : []}
            columns={KendoExcelExporterColumns}
          ></KendoExcelExporter>
        </NavDropdown.Item>
        <NavDropdown.Item>
          <CSVExporter columns={[]} rows={[]} custom={exportData} />
        </NavDropdown.Item>
      </NavDropdown>

      <FeeAndAdjustmentDetailGrandTotal data={summaries} /> */}
      {/* NOTE: There's a separate section for each one of these */}
      {/* {data.map((section, index) => {
        return (
          <React.Fragment key={index}>
            <FeeAndAdjustmentDetailsGroup key={index} data={section} />
            <br />
          </React.Fragment>
        );
      })
      
      } */}
    </DetailsModal>
  );
}
