import React, { useState } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import axios from "axios";

import SingleSelectDropdown from '../../SingleSelectDropdown';

export default function UploadDataTabStep1Panel(props) {
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const templateOptions = [
        { label: 'Acquisition Cost Analysis', value: 'acquisitionCostAnalysis' },
        { label: 'State Price List', value: 'statePriceList' }
    ];

    async function getTemplate() {
        if (selectedTemplate) {
            const uris = {
                acquisitionCostAnalysis: 'api/BYOD/AcquisitionCostAnalysisDownload',
                statePriceList: 'api/BYOD/StatePriceListDownload'
            }

            const endpoint = uris[selectedTemplate]

            try {
                const response = await axios.get(endpoint, {
                    responseType: 'blob',
                });

                const fileName = response.headers['content-disposition']
                    .split(';')
                    .find(n => n.includes('filename='))
                    .replace('filename=', '')
                    .trim();

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
            } catch (ex) {
            }
        }
        else {
            alert("Please choose a Data Template.");
        }

    }

    return (
        <div>
            <h4>Step 1: Download a template to get started</h4>
            <hr className={"neutral-hr"} />
            <div>
                Choose an Excel data template from the list and click “Download” to save it to your Downloads folder.
            </div>
            <div>
                Please ensure the template selected matches the type of analytics you intend to run.
            </div>
            <br />

            <span className={"small-label form-margin"}>Data Template</span>

            <Row className={"form-margin"} >
                <Col md="auto">
                    <SingleSelectDropdown options={templateOptions} onChange={(selectedTemplate) => setSelectedTemplate(selectedTemplate)} />
                </Col>
            </Row>
            <Row className={"form-margin"} >
                <Col md="auto">
                    <Button onClick={getTemplate}>Download </Button>
                </Col>
            </Row>

        </div>
    )
}
