import axios from 'axios';

const DownloadFileByBatchId = (
    setParentLoading,
    handleSuccessCallback,
    handleFailureCallback,
    apiParameters,
    accessToken
) => {
    setParentLoading(true);

    try{
        axios.get("api/RemittanceExport/GetFileByBatchId", {
            params: apiParameters,
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then((success) => {
                handleSuccessCallback(success);
            })
            .catch((e) => {
                handleFailureCallback(e);
            });
    }
    catch(exception){
        console.log(exception);
        handleFailureCallback(exception);
    }

};

const CreateNewRequestForExistingBatch = (
    setParentLoading,
    handleSuccessCallback,
    handleFailureCallback,
    apiParameters,
    accessToken
) => {
    setParentLoading(true);

    try{
        axios.post("api/RemittanceExport/CreateNewRequestToRebuildBatch",
            apiParameters,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then(handleSuccessCallback)
            .catch(handleFailureCallback);
    }
    catch(exception){
        handleFailureCallback(exception);
    }

};

const CheckBatchDateOlderThan90Days = (dataItem) => {
    if (dataItem.batchRequestDate) {
        var today = new Date();
        var batchDate = new Date(dataItem.batchRequestDate);
        var diffDays = Math.floor((today - batchDate) / (1000 * 60 * 60 * 24));

        // return diffDays > 5;
        return diffDays > 90;
    }
    else {
        return false;
    }
};

export {
    DownloadFileByBatchId,
    CreateNewRequestForExistingBatch,
    CheckBatchDateOlderThan90Days
};
