import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";

import axios from "axios";

function onDownloadAttachmentFailure(fileName) {
  let msg = `Unable to download remittance attachment file {${fileName}}.`;

  alert(msg);
}

export default function MissingRemittanceDownloadAttachment(
  remittanceId,
  uploadedAttachmentFile,
  auth
) {
  try {
    let accessToken = auth.getAccessToken();

    let parameters = {
      missingRemittanceId: remittanceId,
    };

    axios
      .get("api/MissingRemittances/DownloadAttachment", {
        params: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const base64Response = response.data.fileStream;

        let downloadBytes = Base64ToArrayBuffer(base64Response);

        SaveByteArray(uploadedAttachmentFile, downloadBytes);
      })
      .catch(() => onDownloadAttachmentFailure(uploadedAttachmentFile));
  } catch (exception) {
    onDownloadAttachmentFailure(() =>
      onDownloadAttachmentFailure(uploadedAttachmentFile)
    );
  }
}
