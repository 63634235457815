

export default function DataTableSorter(type) {
    switch (type) {
        case 'number': 
            return new DataTableNumberSorter()
        case 'money':
            return new DataTableNumberSorter()
        default:
            return new DataTableStringSorter()
    }
}

class DataTableStringSorter {

    // sortAscending = (property) => {
    //     var sortOrder = 1;
    //     if (property[0] === "-") {
    //         sortOrder = -1;
    //         property = property.substr(1);
    //     }
    //     return function (a, b) {
    //         var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    //         return result * sortOrder;
    //     }
    // }

    sortAscending(property){
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    sortDescending(property){
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = 1;
            property = property.substr(1);
        }

        return function (a, b) {
            var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
}

class DataTableNumberSorter {

    sortAscending(property){
        return function (a, b) {
            return (a[property] - b[property])
        }
    }

    sortDescending(property){
        return function (a, b) {
            return (b[property] - a[property])
        }
    }
}