import React, { useContext, useState } from 'react';
import { Form, FormFile, Col, Row, Button } from 'react-bootstrap';
import axios from "axios";
import SingleSelectDropdown from '../../SingleSelectDropdown';
import { UserContext } from '../../context/UserContext';
import { LoadingSpinner } from './../../layout/LoadingSpinner';

export default function UploadDataTabStep3Panel(props) {
    const userContext = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false)

    const [fileData, setFileData] = useState(null)

    const [selectedAnalyticsFormat, setSelectedAnalyticsFormat] = useState(null)

    const analyticsFormatOptions = [
        { label: 'Acquisition Cost Analysis', value: 'acquisitionCostAnalysis' },
        { label: 'State Price List', value: 'statePriceList' }
    ]

    function setFile(e) {
        const file = Array.from(e.target.files)[0]

        if (file) {
            setFileData({
                formFile: file,
                fileName: file.name
            })
        }

    }

    async function handleFormSubmit(e) {
        setIsLoading(true);
        e.preventDefault()

        if (selectedAnalyticsFormat) {
            if (fileData) {
                console.log(fileData);
                if (fileData.formFile && fileData.formFile.size > 2000000) {
                    alert("This file is too large, the maximum file size for this upload is 2 MB.");
                }
                else {
                    try {
                        alert("Please Note: You will not be able to change or delete this data except through the option of overwriting it with new data. Please ensure there is no PHI (Protected Health Information) or PII (Personal Identifying Information) in the data you are uploading.");
                        const formData = new FormData();
                        formData.append("fileName", fileData.fileName)
                        formData.append("formFile", fileData.formFile)
                        formData.append("selectedAnalyticsFormat", selectedAnalyticsFormat)
                        formData.append("userEmailAddress", userContext.email)
                        formData.append("organization", userContext.currentOrganization.value)
                        const response = await axios.post("api/BYOD", formData);

                        let errorMessage = "";

                        if (response.data && response.data.duplicateNdcs && response.data.failedNdcs &&
                            (
                                response.data.failedNdcs.length > 0 || response.data.duplicateNdcs.length > 0 
                            )
                        ) {
                            if (response.data.failedNdcs.length > 0 && response.data.failedNdcs.length <= 5) {
                                errorMessage = errorMessage + "Some NDCs failed, including the following NDC(s): ";

                                let joinedUpFailedNdcs = response.data.failedNdcs.join(', ');
                                errorMessage = errorMessage + joinedUpFailedNdcs + ". ";
                            }
                            else if (response.data.failedNdcs.length > 5) {
                                errorMessage = errorMessage + "More than 5 NDCs failed, including the following NDC(s): ";

                                let joinedUpFailedNdcs = response.data.failedNdcs.slice(0, 5).join(', ');
                                errorMessage = errorMessage + joinedUpFailedNdcs + ". ";
                            }

                            if (response.data.duplicateNdcs.length > 0 && response.data.duplicateNdcs.length <= 5) {
                                errorMessage = errorMessage + "There were duplicates for the following NDC(s), so only one record was loaded for this data as a result: ";

                                let joinedUpDuplicateNdcs = response.data.duplicateNdcs.join(', ');
                                errorMessage = errorMessage + joinedUpDuplicateNdcs + ". ";
                            }
                            else if (response.data.duplicateNdcs.length > 5) {
                                errorMessage = errorMessage + "There were more than 5 duplicates, including the following NDC(s), so only one record was loaded for this data as a result: ";

                                let joinedUpDuplicateNdcs = response.data.duplicateNdcs.slice(0, 5).join(', ');
                                errorMessage = errorMessage + joinedUpDuplicateNdcs + ". ";
                            }


                            errorMessage = errorMessage + "However, the other NDCs were uploaded.";

                            alert(errorMessage);
                            props.refreshFileUploadHistoryGrid();
                        }
                        else {
                            alert("Data successfully uploaded!");
                            props.refreshFileUploadHistoryGrid();
                        }
                    } catch (ex) {
                        if (ex.response) {
                            props.passExceptionFromStep3PanelToTab(ex.response);
                        }
                        else {
                            props.passExceptionFromStep3PanelToTab(ex);
                        }
                    }
                }

            }
            else {
                alert("Please choose a file.");
            }
        }
        else {
            alert("Please choose an analytics format.");
        }

        setIsLoading(false);

        //NOTE: This clears the "Form.File" input 
        document.getElementById('file-upload').value = null;
        setFileData(null);
    }

    return (
        <div>
            {/* <LoadingSpinner isDataLoading={isLoading} controlsName={'augmentedAnalyticsUploadDataTabStep3Panel'} /> */}
            <h4>Step 3: Upload the completed template</h4>
            <hr className={"neutral-hr"} />
            <div>Choose the analytics format that matches the template you’re using from the list below, and click ”Choose File” to select and upload your data. You will be able to run the analytics for that data from the “Run Analytics” tab above, on the day after you’ve completed the Upload.</div>

            <br />
{/* 
            <Form onSubmit={handleFormSubmit}>
                <Row className={"form-margin"}>
                    <Col md="auto">
                        <span className={"small-label form-margin"}>Analytics Format</span>
                        <Row>
                            <Col md="auto">
                                <SingleSelectDropdown options={analyticsFormatOptions} onChange={(selectedTemplate) => setSelectedAnalyticsFormat(selectedTemplate)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={"form-margin"} >
                    <Col md="auto">
                        <Form.File onChange={setFile} id="file-upload" />
                    </Col>
                </Row>
                <Row className={"form-margin"} >
                    <Col md="auto">
                        <Button type='submit'>Upload</Button>
                    </Col>
                </Row>
            </Form> */}

        </div>
    )

}
