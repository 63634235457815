import React, { useState, useEffect } from "react";
import classNames from 'classnames'

import SingleSelectDropdownOption from './SingleSelectDropdownOption'

export default function SingleSelectDropdownBody(props) {
    const { options, onChange, show, defaultSelected, externalDataReference } = props
    //const [isFiltered, setFiltered] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState(null);

    // setting all selected options values to false to start with when options prop changes.
    useEffect(() => {
        if (defaultSelected) {
            const { value, label } = defaultSelected
            setSelectedOptions(value)
            onChange(label, value);
        }
    }, [options])

    // handles when an option is clicked
    function handleOptionClick(label,value) {
        setSelectedOptions(value);
        onChange(label, value);
    }

    let optionsToUse = options

    let className = classNames('multi-select-dropdown-body', { 'hidden': !show});

    return (
        <div className={className} data-testid={props["data-testid"] ? props["data-testid"] : null}>
            <ul>
                {optionsToUse.map((option, index) => {
                    return (
                        <SingleSelectDropdownOption
                            selected={selectedOptions === option.value}
                            key={index}
                            label={option.label}
                            value={option.value}
                            handleOptionClick={handleOptionClick}
                            disabled={externalDataReference ? externalDataReference.indexOf(option.value.toString()) > -1 : false}
                        />
                    )
                })}
            </ul>
        </div>
    )
}