import React, { useContext } from "react";
import MultiSelectDropdown from "../MultiSelectDropdown";
import { UserContext } from "../context/UserContext";

export default function MemberLocationDropdown(props) {
  const { onChange, programSubscription, customMemberLocationList = null } = props;
  const userContext = useContext(UserContext);

  //NOTE: Depending on the programSubscription being passed, determine which set of locations to display data for
  if (programSubscription === "Rec2.0") {
    return (
      userContext.recLocations && (
        <MultiSelectDropdown
          options={customMemberLocationList ? customMemberLocationList : userContext.recLocations}
          onChange={onChange}
          selectAllOption
          filterable
          onLoadAllSelected
        />
      )
    );
  } else if (programSubscription === "Metric2.0") {
    return (
      userContext.metricLocations && (
        <MultiSelectDropdown
          options={customMemberLocationList ? customMemberLocationList : userContext.metricLocations}
          onChange={onChange}
          selectAllOption
          filterable
          onLoadAllSelected
        />
      )
    );
  } else {
    return (
      userContext.locations && (
        <MultiSelectDropdown
          options={customMemberLocationList ? customMemberLocationList : userContext.locations}
          onChange={onChange}
          selectAllOption
          filterable
          onLoadAllSelected
        />
      )
    );
  }
}
