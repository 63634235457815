import React from 'react';
import { UploadDataTab } from './UploadDataTab'
import { RunAnalyticsTab } from './RunAnalyticsTab'

import TabBasedFeature from '../../layout/TabBasedFeature';

export default function Workbench(props) {

    //const SisenseURL = process.env.REACT_APP_SisenseURI + '/app/main#/home?h=false'

    //NOTE: This UI started out with a sisense iframe, I have no idea what it's for so I'm dumping it for now
    //return (
    //    <div className="full-sisense-feature">
    //        <span>hello</span>
    //        <iframe src={SisenseURL} id="sisense-frame" width="100%" height="100%" allowFullScreen="true"></iframe>
    //    </div>
    //)
    console.log(props);

    let tabs = [
        { key: 'uploadData', title: 'Upload Data', Component: UploadDataTab  },
        { key: 'runAnalytics', title: 'Run Analytics', Component: RunAnalyticsTab, disabled:'true' }
    ]
    return (
        <TabBasedFeature tabs={tabs} defaultActiveKey="uploadData" passedProps={props.passedProps} />
    );


}
