import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import Cell from './Cell'
import SelectableRowCell from './SelectableRowCell'
import './Row.css'

export default function Row(props) {
    const {
        columns,
        row,
        rowIndex,
        scrollOffset,
        handleCellClick,
        handleEdit,
        selectableRows,
        handleRowSelected
    } = props

    /*
     NOTE: The handleCellClick() being the way it is makes it so that "type"
     only has a value if the click comes from the Action column, in which case it is the "input" of the " props.handleClick("download") "
     sections on the View(), Action(), and Download() hooks on the "RemitRcptVerification.js" file.
     The column.accessor is the "internal name of each column".
     */
    return (
        <div className="data-table-row">
            <SelectableRowCell
                show={selectableRows}
                leftOffset={scrollOffset || 0}
                selected={row.selected === undefined ? false : row.selected}
                handleRowSelected={handleRowSelected}
            />
            {columns.map((column, index) => {
                return (
                    <Cell
                        accessor={column.accessor}
                        key={rowIndex + '-' + index}
                        type={column.type}
                        content={row[column.accessor]}
                        selectable={column.selectable}
                        truncatable={column.truncatable}
                        leftOffset={column.fixedLeft ? scrollOffset : 0}
                        handleCellClick={(column) => handleCellClick(column)}
                        alert={column.isCellAlert ? column.isCellAlert(row, column.accessor) : false}
                        hidden={column.hidden}
                        editable={column.editable}
                        handleEdit={(value) => handleEdit(value, row, column.accessor)}
                    />
                )
            })}
        </div>
        )
    }
    
const columnExact = {

    accessor: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'custom', 'date','checkbox']).isRequired,
    fixedLeft: PropTypes.bool,
    isCellAlert: PropTypes.func,
    hidden: PropTypes.bool,
    selectable: PropTypes.bool,
    editable: PropTypes.bool,
    handleEdit: PropTypes.func,
    truncatable: PropTypes.bool
}
        
Row.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
    row: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    scrollOffset: PropTypes.number.isRequired,
    handleCellClick: PropTypes.func.isRequired,
}