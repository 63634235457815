import React, { useState, useEffect } from 'react';

import { Form, Col, Row, OverlayTrigger, Popover } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import QueryBuilderBody from './QueryBuilderBody';
import './QueryBuilder.css';

export default function QueryBuilder(props) {
    const { handleOptionChangeOnForm, optionsFromForm } = props;

    const popover = (
        <Popover id="popover-basic">
            {"Text search terms may be separated by ';' character delimiters."}
        </Popover>
    );

    return (
        <React.Fragment>
            <Row className="justify-content-start" id="query-builder-title">
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <div>
                        Query Builder
                    </div>
                </Form.Group>
                <span>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                        <i className="additional-info-popover-icon fa fa-info-circle" />
                    </OverlayTrigger>
                </span>
            </Row>

            <QueryBuilderBody handleOptionChangeOnForm={handleOptionChangeOnForm} optionsFromForm={optionsFromForm} />

        </React.Fragment>
    )

}