import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import { UserContext } from "./components/context/UserContext";
import { setWithExpiry } from "./utils/localStorageAccess";
import RMERouter from "./components/router/RMERouter";

import { IsUnrestrictedEraRoute } from "./constants/EraPaths";

//NOTE: Is this used?
function onAuthRequired({ history }) {
  history.push("/login");
}

let appInsights = null;
export default function App(props) {
  //NOTE: TO FIND these values, they are in the .env files, but really they're sourced from the Okta website
  //I don't think I have access to this for now, but for now it should be alright I think (as long as the values in .env are correct)
  let oktaIssuer = process.env.REACT_APP_OKTADomain + "/oauth2/default";
  // console.log(oktaIssuer);

  //NOTE: I think we might need to store this somewhere on the deployed environment? Figure this out eventually
  let oktaClientId = process.env.REACT_APP_OKTAClientID;
  // console.log(oktaClientId);

  const constructedOktaAuth = new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    // redirectUri: window.location.origin + '/login/callback'
    // redirectUri: "http://localhost:3000/testfile"
    redirectUri: window.location.origin + "/implicit/callback",
  });

  const navigate = useNavigate();

  const location = useLocation();

  const constructedRestoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  //let oktaIssuer = process.env.REACT_APP_OKTADomain + '/oauth2/default'

  const [programs, setPrograms] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [locations, setLocations] = useState(null);
  const [recLocations, setRecLocations] = useState(null);
  const [metricLocations, setMetricLocations] = useState(null);
  const [ncpdps, setNcpdps] = useState(null);
  const [payers, setPayers] = useState(null);
  const [email, setEmail] = useState("");
  const [hasMetricSubscription, setHasMetricSubscription] = useState(null);
  const [hasRecSubscription, setHasRecSubscription] = useState(null);
  const [contactType, setContactType] = useState(null);

  const [isUnrestrictedEraPath, setIsUnrestrictedEraPath] = useState(true);

  let userConfig = {
    programs: programs,
    onProgramsChange: (programs) => setPrograms(programs),
    organizations: organizations,
    onOrganizationsChange: (organizations) => setOrganizations(organizations),
    currentOrganization: currentOrganization,
    onCurrentOrganizationChange: (currentOrganization) =>
      setCurrentOrganization(currentOrganization),
    locations: locations,
    onLocationsChange: (locations) => setLocations(locations),
    recLocations: recLocations,
    onRecLocationsChange: (recLocations) => setRecLocations(recLocations),
    metricLocations: metricLocations,
    onMetricLocationsChange: (metricLocations) =>
      setMetricLocations(metricLocations),
    //NOTE TODO: Are we actually using the "ncpdps" value anywhere? Or is it just "locations" that we're using? check this
    ncpdps: ncpdps,
    onNcpdpsChange: (ncpdps) => setNcpdps(ncpdps),
    payers: payers,
    onPayersChange: (payers) => setPayers(payers),
    email: email,
    onEmailChange: (email) => setEmail(email),
    hasMetricSubscription: hasMetricSubscription,
    onHasMetricSubscriptionChange: (hasMetricSubscription) =>
      setHasMetricSubscription(hasMetricSubscription),
    hasRecSubscription: hasRecSubscription,
    onHasRecSubscriptionChange: (hasRecSubscription) =>
      setHasRecSubscription(hasRecSubscription),
    contactType: contactType,
    onContactTypeChange: (contactType) => setContactType(contactType),
    isUnrestrictedEraPath: isUnrestrictedEraPath,
  };

  useEffect(() => {
    if (locations) {
      setWithExpiry("rme-userConfig", userConfig, 3600000);
    }
  }, [locations]);

  // useEffect(() => {
  //   //alert("switched to new user context");
  //   setLocations(null);
  //   setMetricLocations(null);
  //   setRecLocations(null);
  // }, [accessingUnrestrictedRoute]);

  useEffect(() => {
    let isCurrentRouteUnrestricted = IsUnrestrictedEraRoute(location.pathname);

    //set the state if the the reswtricted/unrestricted state of the current route HAS CHANGED
    //This will allow raising a useeffect event and pull locations again as necessary
    if (isCurrentRouteUnrestricted !== isUnrestrictedEraPath) {
      //alert("isCurrentRouteUnrestricted " + isCurrentRouteUnrestricted);
      setLocations(null);
      setMetricLocations(null);
      setRecLocations(null);
      setIsUnrestrictedEraPath(isCurrentRouteUnrestricted);
    }
  }, [location.pathname]);

  //return (
  //    <UserContext.Provider value={userConfig}>
  //        <Router>
  //            <TelemetryProvider
  //                instrumentationKey="5a586087-db6d-4877-bf5d-fcef6773ba0e"
  //                after={() => appInsights = getAppInsights()}
  //            >
  //                <Security
  //                    issuer={oktaIssuer}
  //                    client_id={process.env.REACT_APP_OKTAClientID }
  //                    redirect_uri={window.location.origin + '/implicit/callback'}
  //                    onAuthRequired={onAuthRequired}
  //                    pkce={true}>
  //                    <RMERouter appInsights={appInsights}/>
  //                </Security>
  //            </TelemetryProvider>
  //        </Router>
  //    </UserContext.Provider>
  //)

  //NOTE: I'm removing the TelemetryProvider until I can figure out how to make it work again
  // return (
  //     <UserContext.Provider value={userConfig}>
  //         <Security
  //             oktaAuth={constructedOktaAuth}
  //             restoreOriginalUri={constructedRestoreOriginalUri}
  //             issuer={oktaIssuer}
  //             client_id={process.env.REACT_APP_OKTAClientID}
  //             redirect_uri={window.location.origin + '/implicit/callback'}
  //             onAuthRequired={onAuthRequired}
  //             pkce={true}>
  //             <RMERouter appInsights={appInsights} />
  //         </Security>
  //     </UserContext.Provider>
  // )

  return (
    <UserContext.Provider value={userConfig}>
      <Security
        oktaAuth={constructedOktaAuth}
        restoreOriginalUri={constructedRestoreOriginalUri}
        issuer={oktaIssuer}
        client_id={process.env.REACT_APP_OKTAClientID}
        onAuthRequired={onAuthRequired}
        pkce={true}
      >
        <RMERouter appInsights={appInsights} />
      </Security>
    </UserContext.Provider>
  );
}
