import React, { useState,useEffect } from "react";
import {useLocation} from 'react-router-dom'

import { MissingRemittancesActive } from "./MissingRemittancesActive";
import { MissingRemittancesHistory } from "./MissingRemittancesHistory";
import TabBasedFeature from "../../layout/TabBasedFeature";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./MissingRemittance.css";

export default function MissingRemittances(props) {
  //This will be used a flag to indicate an update was made and the data needs to be refreshed on both tabs
  const [statusUpdated, setStatusUpdated] = useState("0");
  const [querystringTab, setQueryStringTab] = useState();
  let location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let tab = searchParams.get("tab");
    if(tab != null){
      setQueryStringTab(tab);   
    }

  }, [location]);
  //add the flag to the passed props
  props.passedProps.statusUpdated = statusUpdated;
  props.passedProps.setStatusUpdated = setStatusUpdated;

  let tabs = [
    {
      key: "activeTab",
      title: "Active",
      Component: MissingRemittancesActive,
    },
    {
      key: "historyTab",
      title: "History",
      Component: MissingRemittancesHistory,
    },
  ];

  return (
    <TabBasedFeature
      tabs={tabs}
      defaultActiveKey="activeTab"
      passedProps={props.passedProps}
      activeKey = {querystringTab}
    />
    // <TabBasedFeature tabs={tabs} defaultActiveKey="exportBatchHistory" passedProps={props.passedProps} />
  );
}
