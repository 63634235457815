// Library Home Page: https://github.com/dolezel/react-csv-downloader

export default function csvFormatter(cols = [], rows = []) {
  const headers = [];
  const content = [];

  const unExportableTypes = ["custom"];

  if (cols.length > 0 && rows.length > 0) {
    // set the headers
    cols.forEach((col) => {
      if (!col.hidden && !unExportableTypes.includes(col.type))
        headers.push({ id: col.accessor, displayName: col.title });
    });

    // format the data
    rows.forEach((row) => {
      let rowObj = {};
      cols.forEach((col) => {
        if (!col.hidden && !unExportableTypes.includes(col.type))
          rowObj[col.accessor] = formatter(row[col.accessor], col.type);
      });
      content.push(rowObj);
    });
    content.push(addTotalRow());
  }

  function formatter(value, type) {
    switch (type) {
      case "date":
        return formatDate(value);
      //case 'money': return formatMoney(value);
      case "percent":
        return formatPercent(value);
      case "bool":
        return formatBool(value);
      default:
        return unkownType(value);
    }
  }

  function formatMoney(cellValue) {
    let currency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return cellValue < 0
      ? "(" + currency.format(-cellValue) + ")"
      : currency.format(cellValue);
  }

  function formatPercent(cellValue) {
    let percent = new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return cellValue < 0
      ? "(" + percent.format(cellValue) + ")"
      : percent.format(cellValue);
  }

  function formatBool(cellValue) {
    return cellValue ? "Yes" : "No";
  }

  function unkownType(cellValue) {
    switch (typeof cellValue) {
      case "number":
        return cellValue.toString();
      case "string":
        return cellValue.toLocaleUpperCase();
      default:
        return "";
    }
  }

  function formatDate(cellValue) {
    return cellValue
      ? new Intl.DateTimeFormat("en-US").format(new Date(cellValue)).toString()
      : "";
  }

  function addTotalRow() {
    let rowData = {};
    let allowedTypes = ["money", "number"];

    let showTotal = false;

    cols.forEach((col) => {
      if (!col.hidden && !unExportableTypes.includes(col.type)) {
        if (allowedTypes.includes(col.type) && col.showTotal) {
          rowData[col.accessor] = formatter(
            getColumntotal(col.accessor),
            col.type
          );
          showTotal = true;
        } else {
          rowData[col.accessor] = "";
        }
      }
    });

    if (showTotal) {
      rowData[Object.keys(rowData)[0]] = "Total";
    }

    return rowData;
  }

  function getColumntotal(column) {
    let total = 0.0;
    rows.forEach((row) => {
      total += Math.round((row[column] + Number.EPSILON) * 100) / 100;
    });
    return total;
  }

  return { columns: headers, rows: content };
}
