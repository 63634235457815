import React from "react";
import classNames from 'classnames'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

import './AccordionHeader.css'

export default function AccordionHeader(props) {
    const { expanded, onClick, label, popoverLabel = null, popoverBody = null } = props

    return (
        <div className="accordion-header">
            <AccordionIcon onClick={onClick} expanded={expanded} />
            <AccordionLabel onClick={onClick} label={label} />
            <AccordionPopover popoverLabel={popoverLabel} popoverBody={popoverBody} />
        </div>
    )
}

function AccordionIcon(props) {
    const { onClick, expanded } = props
    let className = classNames('accordion-header-icon', { 'fa fa-chevron-up': expanded }, { 'fa fa-chevron-down': !expanded });

    return (
        <i onClick={onClick} className={className} />
    )
}

function AccordionLabel(props) {
    const { onClick, label } = props

    return (
        <div onClick={onClick} className="accordion-header-label">{label}</div>
    )
}


function AccordionPopover(props) {
    const { popoverLabel, popoverBody } = props

    if (!popoverLabel || !popoverBody) return null

    const popover = (
        <Popover id="popover-basic">
            <Popover.Content>
                {popoverBody}
            </Popover.Content>
        </Popover>
    );

    return (
        <span style={{ float: "right" }}>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                <span>
                    <i className="additional-info-popover-icon fa fa-info-circle" /> {popoverLabel}
                </span>
            </OverlayTrigger>
        </span>
    )

    //return (
    //    <span style={{ float: "right" }}>
    //            <i className="additional-info-popover-icon fa fa-info-circle" /> About Augmented Analytics
    //    </span>
    //)
}