import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import StatusDropdown from "../../MultiSelectDropdown";
import { Form, Row, Col, Button, NavDropdown } from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";

export default function RemittanceLookupForm(props) {
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [isDateLookup, setDateLookup] = useState(true);
  const [dateType, setDateType] = useState("check");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 3))
  );
  const [toDate, setToDate] = useState(new Date());

  const [amount, setAmount] = useState("");
  const [isAmountLookup, setAmountLookup] = useState(false);

  const [checkNumber, setCheckNumber] = useState("");
  const [isCheckLookup, setCheckLookup] = useState(false);

  const location = useLocation();

  //Netx-2437, this will receive the linked check number from the remittance history page
  //If page is called that way, automatically set the filters to the data passed from the history page and submit the form
  useEffect(() => {
    if (location.state && location.state.linkedCheckNumber) {
      setCheckLookup(true);
      setCheckNumber(location.state.linkedCheckNumber);
      setFromDate(location.state.linkedCheckDate);
      setToDate(location.state.linkedCheckDate);

      //Disabling the amount - may re-add nit later and a few other fields as well
      // setAmountLookup(true);
      // setAmount(location.state.linkedCheckAmount);

      submitForm();
    }
  }, [location.state, selectedLocations]);

  const statusOptions = [
    { value: "Archive", label: "Archived" },
    { value: "Pending", label: "Pending" },
  ];

  function validateForm() {
    // location (NCPDP) is a required field
    if (selectedLocations.length === 0)
      return "Location (NCPDP) is a required field";

    // Payer is a required field
    if (selectedPayers.length === 0) return "Payer is a required field";

    // Status is a required field
    if (selectedStatus.length === 0) return "Status is a required field";

    // At least one selection must be made for either date, amount or check number lookup RME-1511
    if (isDateLookup || isAmountLookup || isCheckLookup) {
      // when Date look up is checked then validate date look up values
      if (isDateLookup) {
        // Start Date is a required field
        if (!fromDate) return "Start date is a required field";

        // End Date is a required field
        if (!toDate) return "End date is a required field";

        // Date From cannot be greater tha end date
        if (fromDate > toDate)
          return "Start date cannot be greater than end date";
      }

      function isSpecialCharacters(val, includeDash = false) {
        var pattern = includeDash
          ? new RegExp(/[~`!#$%\^&*+=\[\]\\';,/{}|\\":<>\?]/)
          : new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
        return pattern.test(val);
      }

      // when amount look up is checked then validate amount look up values
      if (isAmountLookup) {
        if (amount) {
          // check if it contains a value
          if (isSpecialCharacters(amount)) {
            // check if value contains any special characters
            return "Please only use standard alphanumerics characters";
          } else if (isNaN(amount)) {
            // check if the value is a number
            return "Amount value should be a number";
          } else {
            let amountVal = amount;
            setAmount(parseFloat(amountVal).toFixed(2));
          }
        } else {
          return "Amount field is required when look up by amount is selected";
        }
      }

      // when check look up is checked then validate the check look up values
      if (isCheckLookup) {
        if (checkNumber) {
          // check if it contains a value
          if (isSpecialCharacters(checkNumber, true)) {
            // check if value contains any special characters
            return "Please only use standard alphanumerics characters";
          } else if (checkNumber.length < 3) {
            // check if value is at least 3 characters long
            return "Check amount should be at least 3 characters long";
          }
        } else {
          return "Check field is required when look up by check number is selected";
        }
      }
    } else {
      return "At least one selection should be made for look up by";
    }

    return null;
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    let errors = validateForm();
    errors ? alert(errors) : submitForm();
  }

  function submitForm() {
    props.handleFormSubmit({
      payers: selectedPayers.join(","),
      locations: selectedLocations.join(","),
      status: selectedStatus.join(","),
      dateType: isDateLookup ? dateType : "",
      fromDate: fromDate && isDateLookup ? fromDate.toLocaleDateString() : "",
      toDate: toDate && isDateLookup ? toDate.toLocaleDateString() : "",
      amount: isAmountLookup ? amount : "",
      checkNumber: isCheckLookup ? checkNumber : "",
    });
  }

  function ExportExcel(e) {
    e.preventDefault();
    props.handlePayerExcelExport();
  }

  function ExportCSV(e) {
    e.preventDefault();
    props.handlePayerCSVExport();
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Remittance Status</Form.Label>
          <StatusDropdown
            options={statusOptions}
            onChange={(Status) => {
              setSelectedStatus(Status);
            }}
            selectAllOption
            filterable
            onLoadAllSelected
          />
        </Col>
        <Col
          md="auto"
          className="d-flex flex-column"
          style={{ padding: `35px 20px 0px 0px` }}
        >
          <NavDropdown
            title={<span>Payer List Report</span>}
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item onClick={ExportExcel} href="#">
              Excel
            </NavDropdown.Item>
            <NavDropdown.Item onClick={ExportCSV} href="#">
              CSV
            </NavDropdown.Item>
          </NavDropdown>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Look Up By...</Form.Label>
          <div key="LookupDate" className="mb-3">
            <Form.Check
              custom={"true"}
              type="checkbox"
              id="lookup1"
              label="Date"
              onChange={(e) => {
                setDateLookup(e.target.checked);
              }}
              defaultChecked
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Date Type</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setDateType(e.target.value);
            }}
            disabled={!isDateLookup}
          >
            <option value="check">Check</option>
            <option value="remittance">Remit</option>
            <option value="archive">Archive</option>
            <option value="payment">Payment</option>
          </Form.Control>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => {
              setFromDate(date);
            }}
            disabled={!isDateLookup}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => {
              setToDate(date);
            }}
            disabled={!isDateLookup}
          />
        </Col>

        <Col
          md="auto"
          className="d-flex flex-column"
          style={{ padding: `35px 20px 0px 0px` }}
        >
          <div key="LookupAmount" className="mb-3">
            <Form.Check
              custom={"true"}
              type="checkbox"
              id="Lookup2"
              label="Amount"
              checked={isAmountLookup}
              onChange={(e) => {
                setAmountLookup(e.target.checked);
              }}
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="text"
            maxLength="19"
            placeholder="Optional"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            value={amount}
            disabled={!isAmountLookup}
          />
        </Col>

        <Col
          md="auto"
          className="d-flex flex-column"
          style={{ padding: `35px 20px 0px 0px` }}
        >
          <div key="LookupCheckNumber" className="mb-3">
            <Form.Check
              custom={"true"}
              type="checkbox"
              id="lookup3"
              label="Check Number"
              checked={isCheckLookup}
              onChange={(e) => {
                setCheckLookup(e.target.checked);
              }}
            />
          </div>
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Check Number</Form.Label>
          <Form.Control
            type="text"
            maxLength="60"
            placeholder="Optional"
            onChange={(e) => {
              setCheckNumber(e.target.value);
            }}
            value={checkNumber}
            disabled={!isCheckLookup}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
