import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "../../Accordion";
import OverpaymentsForm from "./OverpaymentsForm";
import axios from "axios";

import { constants } from "./PaymentExceptionConstants";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import ErrorHandler from "./../../core/ErrorHandler";
import { DetailsModal } from "../../grid/DetailsModal";
import IgnoreModal from "./IgnoreModal";
import PrescriptionDetailsModal from "./PrescriptionDetailsModal";
import { GridColumn } from "@progress/kendo-react-grid";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import { process } from "@progress/kendo-data-query";

import { ZIndexContext } from "@progress/kendo-react-common";
import SharedPaymentExceptionGrid from "./SharedPaymentExceptionGrid";

import {
  MoneyCell,
  AggregateMoneyFooterCell,
  AggregateNumberFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";

import GridExport from "../../export/GridExport";

import { DefaultColumnWidth, DefaultBooleanOrShortWidthColumnWidth } from "../../grid/Kendo/KendoGridAndColumnConstants";

export function Overpayments(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [data, setData] = useState([]);

  const [dataOriginal, setDataOriginal] = useState([]);
  const [detailDataOriginal, setDetailDataOriginal] = useState([]);
  const [columns, setColumns] = useState(props.columns ? props.columns : []);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showViewReportModal, setShowViewReportModal] = useState(false);

  const [detailPayer, setDetailPayer] = useState([]);
  const [currentDetailReport, setCurrentDetailReport] = useState([]);
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);

  const [selectedNCPDP, setSelectedNCPDP] = useState("");
  const [selectedRX, setSelectedRX] = useState("");
  const [ignoreAll, setIgnoreAll] = useState(false);
  const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
  const [selectedClid, setSelectedClid] = useState("");
  const [mainGridHeaderCheckBox, setMainGridHeaderCheckBox] = useState(false);
  const [detailGridHeaderCheckBox, setDetailGridHeaderCheckBox] =
    useState(false);
  const [selectedState, setSelectedState] = React.useState({});

  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);

  const [detailData, setDetailData] = useState([]);

  const [detailsSelectedState, setDetailsSelectedState] = React.useState({});
  const [detailsGridAggregates, setDetailsGridAggregates] = useState([]);
  const [
    detailGridMoneyColumnAggregateSettings,
    setDetailGridMoneyColumnAggregateSettings,
  ] = useState([]);

  const [ClearSelection, SetClearSelection] = useState(0);
  const DATA_ITEM_KEY = "pbid";
  const SELECTED_FIELD = "selected";
  const DETAILS_DATA_ITEM_KEY = "clid";
  const DETAILS_SELECTED_FIELD = "selected";

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const [detailExportData, setDetailExportData] = useState([]);
  const [detailExportColumns, setDetailExportColumns] = useState([]);

  const CustomActionCell = (props) => (
    <CustomCell {...props} color={"#015CAB"} />
  );
  const CustomDetailsActionCell = (props) => (
    <CustomDetailsCell {...props} color={"#015CAB"} />
  );
  const sendAggregatesToParent = (aggregatesFromPaymentsGridSkeleton) => {
    setMainGridAggregates(aggregatesFromPaymentsGridSkeleton);
  };

  const sendDetailAggregatesToParent = (aggregatesFromPaymentsGridSkeleton) => {
    setDetailsGridAggregates(aggregatesFromPaymentsGridSkeleton);
  };

  const OnHeaderSelectChangeEvent = (childData) => {
    setMainGridHeaderCheckBox(childData);
  };
  const OnDetailHeaderSelectChangeEvent = (childData) => {
    setDetailGridHeaderCheckBox(childData);
  };

  const OnStateEventCallback = (data) => {
    setSelectedState(data);
  };

  const OnGridDataStateEvent = (data) => {
    //We are setting export data here - needs to be applied on the entire grid data, not just what is rendered on the screen
    let filterSettings = {};
    filterSettings.filter = data.filter;
    let filteredGridData = process(dataOriginal, filterSettings);

    setExportData(filteredGridData.data);
  };

  const OnDetailStateEventCallback = (data) => {
    setDetailsSelectedState(data);
  };

  const OnDetailGridDataStateEvent = (data) => {
    //We are setting export data here - needs to be applied on the entire grid data, not just what is rendered on the screen
    let filterSettings = {};
    filterSettings.filter = data.filter;
    let filteredGridData = process(detailDataOriginal, filterSettings);

    setDetailExportData(filteredGridData.data);
  };

  const CustomCell = (props) => {
    function onCellClick() {
      getReport(props.dataItem);
    }
    return (
      <td
        {...props.tdProps}
        colSpan={1}
        style={{
          color: props.color,
        }}
      >
        <a
          className="action-cell"
          onClick={onCellClick}
          data-testid={"action-" + props.key}
        >
          View Report
        </a>
      </td>
    );
  };

  const DetailsGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: detailsGridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const CustomDetailsCell = (props) => {
    function onPrescriptionClick() {
      getPrescriptionDetail(props.dataItem.clid);
    }
    function onIgnoreClick() {
      openIgnoreModalWithSelectedClaims(props.dataItem);
    }
    return (
      <td
        {...props.tdProps}
        colSpan={1}
        style={{
          color: props.color,
        }}
      >
        <a
          className="action-cell"
          onClick={onPrescriptionClick}
          data-testid={"action-" + props.key}
        >
          Prescription Details
        </a>
        <br />
        <a
          className="action-cell"
          onClick={onIgnoreClick}
          data-testid={"action-" + props.key}
        >
          Ignore
        </a>
      </td>
    );
  };

  useEffect(() => {
    getData();
  }, [submittedFormData]);

  function getData() {
    if (submittedFormData) {
      try {
        const accessToken = passedProps.auth.getAccessToken();
        let response = axios
          .get(`api/PaymentException/GetOverpaymentsSummary`, {
            params: {
              user: user,
              fromDate: submittedFormData.fromDate,
              toDate: submittedFormData.toDate,
              locations: submittedFormData.locations,
              payers: submittedFormData.payers,
              dateType: submittedFormData.dateType,
              checkNumber: submittedFormData.checkNumber,
              showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function handleFormSubmit(e) {
    setData([]);
    setIsLoading(true);
    setSubmittedFormData(e);
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/PaymentException/GetOverpaymentsSummary":
        setGridData(response.data);
        break;
        case "api/PaymentException/IgnoreClaims":
          getDetailData(currentDetailReport);
        break;
        default:
        break;
    }
  }

  function onFailure(error) {
    setIsLoading(false);
    setError(error.response);
    switch (error.response.config.url) {
      case "api/PaymentException/GetOverpaymentsSummary":
        setData([]);
        setColumns([]);
        break;
      case "api/PaymentException/GetOverpaymentsDetails":
        setDetailData([]);

        break;
      default:
        break;
    }
  }
  function setGridData(data) {
    let rows = data;
    const columns = [];
    if (rows.length > 0) {
      columns.push({ accessor: "Checkbox", title: "", type: "custom" });
      columns.push({ accessor: "Action", title: "Action", type: "custom" });

      const headerProps = constants.headerProps;
      Object.keys(rows[0]).map((key) => {
        return columns.push({
          accessor: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          showTotal: headerProps[key].showTotal,
          hidden: headerProps[key].hidden,
        });
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }

    setData(rows);
    //This will be used for grid exports and this will be the original data which was never nmodified
    setDataOriginal(rows);
    setColumns(columns);
    setIsLoading(false);
    let moneyColumns = [];
    columns.forEach((column) => {
      if (column.type === "money") {
        moneyColumns.push({ aggregate: "sum", field: column.accessor });
      }
      if (column.type === "number" && column.showTotal) {
        moneyColumns.push({ aggregate: "sum", field: column.accessor });
      }
    });

    setMainGridMoneyColumnAggregateSettings(moneyColumns);
  }
  function setDetailGridData(data) {
    let rows = data;
    const columns = [];
    if (rows.length > 0) {
      const headerProps = constants.detailHeaderProps;
      Object.keys(rows[0]).forEach((key) => {
        columns.push({
          accessor: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          fixedLeft: headerProps[key].fixedLeft,
          showTotal: headerProps[key].showTotal,
          hidden: headerProps[key].hidden,
        });
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }
    setDetailData(rows);

    //This will be used for grid exports and this will be the original data which was never nmodified
    setDetailDataOriginal(rows);

    setIsLoading(false);
    setShowViewReportModal(true);
    let moneyColumns = [
      { aggregate: "sum", field: "adjudicatedCopay" },
      { aggregate: "sum", field: "adjudicated" },
      { aggregate: "sum", field: "paid" },
      { aggregate: "sum", field: "difference" },
    ];
    setDetailGridMoneyColumnAggregateSettings(moneyColumns);
  }

  function CreateGridColumn(column) {
    let columnWidth = DefaultColumnWidth();

    let filterType = "text";
    if (column.type === "money" || column.type === "number") {
      filterType = "numeric";
    }

    if (column.hidden) {
      return;
    }
    if (column.accessor === "Checkbox") {
      return (
        <GridColumn
          field={SELECTED_FIELD}
          key={column.accessor}
          width="50"
          filterable={false}
          headerSelectionValue={mainGridHeaderCheckBox}
        />
      );
    } else if (column.accessor === "Action") {
      return (
        <GridColumn
          key={column.accessor}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          filterable={false}
          cells={{
            data: CustomActionCell,
          }}
        />
      );
    } else if (column.type === "money") {
      return (
        <GridColumn
          key={column.accessor}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          cells={{
            data: MoneyCell,
            footerCell: MainGridAggregateMoneyFooterCell,
          }}
          footerCell={MainGridAggregateMoneyFooterCell}
        />
      );
    } else if (column.type === "number") {
      return (
        <GridColumn
          key={column.accessor}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          cells={{
            footerCell: MainGridAggregateNumberFooterCell,
          }}
          footerCell={MainGridAggregateNumberFooterCell}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
        />
      );
    }
  }

  const MainGridAggregateMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const MainGridAggregateNumberFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };

    return AggregateNumberFooterCell(props, options);
  };

  async function getDetailData(row) {
    setIsLoading(true);
    try {
      const accessToken = await passedProps.auth.getAccessToken();
      const response = await axios.get(
        `api/PaymentException/GetOverpaymentsDetails`,
        {
          params: {
            user: user,
            fromDate: submittedFormData.fromDate,
            toDate: submittedFormData.toDate,
            locations: submittedFormData.locations,
            payers:
              row.pbid ||
              Object.keys(selectedState)
                .filter((key) => selectedState[key] === true)
                .map(Number)
                .join(","),
            dateType: submittedFormData.dateType,
            checkNumber: submittedFormData.checkNumber,
            showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      let detailsDataWithCleanedDates = response.data.map((t) => {
        return {
          ...t,
          dispensedDate: IsNullOrWhitespace(t.dispensedDate)
            ? ""
            : new Date(Date.parse(t.dispensedDate)),
          transactionDate: IsNullOrWhitespace(t.transactionDate)
            ? ""
            : new Date(Date.parse(t.transactionDate)),
        };
      });
      setDetailGridData(detailsDataWithCleanedDates);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }

  function customExportData(data) {
    const allValues = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key].forEach((obj) => {
          allValues.push(obj);
        });
      }
    }
    return allValues;
  }

  async function getReport(row) {
    if (row.payer) {
      setDetailPayer(row.payer);
      setCurrentDetailReport(row);
    } else {
      const payers = [];
      const rows = [];
      let pbids = Object.keys(selectedState)
        .filter((key) => selectedState[key] === true)
        .map(Number);
      for (const pbid of pbids) {
        const foundObject = data.find((item) => item.pbid === pbid);
        if (foundObject) {
          payers.push(foundObject.payer);
          rows.push(foundObject);
        }
      }
      setDetailPayer(payers.join(","));
      setCurrentDetailReport(rows);
      if (!row.payer && payers.length < 1) {
        return alert("Please select a report to view");
      }
    }
    setIsLoading(true);
    try {
      const accessToken = await passedProps.auth.getAccessToken();
      const exportWithoutDetail = await axios.get(
        `api/PaymentException/GetOverpaymentExport`,
        {
          params: {
            user: user,
            fromDate: submittedFormData.fromDate,
            toDate: submittedFormData.toDate,
            locations: submittedFormData.locations,
            payers:
              row.pbid ||
              Object.keys(selectedState)
                .filter((key) => selectedState[key] === true)
                .map(Number)
                .join(","),
            dateType: submittedFormData.dateType,
            checkNumber: submittedFormData.checkNumber,
            showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      getDetailData(row);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  }
  function getPrescriptionDetail(clid) {
    setSelectedClid(clid);
    setShowPrescriptionModal(true);
    setShowViewReportModal(false);
  }

  function HandlDetailClose() {
    setShowViewReportModal(false);
    SetClearSelection((current) => current + 1);
    setIsLoading(true);
    getData();
  }

  function openIgnoreModalWithSelectedClaims(row) {
    if (row.clid) {
      setSelectedNCPDP(row.ncpdp);
      setSelectedRX(row.rxNumber);
      setIgnoreAll(false);
    } else {
      setIgnoreAll(true);
      setSelectedNCPDP("All Selected");
      setSelectedRX("All Selected");
    }
    setShowIgnoreModal(true);
    setShowViewReportModal(false);
  }

  //Called when user clicks save in ignore details modal
  const handleSaveIgnoreClick = async (selectedIgnoreOption, ignoreNote) => {
    if (!selectedIgnoreOption) {
      return alert("Please Select an Ignore Code to Save!");
    }
    setShowIgnoreModal(false);
    setShowViewReportModal(true);
    var strClids = "";
    if (selectedIgnoreOption) {
      if (!ignoreAll) {
        var objIndex = detailData.findIndex(
          (obj) => obj.ncpdp == selectedNCPDP && obj.rxNumber == selectedRX
        );
        strClids = detailData[objIndex].clid;
      } else {
        strClids = Object.keys(detailsSelectedState)
          .filter((key) => detailsSelectedState[key] === true)
          .map(Number)
          .join(",");
      }

      try {
        setIsLoading(true);

        const accessToken = passedProps.auth.getAccessToken();
        const response = await axios
          .get("api/PaymentException/IgnoreClaims", {
            params: {
              user: user,
              ignoreId: selectedIgnoreOption,
              ignoreNote: ignoreNote,
              claimIds: strClids,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          //NOTE TODO: Instead of resetting the grid here, I think we need to wait until we get the response first
          // .then(resetGrid(strClids))
          .then(onSuccess)
          .catch(onFailure);

          // updateGridAfterIgnoreAction(strClids, response);

      } catch (exception) {
        onFailure(exception);
      }
    }
  }

  function handleCellClick(row, column) {
    switch (column) {
      case "viewReport":
        getReport(row);
        break;
      case "prescriptionDetail":
        getPrescriptionDetail(row.clid);
        break;
      case "ignore":
        setIgnoreAll(false);
        openIgnoreModalWithSelectedClaims(row);
        break;
      case "excelExport":
        break;
      case "pdfExport":
        break;
      default:
        break;
    }
  }

  function updateGridAfterIgnoreAction() {
    // if (claimIds == "") {
    //   return;
    // }

    getDetailData(currentDetailReport);
  }

  function handleIgnoreClose() {
    setShowIgnoreModal(false);
    setIgnoreAll(false);
    setShowViewReportModal(true);
  }
  function handlePrescriptionDetailClose() {
    setShowPrescriptionModal(false), setShowViewReportModal(true);
  }
  return (
    <div className="feature">
      <LoadingSpinner isDataLoading={isLoading} controlsName="Overpayments" />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters">
            <OverpaymentsForm
              handleFormSubmit={handleFormSubmit}
              auth={passedProps.auth}
            />
          </Col>
          {/* <Col>
                        <FeatureWidget />
                    </Col> */}
        </Row>
      </Accordion>
      <Row className="align-items-center">
        <div className="col-md-auto">
          <GridExport
            exportData={exportData}
            exportDataColumns={exportColumns}
            exportFileNamePrefix={"PaymentExceptionOverpayments"}
          />
        </div>
        <div className="col-md-auto">
          <div>
            <label className="action-cell" onClick={getReport}>
              View Selected Reports
            </label>
          </div>
        </div>
      </Row>

      <div>
        <Row>
          <Col className="tight-grid multi-line-filter">
            <SharedPaymentExceptionGrid
              data={data}
              aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
              sendAggregatesParentCallback={sendAggregatesToParent}
              DATA_ITEM_KEY={DATA_ITEM_KEY}
              SELECTED_FIELD={SELECTED_FIELD}
              OnHeaderSelectChangeEvent={OnHeaderSelectChangeEvent}
              OnStateEventCallback={OnStateEventCallback}
              OnGridDataStateEvent={OnGridDataStateEvent}
              ClearSelection={ClearSelection}
              SetExportColumns={setExportColumns}
            >
              {columns.length > 0
                ? columns.map((singleColumn) => CreateGridColumn(singleColumn))
                : null}
            </SharedPaymentExceptionGrid>
          </Col>
        </Row>
      </div>

      <DetailsModal
        title={detailPayer}
        show={showViewReportModal}
        handleClose={HandlDetailClose}
      >
        <Row className="align-items-center">
          <div className="col-md-auto">
            <GridExport
              exportData={detailExportData}
              exportDataColumns={detailExportColumns}
              exportFileNamePrefix={"PaymentExceptionOverpaymentsDetails"}
            />
          </div>

          <div className="col-md-auto">
            <a className="action-cell" onClick={openIgnoreModalWithSelectedClaims}>
              Ignore Selected
            </a>
          </div>
        </Row>

        <ZIndexContext.Provider value={10003}>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <SharedPaymentExceptionGrid
                data={detailData}
                aggregateColumnSettings={detailGridMoneyColumnAggregateSettings}
                sendAggregatesParentCallback={sendDetailAggregatesToParent}
                DATA_ITEM_KEY={DETAILS_DATA_ITEM_KEY}
                SELECTED_FIELD={DETAILS_SELECTED_FIELD}
                OnHeaderSelectChangeEvent={OnDetailHeaderSelectChangeEvent}
                OnStateEventCallback={OnDetailStateEventCallback}
                OnGridDataStateEvent={OnDetailGridDataStateEvent}
                SetExportColumns={setDetailExportColumns}
              >
                <GridColumn
                  field={DETAILS_SELECTED_FIELD}
                  width="50px"
                  filterable={false}
                  headerSelectionValue={detailGridHeaderCheckBox}
                />
                <GridColumn
                  key={"Action"}
                  field={"Action"}
                  title={"Action"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filterable={false}
                  cells={{
                    data: CustomDetailsActionCell,
                  }}
                />
                <GridColumn
                  key={"ncpdp"}
                  field={"ncpdp"}
                  title={"NCPDP"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"rxNumber"}
                  field={"rxNumber"}
                  title={"Rx #"}
                  filter={"text"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"dispensedDate"}
                  field={"dispensedDate"}
                  title={"Dispensed Date"}
                  filter={"date"}
                  format="{0:d}"
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"transactionDate"}
                  field={"transactionDate"}
                  title={"Transaction Date"}
                  filter={"date"}
                  format="{0:d}"
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"patient"}
                  field={"patient"}
                  title={"Patient"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"payer"}
                  field={"payer"}
                  title={"Payer"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"claimNumber"}
                  field={"claimNumber"}
                  title={"Claim Number"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"checkNumber"}
                  field={"checkNumber"}
                  title={"Check Number"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"checkDate"}
                  field={"checkDate"}
                  title={"Check Date"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"adjudicatedCopay"}
                  field={"adjudicatedCopay"}
                  title={"Adjudicated Copay"}
                  filter={"numeric"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyFooterCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyFooterCell}
                />
                <GridColumn
                  key={"paidCopay"}
                  field={"paidCopay"}
                  title={"Paid Copay"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  cells={{
                    data: MoneyCell,
                  }}
                />
                <GridColumn
                  key={"adjudicated"}
                  field={"adjudicated"}
                  title={"Adjudicated"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyFooterCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyFooterCell}
                />
                <GridColumn
                  key={"paid"}
                  field={"paid"}
                  title={"Paid"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyFooterCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyFooterCell}
                />
                <GridColumn
                  key={"difference"}
                  field={"difference"}
                  title={"Difference"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyFooterCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyFooterCell}
                />
              </SharedPaymentExceptionGrid>
            </Col>
          </Row>
        </ZIndexContext.Provider>
      </DetailsModal>
      <IgnoreModal
        show={showIgnoreModal}
        selectedNCPDP={selectedNCPDP}
        selectedRX={selectedRX}
        handleSaveIgnoreClick={handleSaveIgnoreClick}
        handleClose={handleIgnoreClose}
      />
      <PrescriptionDetailsModal
        auth={passedProps.auth}
        clid={selectedClid}
        show={showPrescriptionModal}
        handleClose={handlePrescriptionDetailClose}
        handleCellClick={handleCellClick}
        user={user}
      />
    </div>
  );
}
