import { useContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { UserContext } from "../context/UserContext";

import { IsUnrestrictedEraRoute } from "../../constants/EraPaths";

export function useParentCompanyLocationValidation() {
  const userContext = useContext(UserContext);
  const { oktaAuth } = useOktaAuth();

  const [eraOnly, setEraOnly] = useState(false);
  const [is835RemittanceExportAllowed, setIs835RemittanceExportAllowed] = useState(false);

  //if EraOnly is true, then the user can only Access Allowed ERA Routes

  let { currentOrganization } = userContext;

  if (!currentOrganization) {
    currentOrganization = {
      value: null,
      type: null,
    };
  }

  function onFailure(error) {
    //todo: write a log
  }

  function onParentCompanyLevelAttributeResponseSuccess(response) {
    let data = response.data;

    //if there is no data, then we just set the default values
    if (!data || data.length === 0) {
      if (eraOnly !== false) {
        setEraOnly(false);
      }

      if (is835RemittanceExportAllowed !== false) {
        setIs835RemittanceExportAllowed(false);
      }
      return;
    }

    //check if there is even 1 record in the response where the attributeValue is not ERA
    let nonEraLocationExists = data.some(
      (x) => x.attributeValue.trim().toUpperCase() !== "ERA" &&
        x.attributeName.trim().toUpperCase() === "PROCESS TYPE"
    );

    //NOTE: Check for 835 Export Options
    let hasExportOptionsExists = data.some(
      (x) => x.attributeValue.trim().toUpperCase() !== "" &&
        x.attributeName.trim().toUpperCase() === "835 EXPORT OPTIONS"
    );

    if (nonEraLocationExists) {
      if (eraOnly !== false) {
        setEraOnly(false);
      }
    } else {
      if (eraOnly !== true) {
        setEraOnly(true);
      }
    }

    if (!hasExportOptionsExists) {
      if (is835RemittanceExportAllowed !== false) {
        setIs835RemittanceExportAllowed(false);
      }
    } else {
      if (is835RemittanceExportAllowed !== true) {
        setIs835RemittanceExportAllowed(true);
      }
    }
  }

  function IsAllowedRoute(route) {
    if (eraOnly === false) return true;

    return IsUnrestrictedEraRoute(route);
  }

  async function GetParentCompanyLevelAttributes() {
    try {
      let accessToken = oktaAuth.getAccessToken();

      let remittanceExportAndProcessTypeParameters = {
        dataSourceType: currentOrganization.type,
        dataSourceId: currentOrganization.value,
        attributes: "Process Type,835 Export Options",
      };

      await axios
        .get("api/Members/GetMemberParentCompanyAttributes", {
          params: remittanceExportAndProcessTypeParameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onParentCompanyLevelAttributeResponseSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  useEffect(() => {
    //if the currentOrganization.value is null, then we do not proceed
    if (!currentOrganization.value) {
      if (eraOnly !== false) setEraOnly(false);

      return;
    }

    GetParentCompanyLevelAttributes();

  }, [currentOrganization]);

  return { eraOnly, is835RemittanceExportAllowed, IsAllowedRoute };
}
