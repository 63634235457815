import React from "react";
import { Button } from "react-bootstrap";

//NOTE TODO: I think we can pass a button with a callback here to reset the parent company
const ParentCompanyDisplayElement = (parentCompanyName, resetValuesCallback) => {
    return (
        <div>
            <div>
                Selected Parent Company: {parentCompanyName}
            </div>
            <div>
                <Button className="inline-show-result-button" size="sm" onClick={resetValuesCallback}>
                    Reset Selected Parent Company
                </Button>
            </div>
        </div>
    );
};

export {
    ParentCompanyDisplayElement
};
