import React, { Component } from 'react';

import 'font-awesome/css/font-awesome.min.css';
import './ContentHeader.css'
import { Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap'

export class ContentHeader extends Component {
    render() {
        return (
            <Row className="no-gutters" id="content-header">

                <Col className="col-sm-6" id="feature-title">
                    {this.props.featureTitle}
                </Col>
                {/* <Col className="sm-auto no-gutters" id="content-search">
                <InputGroup>
                        <FormControl type="text" placeholder="Search" />
                        <Button className="fa fa-search"></Button>
                    </InputGroup>
                </Col> */}
            </Row>
        );
    }
}