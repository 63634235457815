import axios from "axios";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { TotalSalesForm } from "./TotalSalesForm";
import ErrorHandler from "./../../core/ErrorHandler";
import { DetailsModal } from "../../grid/DetailsModal";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import Accordion from "../../Accordion";
import { constants } from "./TotalSalesConstants";
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import { GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ZIndexContext } from "@progress/kendo-react-common";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";

import {DefaultColumnWidth, DefaultDateColumnWidth, DefaultBooleanOrShortWidthColumnWidth, DefaultNoFilterColumnWidth} from "../../grid/Kendo/KendoGridAndColumnConstants";

import {
  MoneyCell,
  AggregateMoneyFooterCell,
  AggregateNumberFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";

export function TotalSalesByBin(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState({});

  const [mainGridData, setMainGridData] = useState([]);
  const [mainGridColumns, setMainGridColumns] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [detailGridData, setDetailGridData] = useState([]);
  const [detailGridColumns, setDetailGridColumns] = useState([]);
  const [detailError, setDetailError] = useState({});
  const [
    detailGridMoneyColumnAggregateSettings,
    setDetailGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [detailGridAggregates, setDetailGridAggregates] = useState([]);
  const [mainGridfilter, setMainGridFilter] = useState([]);

  const _container = React.useRef();
  const sendMainAggregatesToParent = (aggregatesFromSharedTotalSalesGrid) => {
    setMainGridAggregates(aggregatesFromSharedTotalSalesGrid.data);
    setMainGridFilter(aggregatesFromSharedTotalSalesGrid.filter);
  };

  const sendDetailAggregatesToParent = (aggregatesFromSharedTotalSalesGrid) => {
    setDetailGridAggregates(aggregatesFromSharedTotalSalesGrid.data);
  };

  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);

    let accessToken = passedProps.auth.getAccessToken();
    try {
      let response = axios
        .get(`api/TotalSales/GetByBin`, {
          params: {
            user: user,
            toDate: e.toDate,
            fromDate: e.fromDate,
            dateType: e.dateType,
            paidAmountType: e.paidAmountType,
            includeCopay: e.includeCopay,
            omitIgnored: e.omitIgnored,
            mmids: e.selectedLocation,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function CreateGridColumn(column) {
    let columnWidth = 150;

    if (column.locked) {
      columnWidth = 200;
    }

    let filterType = "text";
    if (column.type === "money" || column.type === "number") {
      filterType = "numeric";
    }

    if (column.type === "money") {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.locked}
          cells={{
            data: MainGridMoneyCell,
            footerCell: MainGridAggregateMoneyFooterCell,
          }}
          footerCell={MainGridAggregateMoneyFooterCell}
        />
      );
    } else if (column.type === "number") {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.locked}
          cells={{
            footerCell: AggregateFooterCell,
          }}
          footerCell={AggregateFooterCell}
        />
      );
    } else {
      return (
        <GridColumn
          key={column.field}
          filter={filterType}
          field={column.field}
          title={column.title}
          width={columnWidth}
          locked={column.locked}
        />
      );
    }
  }

  let MainGridMoneyCell = (props) => {
    let onCellClick = () => {
      let accessToken = passedProps.auth.getAccessToken();
      if (props.field == "TotalSales") {
        setIsLoading(true);
        let response = axios
          .get(`api/TotalSales/GetByBinAggregateDetails`, {
            params: {
              bin: props.dataItem["BIN"],
              searchStartDate: submittedFormData.fromDate,
              searchEndDate: submittedFormData.toDate,
              dateType: submittedFormData.dateType,
              omitIgnored: submittedFormData.omitIgnored,
              mmids: submittedFormData.selectedLocation,
              user: user,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } else {
        try {
          setIsLoading(true);
          let response = axios
            .get(`api/TotalSales/GetByBinDetails`, {
              params: {
                bin: props.dataItem["BIN"],
                searchDate: props.field,
                dateType: submittedFormData.dateType,
                omitIgnored: submittedFormData.omitIgnored,
                mmids: submittedFormData.selectedLocation,
                user: user,
              },
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then(onSuccess)
            .catch(onFailure);
        } catch (exception) {
          onFailure(exception);
        }
      }
    };

    let options = {
      onClick: onCellClick,
    };

    return MoneyCell(props, options);
  };

  let AggregateFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };
    return AggregateNumberFooterCell(props, options);
  };

  let DetailsGridAggregateMoneyCell = (props) => {
    var options = {
      aggregateData: detailGridAggregates,
    };
    return AggregateMoneyFooterCell(props, options);
  };

  const MainGridAggregateMoneyFooterCell = (props) => {
    let onCellClick = () => {
      var startDate;
      var endDate;
      if (props.field == "TotalSales") {
        startDate = submittedFormData.fromDate;
        endDate = submittedFormData.toDate;
      } else {
        startDate = props.field;
        endDate = props.field;
      }

      let pushBIN = [];
      if (mainGridfilter == null || mainGridfilter == undefined) {
        for (var count = 0; count < mainGridData.length; count++) {
          pushBIN.push(mainGridData[count].BIN);
        }
      } else {
        let filterSettings = {};
        filterSettings.filter = mainGridfilter;
        var filterResult = process(mainGridData, filterSettings).data;
        for (var count = 0; count < filterResult.length; count++) {
          pushBIN.push(filterResult[count].BIN);
        }
      }
      if (pushBIN.length == 0) {
        return;
      }

      let accessToken = passedProps.auth.getAccessToken();
      try {
        setIsLoading(true);
        let response = axios
          .get(`api/TotalSales/GetByBinAggregateDetails`, {
            params: {
              user: user,
              bin: pushBIN.toString(),
              searchStartDate: startDate,
              searchEndDate: endDate,
              omitIgnored: submittedFormData.omitIgnored,
              mmids: submittedFormData.selectedLocation,
              DateType: submittedFormData.dateType,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(onSuccess)
          .catch(onFailure);
      } catch (exception) {
        onFailure(exception);
      }
    };

    var options = {
      aggregateData: mainGridAggregates,
      onClick: onCellClick,
    };
    return AggregateMoneyFooterCell(props, options);
  };

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/TotalSales/GetByBin":
        setGridData(response.data);
        break;
      case "api/TotalSales/GetByBinDetails":
        setGridDetailData(response.data);
        break;
      case "api/TotalSales/GetByBinAggregateDetails":
        setGridDetailData(response.data);
        break;
      default:
        break;
    }
    setIsLoading(false);
  }

  function onFailure(error) {
    switch (error.response.config.url) {
      case "api/TotalSales/GetByBin":
        setError(error.response);
        setMainGridData([]);
        setMainGridColumns([]);
        break;
      case "api/TotalSales/GetByBinDetails":
        setDetailError(error.response);
        setDetailGridData([]);
        setDetailGridColumns([]);
        break;
      default:
        setError(error.response);
        setMainGridData([]);
        setMainGridColumns([]);
        break;
    }

    setIsLoading(false);
  }

  function setGridData(data) {
    let columns = [];

    if (data.length > 0) {
      let headerProps = {
        Payer: { title: "Payer Name", locked: true },
        BIN: { title: "BIN", locked: true },
        TotalSales: {
          title: "Total Sales",
          type: "money",
          showTotal: true,
          locked: true,
        },
      };
      Object.keys(data[0]).map((key) => {
        return columns.push({
          title: headerProps[key]
            ? headerProps[key].title
            : key.includes("Count")
            ? "Claim Count"
            : key,
          type: headerProps[key]
            ? headerProps[key].type
            : key.includes("Count")
            ? "number"
            : "money",
          field: key,
          showTotal: headerProps[key] ? headerProps[key].showTotal : true,
          fixedLeft: headerProps[key] ? true : false,
          selectable: headerProps[key] || key.includes("Count") ? false : true,
          locked: headerProps[key] ? headerProps[key].locked : false,
        });
      });

      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }
    setMainGridData(data);
    setMainGridColumns(columns);
    setIsLoading(false);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (column.type === "money" || column.type === "number") {
        moneyColumns.push({ aggregate: "sum", field: column.field });
      }
    });

    setMainGridMoneyColumnAggregateSettings(moneyColumns);
  }

  function setGridDetailData(data) {
    let columns = [];

    if (data.length > 0) {
      let headerProps = constants.detailGridHeaderProps;
      Object.keys(data[0]).map((key) => {
        return columns.push({
          field: key,
          title: headerProps[key].title,
          type: headerProps[key].type,
          showTotal: headerProps[key].showTotal,
          fixedLeft: headerProps[key].fixedLeft,
        });
      });

      setDetailError({});
    } else {
      setDetailError({
        status: 201,
        Message: "No data found for given params",
      });
    }

    let detailsDataWithCleanedDates = data.map((t) => {
      return {
        ...t,
        DispensedDate: IsNullOrWhitespace(t.DispensedDate)
          ? ""
          : new Date(Date.parse(t.DispensedDate)),
        TransactionDate: IsNullOrWhitespace(t.TransactionDate)
          ? ""
          : new Date(Date.parse(t.TransactionDate)),
        LastCheckDate: IsNullOrWhitespace(t.LastCheckDate)
          ? ""
          : new Date(Date.parse(t.LastCheckDate)),
        ArchiveDate: IsNullOrWhitespace(t.ArchiveDate)
          ? ""
          : new Date(Date.parse(t.ArchiveDate)),
        LastCheckPaymentDate: IsNullOrWhitespace(t.LastCheckPaymentDate)
          ? ""
          : new Date(Date.parse(t.LastCheckPaymentDate)),
      };
    });

    setDetailGridData(detailsDataWithCleanedDates);
    setDetailGridColumns(columns);
    setIsLoading(false);
    setShowModal(true);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (column.type === "money") {
        moneyColumns.push({ aggregate: "sum", field: column.field });
      } else if (column.type === "number" && column.showTotal) {
        moneyColumns.push({ aggregate: "sum", field: column.field });
      }
    });
    setDetailGridMoneyColumnAggregateSettings(moneyColumns);
  }

  return (
    <div className="feature-tab" id="total-sales-by-bin">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"totalSalesByBin"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      <Accordion defaultExpanded label="Search & Filter">
        <Row>
          <Col className="higher-zindex-filters">
            <TotalSalesForm handleFormSubmit={handleFormSubmit} _uid="TSB" />
          </Col>
        </Row>
      </Accordion>
      <div ref={_container}>
        <Row>
          <Col className="tight-grid multi-line-filter">
            <SharedMainGrid
              data={mainGridData}
              columns={mainGridColumns}
              aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
              sendAggregatesParentCallback={sendMainAggregatesToParent}
              scrollType="scrollable"
              exportFileNamePrefix={"TotalSalesByBin"}
            >
              {mainGridColumns.length > 0
                ? mainGridColumns.map((singleColumn) =>
                    CreateGridColumn(singleColumn)
                  )
                : null}
            </SharedMainGrid>
          </Col>
        </Row>
      </div>
      <DetailsModal
        title="Total Sales Detail"
        show={showModal}
        handleClose={() => setShowModal(false)}
      >
        <ErrorHandler
          error={detailError}
          onClose={() => {
            setDetailError({});
          }}
        />
        <ZIndexContext.Provider value={10003}>
          <Row>
            <Col className="tight-grid multi-line-filter">
              <SharedMainGrid
                data={detailGridData}
                columns={detailGridColumns}
                aggregateColumnSettings={detailGridMoneyColumnAggregateSettings}
                sendAggregatesParentCallback={sendDetailAggregatesToParent}
                dataItemKey="CLID"
                scrollType="scrollable"
                styleOverride={{
                  maxHeight: "40em",
                }}
                exportFileNamePrefix={"TotalSalesByBinDetails"}
              >
                <GridColumn
                  key={"NCPDP"}
                  field={"NCPDP"}
                  title={"NCPDP"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  locked={true}
                />
                <GridColumn
                  key={"Script"}
                  field={"Script"}
                  title={"Prescription"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  locked={true}
                />
                <GridColumn
                  key={"DispensedDate"}
                  field={"DispensedDate"}
                  title={"Dispensed Date"}
                  width={DefaultDateColumnWidth()}
                  filter={"date"}
                  format="{0:d}"
                  locked={true}
                />
                <GridColumn
                  key={"TransactionDate"}
                  field={"TransactionDate"}
                  title={"Transaction Date"}
                  width={DefaultDateColumnWidth()}
                  filter={"date"}
                  format="{0:d}"
                />
                <GridColumn
                  key={"Payer"}
                  field={"Payer"}
                  title={"Payer Name"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"BIN"}
                  field={"BIN"}
                  title={"BIN"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"PCN"}
                  field={"PCN"}
                  title={"PCN"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"AdjudicatedAmount"}
                  field={"AdjudicatedAmount"}
                  title={"Promise To Pay"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyCell}
                />
                <GridColumn
                  key={"PaidAmount"}
                  field={"PaidAmount"}
                  title={"Remittance Paid"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyCell}
                />
                <GridColumn
                  key={"CoPay"}
                  field={"CoPay"}
                  title={"Co-Pay"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    data: MoneyCell,
                    footerCell: DetailsGridAggregateMoneyCell,
                  }}
                  footerCell={DetailsGridAggregateMoneyCell}
                />
                <GridColumn
                  key={"NDC"}
                  field={"NDC"}
                  title={"NDC"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"Quantity"}
                  field={"Quantity"}
                  title={"Qty"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    footerCell: AggregateFooterCell,
                  }}
                  footerCell={AggregateFooterCell}
                />
                <GridColumn
                  key={"DaysSupply"}
                  field={"DaysSupply"}
                  title={"Days Supply"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"numeric"}
                  cells={{
                    footerCell: AggregateFooterCell,
                  }}
                  footerCell={AggregateFooterCell}
                />
                <GridColumn
                  key={"AuthorizationID"}
                  field={"AuthorizationID"}
                  title={"Authorization ID"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"CardholderID"}
                  field={"CardholderID"}
                  title={"Cardholder ID"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"LastCheckNo"}
                  field={"LastCheckNo"}
                  title={"Last Received Check #"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                />
                <GridColumn
                  key={"LastCheckDate"}
                  field={"LastCheckDate"}
                  title={"Last Received Check Date"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"date"}
                  format="{0:d}"
                />
                <GridColumn
                  key={"LastCheckPaymentDate"}
                  field={"LastCheckPaymentDate"}
                  title={"Last Received Check Payment Date"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"date"}
                  format="{0:d}"
                />
                <GridColumn
                  key={"ArchiveDate"}
                  field={"ArchiveDate"}
                  title={"Payment/Check Archive Date"}
                  width={DefaultBooleanOrShortWidthColumnWidth()}
                  filter={"date"}
                  format="{0:d}"
                />
              </SharedMainGrid>
            </Col>
          </Row>
        </ZIndexContext.Provider>
      </DetailsModal>
    </div>
  );
}
