import React from 'react'

import './SelectableRowFilterCell.css'

export default function SelectableRowFilterCell(props) {
    const { show, leftOffset } = props

    if (!show) return null

    const styles = leftOffset > 0 ? {
        transform: `translate(${leftOffset}px, 0px)`,
        position: "relative",
        zIndex: 1
    } : null

    return (
        <div className="selectable-row-filter-cell" style={styles}/>
    )
}