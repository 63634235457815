import React from 'react'

export const constants = {
    headerProps: {
        ncpdp: { title: 'NCPDP', fixedLeft: true },
        rxNumber: { title: 'Rx #', fixedLeft: true },
        serviceDate: { title: 'Service Date', type: 'date', fixedLeft: true },
        bin: { title: 'BIN' },
        pcn: { title: 'PCN' },
        planName: { title: 'Plan Name' },
        pbmMessage: { title: 'PBM Message', truncatable: true },
        additionalPbmMessage: { title: 'Additional PBM Message', truncatable: true },
        category: {
            title: 'Category',
            headerInfo:
                <React.Fragment>
                    <p> COB
                    <br /> Controlled Sub
                    <br /> Day Supply
                    <br /> DUR
                    <br /> eVoucher
                    <br /> Formulary
                    <br /> Manuf.Discount
                    <br /> MTM Eligible
                    <br /> Part B
                    <br /> Pricing
                    <br /> Prior Auth
                    <br /> Transition </p>
                </React.Fragment>

        },
        quantity: {
            title: 'Qty',
            type: 'number'
        },
        daySupply: {
            title: 'Day Supply',
            type: 'number'
        },
        bgIndicator: {
            title: 'B/G',
            headerInfo:
                <React.Fragment>
                    <p><b>B/G: Brand Generic Indicator</b>
                    <br />B=Brand (Single Source, no generic available)
                    <br />G=Generic
                    <br />BO=Brand Originator (Generic available)
                    <br />BG=Branded Generic
                    <br />MB=Multisource Brand
                    <br />MG=Generic for Multisource Brand
                    <br />OB=OTC Brand (Single Source, no generic available)
                    <br />OG=OTC Generic
                    <br />OBO=OTC Brand Originator (Generic available)
                    <br />OBG=OTC Branded Generic
                    <br />OMB=OTC Multisource Brand
                    <br />OMG=OTC Generic for Multisource Brand </p>
                </React.Fragment>
        },
        dispensedDrug: { title: 'Dispensed Drug' },
        daw: {
            title: 'DAW',
            headerInfo:
                <React.Fragment>
                    <p><b>DAW: Dispense as Written Product Selection.</b>
                    <br />0=Single Source Brand or Generic
                    <br />1=Substitution not allowed by Prescriber
                    <br />2=Patient Requested Brand
                    <br />3=Pharmacist selected Brand
                    <br />4=Generic not in stock
                    <br />5=Brand dispensed as generic
                    <br />6=Override – used per payer instructions
                    <br />7=Brand mandated by law
                    <br />8=Generic not available in marketplace
                    <br />9=Plan requests brand </p>
                </React.Fragment>
        },
        scC1: { title: 'SCC' },
        prc: {
            title: 'PRC',
            headerInfo:
                <React.Fragment>
                    <p><b>Residence Code: Patient’s place of Residence.</b>
                    <br />0=Not Specified
                    <br />1=Home
                    <br />2=SNF Medicare B Use Only
                    <br />3=SNF
                    <br />4=ALF
                    <br />5=Custodial Care/Part B
                    <br />6=Group Home
                    <br />7=Inpatient Psychiatic Facility/non-pharmacy
                    <br />8=Psychiatic Facility Partial Hospitalization/non-pharmacy
                    <br />9=ICF/MR
                    <br />10=Substance Abuse Facility/non-pharmacy
                    <br />11=Hospice
                    <br />12=Psychiatric Residential Treatment Facility/non-pharmacy
                    <br />13=Rehabilitation/non-pharmacy
                    <br />14=Homeless Shelter/non-pharmacy
                    <br />15=Correctional Institution </p>
                </React.Fragment>
        },
        facilityName: { title: 'Facility Name' },
        facilityID: { title: 'Facility ID' },
        promisetoPay: { title: 'Promise to Pay', type: 'money' },
        coPay: { title: 'CoPay', type: 'money' },
        totalRevenue: { title: 'Total Revenue', type: 'money' },
        ingredientCostPaid: { title: 'Ing.Cost Paid', type: 'money' },
        dispenseFeePaid: { title: 'Disp.Fee Paid', type: 'money' },
        totalReimbursed: {
            title: 'Total Reimbursed',
            type: 'money',
            showTotal: true,
            headerInfo:
                <React.Fragment>
                    <p>Equals Ingredient Cost Paid + Dispense Fee Paid unless zero. If zero, then equals Insurance Paid + Co-Pay </p>
                </React.Fragment>
        }
    },
}
