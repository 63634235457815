
export function setWithExpiry(key, value, ttl) {
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + ttl
    }

    localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
    const serializedItem = localStorage.getItem(key)

    if (!serializedItem) {
        return null
    }

    const item = JSON.parse(serializedItem)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}