import React from 'react';
import classNames from 'classnames'


import './OrganizationDropdownOption.css'
export default function OrganizationDropdownOption(props) {
    const { option, onClick, selected } = props
    const { value, label } = option

    let className = classNames('Organization-Dropdown-Option', { '--is-selected': selected})
    return (
        <li className={selected ? 'Organization-Dropdown-option--is-selected' :'Organization-Dropdown-option'} key={value} onClick={() => onClick(option)}>
            {label}
        </li>
    )
}