import React, { Component } from 'react';

export class LiveChat extends Component
{
    constructor(props){
        super(props);
        window.__lc = window.__lc || {};
        window.__lc.license = 14790003;
        const script = document.createElement('script');    
        script.src = "https://cdn.livechatinc.com/tracking.js";
        script.async = true;    
        document.body.appendChild(script);    
    }
    render() {
        return (<div></div>)
    }
}