import React from 'react';
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col } from "react-bootstrap";

import {
    Base64ToArrayBuffer,
    SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";

import { DownloadFileByBatchId, CreateNewRequestForExistingBatch, CheckBatchDateOlderThan90Days } from './BatchIdDownloadHelper';
import { DefaultColumnWidth, DefaultDateColumnWidth } from "../../grid/Kendo/KendoGridAndColumnConstants";

export function ExportBatchHistoryGrid(props) {
    const { data, user, getAccessToken, onFailure, setParentIsLoading, setErrorFromParent, getMainGridDataFromParent } = props;

    const batchIdKey = "batchId";
    let defaultColumnWidth = DefaultColumnWidth();

    const onBatchIdCellClick = (dataItem) => {
        checkDateAndBatchStatusAndRebuildOrDownloadBatch(dataItem, false);
    };

    const checkDateAndBatchStatusAndRebuildOrDownloadBatch = (dataItem, isBatchSummary = false) => {
        if (dataItem.status === "Downloaded" || dataItem.status === "Ready To Download" || dataItem.status === "Completed with Errors - Please Review") {

            if (CheckBatchDateOlderThan90Days(dataItem)) {
                let requestNewBuild = window.confirm("Batch does not exist after 90 days.  Do you want to rebuild selected Batch?");

                if (requestNewBuild) {
                    let batchId = dataItem[batchIdKey];
                    let accessToken = getAccessToken();
                    let parameters = {
                        user: user,
                        batchId: batchId
                    }

                    CreateNewRequestForExistingBatch(setParentIsLoading,
                        handleSuccessNewRequestForRebuildingBatch,
                        handleError,
                        parameters,
                        accessToken
                    );
                }
            }
            else {
                let batchId = dataItem[batchIdKey];
                let accessToken = getAccessToken();
                let parameters = {
                    user: user,
                    batchId: batchId,
                    isSummaryFile: isBatchSummary
                };

                DownloadFileByBatchId(setParentIsLoading,
                    handleSuccessGetFileByBatchId,
                    handleError,
                    parameters,
                    accessToken
                );
            }
        }
        else {
            alert("This batch request is still pending, the download is not ready yet.");
        }

    }

    const handleSuccessNewRequestForRebuildingBatch = (response) => {
        setParentIsLoading(false);
        setErrorFromParent({ status: response.status, Message: response.data.response });
        getMainGridDataFromParent();
    };

    const handleError = (error) => {
        onFailure(error);
    }

    const handleSuccessGetFileByBatchId = (response) => {
        setParentIsLoading(false);

        if (response.data) {
            var dataAsArray = Base64ToArrayBuffer(response.data.fileStream);
            SaveByteArray(
                response.data.fileName,
                dataAsArray
            );
        }
        else {
            alert("No file found for this batch, please contact Net-Rx support.");
        }

        getMainGridDataFromParent();
    }

    const onBatchSummaryCellClick = (dataItem) => {
        checkDateAndBatchStatusAndRebuildOrDownloadBatch(dataItem, true);
    };

    const BatchSummaryCell = (props, optionalValue = null) => {
        let datum = props.dataItem;
        let batchId = datum[batchIdKey];
        if (batchId) {
            return (
                <td>
                    <a onClick={() => onBatchSummaryCellClick(props.dataItem)}>
                    {(datum.status === "Downloaded" || datum.status === "Ready To Download" || datum.status === "Completed with Errors - Please Review") ? <i style={{ color: "green" }} className="fa fa-file-excel-o fa-lg"></i> : null}
                    </a>
                </td>
            );
        }
        else {
            return (
                <td>
                    {props.children}
                </td>
            );
        }

    };

    const BatchIdCell = (props) => {
        let batchId = props.dataItem[batchIdKey];

        if (batchId) {
            return (
                <td>
                    <a
                        onClick={() => onBatchIdCellClick(props.dataItem)}
                        style={{
                            color: "#015CAB",
                            cursor: "pointer",
                            textDecoration: "underline",
                        }}
                    >
                        {props.children}
                    </a>
                </td>
            );
        }
        else {
            return (
                <td>
                    {props.children}
                </td>
            );
        }

    };

    return (
        <React.Fragment>
            <Row>
                <Col className="tight-grid multi-line-filter">
                    <h4>Export Batch History Detail</h4>
                    <SharedMainGrid
                        data={data}
                        aggregateColumnSettings={null}
                        sendAggregatesParentCallback={() => { }}
                        dataItemKey={batchIdKey}
                        styleOverride={{ height: "400px" }}
                    >
                        <Column field="batchId" title="Batch ID" width={"175em"}
                            cells={{
                                data: BatchIdCell
                            }}
                        />
                        <Column field="batchSummary" title="Batch Summary" width={"175em"}
                            cells={{
                                data: BatchSummaryCell
                            }}
                        />
                        <Column field="batchRequestDate" title="Batch Request Date" width={DefaultDateColumnWidth()} />
                        <Column field="batchCreationDate" title="Batch Creation Date" width={DefaultDateColumnWidth()} />
                        <Column field="status" title="Status" width={defaultColumnWidth} />
                        <Column field="requestedByUser" title="Requested By" width={defaultColumnWidth} />
                        <Column field="downloadedByUser" title="Downloaded By" width={defaultColumnWidth} />
                    </SharedMainGrid>
                </Col>
            </Row>

        </React.Fragment>
    );
};