import React from 'react'
import './SelectableRowCell.css'

export default function SelectableRowCell(props) {
    const { show, leftOffset, selected, handleRowSelected } = props

    if (!show) return null

    const styles = leftOffset > 0 ? {
        transform: `translate(${leftOffset}px, 0px)`,
        position: "relative",
        zIndex: 1,
        backgroundColor: "#F5F5F5"
    } : null

    // Can't inline handleRowSelected. Causes infinite render
    function onSelectChange() {
        handleRowSelected()
    }
    return (
            <form className="selectable-row-cell" style={styles}>
                <input className="selectable-row-cell-input"
                    name="selected"
                    type="checkbox"
                    checked={selected}
                    onChange={onSelectChange}
                />
            </form>
    )
}