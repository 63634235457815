import React from "react";

import "./LoadingSmallControlSpinner.css";

export function LoadingSmallControlSpinner() {
  return (
    <div className="loadingSmallControlSpinnerContainer">
      <div className="loadingSmallControlSpinnerLoader"></div>
    </div>
  );
}
