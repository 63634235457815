import React from 'react';
import { TotalSalesByBin } from './TotalSalesByBin'
import { TotalSalesByPayer } from './TotalSalesByPayer'
import { TotalSalesByLocation } from './TotalSalesByLocation'

import { Col } from 'react-bootstrap';
import TabBasedFeature from '../../layout/TabBasedFeature';

export default function TotalSalesPaymentSummary(props) {
    let tabs = [
        { key: 'location', title: 'Location', Component: TotalSalesByLocation },
        { key: 'payer', title: 'Payer', Component: TotalSalesByPayer },
        { key: 'bin', title: 'BIN', Component: TotalSalesByBin },
    ]
    return (
        <TabBasedFeature tabs={tabs} defaultActiveKey="location" passedProps={props.passedProps} />
            );
};