import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export function DetailsModal(props) {
  const { show, title, children, handleClose, overrideClass } = props;

  return (
    // <Modal className="grid-details-modal"
    <Modal
      className={overrideClass ? overrideClass : "grid-details-modal"}
      show={show}
      onHide={handleClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header className="modal-header">
        <h1>{title}</h1>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}
