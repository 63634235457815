
let url = process.env.REACT_APP_SisenseURI + '/js/frame.js';

const loadScript = () => new Promise((resolve, reject) => {
    let ready = false;
    if (!document) {
        reject(new Error('Document was not defined'));
    }
    const tag = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;
    script.async = false;
    script.onreadystatechange = () => {
        if (!ready && (!document.readyState || document.readyState === 'complete')) {
            ready = true;
            resolve(script);
        }
    };
    script.onload = script.onreadystatechange;

    script.onerror = (msg) => {
        console.log(msg);
        reject(new Error('Error loading script.'));
    };

    script.onabort = (msg) => {
        console.log(msg);
        reject(new Error('Script loading aborted.'));
    };

    if (tag) {
        if (tag.parentNode != null) {
            tag.parentNode.insertBefore(script, tag);
        }
    }
});

const isLoaded = () => {
    let scripts = Array
            .from(document.querySelectorAll('script'))
            .map(scr => scr.src);
    return scripts.includes(url)
}

export { loadScript, isLoaded }