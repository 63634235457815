import React, { useState, useEffect } from "react";
import classNames from 'classnames'

import MultiSelectDropdownOption from './MultiSelectDropdownOption'
import MultiSelectDropdownFilter from './MultiSelectDropdownFilter'
import { MultiSelectDropdownAllOption } from "./MultiSelectDropdownAllOption";
import { usePrevious } from "../hooks/usePrevious";

export default function MultiSelectDropdownBody(props) {

    const { options, selectAllOption, onLoadAllSelected, filterable, maxAllowedSelections, onChange, show, externalDataReference, defaultSelected } = props
    const [isFiltered, setFiltered] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState({})
    const [numberSelectedOptions, setNumberSelectedOptions] = useState({})
    const [allSelected, setAllSelected] = useState(onLoadAllSelected)
    const [viewableOptions, setViewableOptions] = useState([]);

    const prevOptions = usePrevious(options)
    // setting all selected options values to false to start with when options prop changes.
    useEffect(() => {
        if (options.length !== Object.keys(selectedOptions).length) {
            let newSelectedOptions = selectedOptions;

            options.forEach(option => {
                newSelectedOptions[option.value] = onLoadAllSelected;
            })

            // setting any default values if any
            if (defaultSelected.length > 0) {
                defaultSelected.forEach(option => {
                    newSelectedOptions[option.value] = true
                })
            }

            onChange(Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]))
            setSelectedOptions(newSelectedOptions);
        }
    }, [options])

    useEffect(() => {
        if (defaultSelected != null) {
            if (defaultSelected.length > 0) {
                let newSelectedOptions = selectedOptions;

                options.forEach(option => {
                    newSelectedOptions[option.value] = onLoadAllSelected;
                })

                // setting any default values if any
                if (defaultSelected.length > 0) {
                    defaultSelected.forEach(option => {
                        handleOptionClick(option.value);
                        newSelectedOptions[option.value] = true
                    })
                }

                onChange(Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]))
                setSelectedOptions(newSelectedOptions);
             }
        }
    }, [defaultSelected])

    useEffect(() => {
        if (options && JSON.stringify(prevOptions) !== JSON.stringify(options)) {
            let newSelectedOptions = {}

            options.forEach(option => {
                newSelectedOptions[option.value] = onLoadAllSelected;
            })

            // setting any default values if any
            if (defaultSelected.length > 0) {
                defaultSelected.forEach(option => {
                    newSelectedOptions[option.value] = true
                })
            }

            onChange(Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]))
            setSelectedOptions(newSelectedOptions);
        }
    }, [JSON.stringify(options)])
    // handles when an option is clicked
    function handleOptionClick(value) {

        let newSelectedOptions = selectedOptions;
        newSelectedOptions[value] = !newSelectedOptions[value];

        let numberSelected = Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]).length;
        // setNumberSelectedOptions(Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]).length)
        setNumberSelectedOptions(numberSelected);

        if (Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]).length <= maxAllowedSelections) {

            setSelectedOptions(newSelectedOptions)

            // check if every option has been selected
            let isAllSelected = Object.keys(newSelectedOptions).every(key => newSelectedOptions[key])
            setAllSelected(isAllSelected)
            onChange(Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]))
        }
    }

    // handles when All option is selected
    function handleAllSelected() {
        let isSelected = !allSelected;

        let keys = Object.keys(selectedOptions)
        let newSelectedOptions = selectedOptions;
        keys.forEach(key => {
            newSelectedOptions[key] = isSelected
        })

        // console.log(options);
        // console.log(options.length);
        if (isSelected) {
            setNumberSelectedOptions(options.length);
        }
        else {
            setNumberSelectedOptions(0);
        }
        setSelectedOptions(newSelectedOptions);
        setAllSelected(isSelected);
        onChange(Object.keys(newSelectedOptions).filter(option => newSelectedOptions[option]));
    }

    function handleFilterChanged(filter) {
        if (filter === '') {
            setFiltered(false)
        } else {
            let newViewableOptions = options;

            newViewableOptions = newViewableOptions.filter(option => {
                return option.label.toLowerCase().includes(filter)
            });

            setViewableOptions(newViewableOptions)
            setFiltered(true)
        }
    }

    let optionsToUse = isFiltered ? viewableOptions : options
    let className = classNames('multi-select-dropdown-body', { 'hidden': !show });

    return (
        <div className={className}>
            <ul>
                <MultiSelectDropdownFilter show={filterable} handleFilterChanged={handleFilterChanged} />
                <MultiSelectDropdownAllOption show={selectAllOption} allSelected={allSelected} handleAllSelected={handleAllSelected} />
                {optionsToUse.map((option, index) => {
                    return (
                        <MultiSelectDropdownOption
                            selected={allSelected ? allSelected : selectedOptions[option.value]}
                            key={index}
                            label={option.label}
                            value={option.value}
                            handleOptionClick={handleOptionClick}
                            disabled={externalDataReference ? externalDataReference.indexOf(option.value.toString()) > -1 : false}
                            maxReached={numberSelectedOptions >= maxAllowedSelections}
                        />
                    )
                })}
            </ul>
        </div>
    )
}