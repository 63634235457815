const IgnoreOptions = [
    { value: 1, label: "WO - RTS: HAVE RX RECEIPT" },
    { value: 2, label: "RTS: CC OVER $75.00" },
    { value: 3, label: "RTS: UNDER $75.00" },
    { value: 4, label: "WO - DUPLICATE" },
    { value: 5, label: "PAID PREVIOUS RA" },
    { value: 6, label: "DROPPED" },
    { value: 7, label: "PDR or APR" },
    { value: 8, label: "DIFFERENCE /REBILL" },
    { value: 9, label: "AUDIT APPROVED/RECOUP" },
    { value: 10, label: "AUDIT NOT APPROVED" },
    { value: 11, label: "RDC or CNP" },
    { value: 12, label: "ADJ CLAIM BY PLAN" },
    { value: 13, label: "OVERCHARGE ON COPAY" },
    { value: 14, label: "RINGING ERROR" },
    { value: 15, label: "W/O LAST RA" },
    { value: 16, label: "OVER $75 CANCELED RX" },
    { value: 17, label: "WO - PAID ON OTHER PLAN/PRIMARY" },
    { value: 18, label: "PAID NEXT RA" },
    { value: 19, label: "WO - OTHER" },
    { value: 20, label: "EXP - FEES" },
    { value: 21, label: "WO - OCER/UNDER BILLING ISSUE" },
    { value: 22, label: "WO - AUDIT" },
    { value: 23, label: "WO - SYSTEM ERROR" },
    { value: 24, label: "EXP - TECHNICAL BILLING ISSUE" },
    { value: 25, label: "IGN - NOT SETUP FOR 835s" },
    { value: 26, label: "PAID ON PHYSICAL CHECK" },
    { value: 27, label: "IGN - OTHER" },
    { value: 28, label: "IGN - SYSTEM ERROR" },
    { value: 51, label: "MEDICAL BENEFITS" },
    { value: 52, label: "WRITE-OFF" },
    { value: 53, label: "COST-SHARE PAYMENTS" },
    { value: 54, label: "Forwarding Balance Ignore" },
    { value: 55, label: "100% Copay" },
    { value: 90, label: "PATIENT RESPONSIBILITY" },
    { value: 91, label: "VALID CLAIM NOT RECEIVED BY NET-RX" },
    { value: 92, label: "SALES TAX" },
    { value: 93, label: "LIC Adjustment" },
    { value: 94, label: "REVERSAL" },
    { value: 95, label: "PAID ON PAPER EOB" },
    { value: 98, label: "Manual Ignore" },
    { value: 99, label: "Overpaid Ignore" },
  ];

  export default IgnoreOptions;