import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";

import "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "./OktaWidgetStyleOverrides.css";

const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    //Get the scopes from copnfig, it should be a comma separated string
    let scopesFromConfig = process.env.REACT_APP_OKTAAuthenticationScopes;

    //convert the comma separated string to an array
    let scopes = scopesFromConfig.split(",").map((item) => item.trim());

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    var searchParams = new URL(window.location.href).searchParams;

    let signInConfig = {
      issuer: `${process.env.REACT_APP_OKTADomain}${"/oauth2/default"}`,
      clientId: process.env.REACT_APP_OKTAClientID,
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: scopes,
      otp: searchParams.get("otp"),
      state: searchParams.get("state"),
      // pkce: true
      // pkce: false, // Explicitly set pkce to false
    };

    // console.log(signInConfig);
    // console.log(searchParams);
    // console.log(searchParams.get("otp"));
    // console.log(searchParams.get("state"));
    
    const widget = new OktaSignIn(signInConfig);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    // console.log("widget config", widget);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
