
export const constants = {
    mainGridHeaderProps: {
      id: { title: "id", hidden: true },
      script: { title: "Script",},
      nCPDP: { title: "NCPDP",},
      dispensedDate: { title: "Dispensed Date", type: 'date' },
      adjustment: { title: "Adjustment", type: 'money', showTotal: true },
      paidAmount: { title: "Paid Amount", type: 'money', showTotal: true },
      outStandingAmount: { title: "OutStandingAmount", type: 'money', showTotal: true },
      transactionFee: { title: "Transaction Fee", type: 'money', showTotal: true },
    },
  };