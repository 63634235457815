import React, { useState, useEffect } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { GetExportFileNameDated } from "./ExportFileName";
import { FormatValue } from "../../helpers/DataFormatHelpers";

export default function KendoExcelExporterDirect(props) {
  const _dummyExport = React.useRef(null);
  const data = props.data;
  const columns = props.columns;
  const exportCompletedCallback = props.exportCompletedCallback;

  // console.log("KendoExcelExporterNewData", data);
  // console.log("KendoExcelExporterNewColumns", columns);

  useEffect(() => {
    //alert("KendoExcelExporterDirect");
    dataExport();
    exportCompletedCallback();
  }, []);

  const FormattedExportData = () => {
    let columnsToFormat = GetColumnsToFormatData();

    //if there are no columns to format, then return original dataset
    if (!columnsToFormat || columnsToFormat.length === 0) {
      return data;
    }

    let formattedData = data.map((item) => {
      columnsToFormat.forEach((column) => {
        let thisKey = column.field;

        const formattedValue = FormatValue(item[thisKey], column.format);

        item[thisKey] = formattedValue;
      });

      return item;
    });

    return formattedData;
  };

  //All logic on custom formatting is below, building an array of columns that need to be formatted and the format type tht needs to be applied

  const GetColumnsToFormatData = () => {
    let result = [];

    if (!columns || columns.length === 0) return result;

    columns.forEach((column) => {
      //if column.filter contains the word "date", then format it as a date
      //ignore case when searching for the word "date"
      if (column.field && column.field.toLowerCase().includes("date")) {
        result.push({ field: column.field, format: "date" });

        //go to next foreach iteration
        return;
      }
    });

    return result;
  };

  const dataExport = () => {
    //console.log("1");
    let formattedData = FormattedExportData();
    //console.log("2");

    //console.log(formattedData);
    //alert("Exporting data...");
    _dummyExport.current.save(formattedData, columns);
  };

  let exportFileName = props.exportFileNamePrefix
    ? props.exportFileNamePrefix
    : "NetRxExport";

  return (
    <React.Fragment>
      <ExcelExport
        ref={_dummyExport}
        fileName={GetExportFileNameDated(exportFileName, "xlsx")}
      ></ExcelExport>

      <div onClick={dataExport}>Excel</div>
    </React.Fragment>
  );
}
