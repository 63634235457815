import React, {useEffect, useState} from 'react'
import { Tab, Tabs } from 'react-bootstrap';

export default function TabBasedFeature(props) {
    const [key, setKey] = useState(props.defaultActiveKey);
    useEffect(() => {
        if (props.activeKey) {
            setKey(props.activeKey)
        }
    }, [props.activeKey])
    return (
        <div className="tab-feature-wrapper">
            <Tabs defaultActiveKey={props.defaultActiveKey} className="feature-tabs" onSelect={(k) => setKey(k)} activeKey={key}>
                {props.tabs.map(tab => {
                    return (
                        <Tab key={tab.key}
                            eventKey={tab.key}
                            title={tab.title}
                            disabled={tab.disabled && tab.disabled == 'true'}>
                            <tab.Component passedProps={props.passedProps} />
                        </Tab>)
                })}
            </Tabs>
        </div>

    )
}