import React, { Component } from "react";

import Row from "react-bootstrap/Row";

export class Footer extends Component {
  render() {
    return (
      <Row className="w-100 no-gutters" id="footer">
        <div className="col-sm-auto action-link-with-icon">
          <a
            href="https://www.mhainc.com/About_MHA/Ethics_Privacy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-external-link" />
            Terms & Conditions
          </a>
        </div>
        <div className="col-sm-auto action-link-with-icon">
          <a
            href="https://www.mhainc.com/About_MHA/PrivacyPolicy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-external-link" />
            Privacy Policy
          </a>
        </div>
        <div className="col-sm-auto mr-auto action-link-with-icon">
          <a
            href="https://www.net-rx.com/Contact_and_Support/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa fa-envelope-o" />
            Contact Us
          </a>
        </div>
        <div className="col-auto" id="copyright">
          Copyright &copy; 2023 Managed Health Care Associates, Inc. All rights
          reserved.
        </div>
      </Row>
    );
  }
}
