import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Col, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { SharedFormRow, SharedFormColumn } from "../../layout/SharedFormElements/SharedFormRowAndColumns";

import FormValidation from "../../form/validation/FormValidation";

export function ExportBatchHistoryForm(props) {
  const { handleFormSubmit } = props;

  //NOTE TODO: This is the original logic, but I'm reverting this for now for testing
  const [fromDate, setFromDate] = useState(getFromDate());
  const [toDate, setToDate] = useState(new Date());

  // const [fromDate, setFromDate] = useState(new Date(2022, 5, 15))
  // const [toDate, setToDate] = useState(new Date(2022, 6, 15))
  const [batchId, setBatchId] = useState("");
  const [batchStatus, setBatchStatus] = useState("all");

  function getFromDate() {
    let date = new Date();
    let currentMilliseconds = date.getUTCMilliseconds();
    //NOTE: Set it for 6 months ago
    date.setUTCMilliseconds(currentMilliseconds - 15780000000);
    return new Date(date);
  }

  function submitForm() {
    let parameters = {
      toDate: toDate,
      fromDate: fromDate,
      batchId: batchId,
      batchStatus: batchStatus,
      requiredFields: ["toDate", "fromDate"],
    };

    let validationIssueMessage = null;

    if(!(batchId && batchId.length > 0)){
      validationIssueMessage = FormValidation(parameters);
    }

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      handleFormSubmit(parameters);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitForm();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <SharedFormRow>
        <SharedFormColumn>
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Batch ID</Form.Label>
          <Form.Control
            name="batchId"
            type="text"
            placeholder=""
            onChange={(e) => {
              setBatchId(e.target.value);
            }}
            value={batchId}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Batch Status</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setBatchStatus(e.target.value);
            }}
          >
            <option value="all">All</option>
            <option value="readyToDownload">Ready to Download</option>
            <option value="downloaded">Downloaded</option>
            <option value="pendingBuildProcess">Pending Build Process</option>
            <option value="completedWithErrors">Completed With Errors - Please Review</option>
          </Form.Control>
        </SharedFormColumn>
      </SharedFormRow>
      <SharedFormRow>
        <SharedFormColumn>
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </SharedFormColumn>
      </SharedFormRow>
    </Form>
  );
}

ExportBatchHistoryForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
