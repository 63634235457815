import React, { useState, useEffect } from "react";
import classNames from 'classnames'

export default function SingleSelectDropdownOption(props) {
    const { label, value, disabled} = props;

    const [selected, setSelected] = useState(props.selected)
    let className = classNames('multi-select-dropdown-option', { 'selected': selected }, { 'disabled': disabled });

    // only runs when props.selected has changed
    useEffect(() => {
        if (props.selected !== selected) {
            setSelected(props.selected)
        }
    }, [props.selected])

    function handleOptionClick() {
        props.handleOptionClick(label,value);
        setSelected(true);
    }

    return (
        <li>
            <button type="button" className={className} onClick={disabled ? null : handleOptionClick}>
                <span className='multi-select-dropdown-option-label'>{label}</span>
            </button>
        </li>
    )
}