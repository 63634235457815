import React from 'react'

export const constants = {
    headerProps: {
        ncpdp: { title: 'NCPDP', fixedLeft: true },
        rx: { title: 'Rx #', fixedLeft: true },
        serviceDate: { title: 'Service Date', type: 'date', fixedLeft: true },
        planName: { title: 'Plan Name' },
        bin: { title: 'BIN' },
        pcn: { title: 'PCN' },
        groupID: { title: 'Group ID' },
        facilityName: { title: 'Facility Name' },
        facilityID: { title: 'Facility ID' },
        patientResidenceCode: {
            title: 'PRC',
            isCellAlert: function (row, column) {
                if (column === 'patientResidenceCode' && row[column] !== null && row['sccSent'] !== null) {
                    const substrings = [
                        '21', '22', '23', '24',
                        '25', '26', '27', '28',
                        '29', '30', '31', '32',
                        '33', '34', '35', '36'
                    ]
                    return !row[column].includes('03') && substrings.some(x => row['sccSent'].includes(x))
                }
            },
            headerInfo:
                <React.Fragment>
                    <p><b>Residence Code: Patient’s place of Residence.</b>
                        <br />0=Not Specified
                    <br />1=Home
                    <br />2=SNF Medicare B Use Only
                    <br />3=SNF
                    <br />4=ALF
                    <br />5=Custodial Care/Part B
                    <br />6=Group Home
                    <br />7=Inpatient Psychiatric Facility/non-pharmacy
                    <br />8=Psychiatric Facility Partial Hospitalization/non-pharmacy
                    <br />9=ICF/MR
                    <br />10=Substance Abuse Facility/non-pharmacy
                    <br />11=Hospice
                    <br />12=Psychiatric Residential Treatment Facility/non-pharmacy
                    <br />13=Rehabilitation/non-pharmacy
                    <br />14=Homeless Shelter/non-pharmacy
                    <br />15=Correctional Institution </p>
                </React.Fragment>
        },
        billedShortCycle: {
            title: 'Billed Short Cycle',
            isCellAlert: function (row, column) {
                if (column === 'billedShortCycle' && row['patientResidenceCode'] !== null) {
                    return row['patientResidenceCode'].includes('03')
                        && row[column] === 'Y'
                        && row['spiSent'] === null || row['spiSent'] === ''
                }
            }
        },
        sccSent: {
            title: 'SCC Sent',
            isCellAlert: function (row, column) {
                if (column === 'sccSent') {
                    return row[column].includes('21') || row[column].includes('36')
                }
            }
        },
        spiSent: {
            title: 'SPI Sent',
            headerInfo:
                <React.Fragment>
                    <p><b>Special Packaging Indicator to identify the type of dispensing (packaging)</b>
                        <br />1 = Not Unit Dose
                    <br />2 = Manufacturer Unit Dose
                    <br />3 = Pharmacy Unit Dose
                    <br />4 = Pharmacy Unit Dose Patient Compliance Packaging
                    <br />5 = Pharmacy Multi-Drug Patient Compliance Packaging
                    <br />6 = Remote Device Unit Dose
                    <br />7 = Remote Device Multi-Drug Compliance
                    <br />8 = Manufacturer Unit of Use Package (not unit dose) </p>
                </React.Fragment>
        },
        dispensedDrug: { title: 'Dispensed Drug' },
        ndc: { title: 'NDC' },
        bgIndicator: {
            title: 'B/G',
            headerInfo:
                <React.Fragment>
                    <p><b>B/G: Brand Generic Indicator</b>
                        <br />B=Brand (Single Source, no generic available)
                    <br />G=Generic
                    <br />BO=Brand Originator (Generic available)
                    <br />BG=Branded Generic
                    <br />MB=Multisource Brand
                    <br />MG=Generic for Multisource Brand
                    <br />OB=OTC Brand (Single Source, no generic available)
                    <br />OG=OTC Generic
                    <br />OBO=OTC Brand Originator (Generic available)
                    <br />OBG=OTC Branded Generic
                    <br />OMB=OTC Multisource Brand
                    <br />OMG=OTC Generic for Multisource Brand </p>
                </React.Fragment>
        },
        quantity: {
            title: 'Qty',
            type: 'number'
        },
        daysSupply: {
            title: 'Days Supply',
            type: 'number',
            isCellAlert: function (row, column) {
                if (column === "daysSupply") {
                    return row[column] > 14 && row['sccSent'].includes('34')
                        || row[column] > 7 && row['sccSent'].includes('22')
                        || row[column] < 14 && row['sccSent'].includes('21')
                }
            }
        },
        dispenseFeePaid: {
            title: 'Disp. Fee Paid',
            type: 'money',
            showTotal: true,
            isCellAlert: function (row, column) {
                if (column === 'dispenseFeePaid') {
                    return !row['isMedicaid'] && row[column] > 8
                }
            }
        },
        totalReimbursed: {
            title: 'Total Reimbursed',
            type: 'money',
            showTotal: true,
            headerInfo:
                <React.Fragment>
                    <p>Equals Ingredient Cost Paid + Dispense Fee Paid unless zero. If zero, then equals Insurance Paid + Co-Pay </p>
                </React.Fragment>
        },
        isMedicaid: { title: 'IsMedicaid', hidden: true }
    },
    featureInfo:
        <React.Fragment>
            <b>Billed Short Cycle</b>
            <p>
                Red text = Claim appears to be billed as if Short Cycled, however no Special Packaging Indicator was used.  SPI codes are required for Short Cycle billing. Please review claim details and ensure all required coding is populated accurately.
            </p>
            <b>Day Supply</b>
            <p>
                Red text = Claim day supply does not match SCC used.  Please review billing and coding to ensure accuracy. For assistance with Short Cycle rules please reach out to your Net-Rx analyst.
            </p>
            <b>Disp. Fee Paid</b>
            <p>
                Red text = Claim appears to have been paid a high dispense fee. To avoid possible take-backs, please review claim details and coding for accuracy.
            </p>
            <b>PRC</b>
            <p>Red text = Claim appears to be billed with Short Cycle coding for an unqualified facility. Please review PRC and SCC used in combination with Short Cycle Rules. If you need assistance please reach out to your Net-Rx analyst.
            </p>
            <b>SCC Sent</b>
            <p>Red text = SCC used is a high audit trigger, please review billing and coding to ensure accuracy. For assistance on Short Cycle rules please reach out to your Net-Rx analyst.
            </p>
        </React.Fragment>

}

