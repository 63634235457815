import React, { useState, useEffect } from "react";

import GridExport from "../../export/GridExport";

import { Grid } from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";

//NOTE: Here we're defining just the common features on the grid;
//the GridColumns are still defined on the individual tabs themselves
export default function SharedMainGrid(props) {
  const {
    data,
    aggregateColumnSettings,
    sendAggregatesParentCallback,
    dataItemKey,
    scrollType,
    styleOverride,
    exportFileNamePrefix,
  } = props;

  const INITIAL_MAIN_GRID_DATA_STATE = { skip: 0, take: 50 };

  const [mainGridDataState, setMainGridDataState] = useState(
    INITIAL_MAIN_GRID_DATA_STATE
  );
  const [mainGridResultState, setMainGridResultState] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const _grid = React.useRef();
  const _mainExport = React.useRef(null);

  const onMainGridDataStateChange = (event) => {
    setMainGridDataState(event.dataState);
  };

  //NOTE: This runs when the data gets updated (either on initial page load or new data fetch)
  useEffect(() => {
    setMainGridDataState(INITIAL_MAIN_GRID_DATA_STATE);
  }, [data]);

  //NOTE: When a filter is applied, this useEffect hook will kick off to update the aggregates/export/show grid
  useEffect(() => {
    let _data = process(data, mainGridDataState);
    showGridData(_data);

    let filterAndSortSettings = {};
    filterAndSortSettings.filter = mainGridDataState.filter;
    filterAndSortSettings.sort = mainGridDataState.sort;
    let _filterAndSortdata = process(data, filterAndSortSettings);

    exportOption(_filterAndSortdata.data);
    aggregateOption(_filterAndSortdata.data);
  }, [mainGridDataState]);

  function showGridData(_data) {
    setMainGridResultState(_data);
  }

  function exportOption(_data) {
    setExportData(_data);
    if (_grid.current && _grid.current.columns)
      setExportColumns(_grid.current.columns);
  }

  function aggregateOption(_data) {
    let aggregates = {};
    if (_data && aggregateColumnSettings) {
      aggregates = aggregateBy(_data, aggregateColumnSettings);
    }

    let returnValues = {};
    returnValues.data = aggregates;
    returnValues.filter = mainGridDataState.filter;

    sendAggregatesParentCallback(returnValues);
  }

  return (
    <ExcelExport ref={_mainExport}>
      <GridExport
        exportData={exportData}
        exportDataColumns={exportColumns}
        exportFileNamePrefix={exportFileNamePrefix}
      />
      <Grid
        style={styleOverride ? styleOverride : { height: "700px" }}
        ref={_grid}
        data={mainGridResultState}
        filterable={true}
        sortable={true}
        groupable={true}
        pageable={true}
        key={true}
        scrollable={
          scrollType == null || scrollType == undefined
            ? "scrollable"
            : scrollType
        }
        dataItemKey={dataItemKey}
        onDataStateChange={onMainGridDataStateChange}
        {...mainGridDataState}
      >
        {props.children}
      </Grid>
    </ExcelExport>
  );
}
