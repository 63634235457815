export const constants = {
    detailGridHeaderProps: {
        CLID : { title: 'CLID', hidden: true },
        NCPDP: { title: 'NCPDP', fixedLeft: true },
        Script: { title: 'Prescription', fixedLeft: true },
        DispensedDate: { title: 'Dispensed Date', fixedLeft: true, type: 'date' },
        TransactionDate: { title: 'Transaction Date', fixedLeft: true, type: 'date' },
        Payer: { title: 'Payer Name', fixedLeft: true },
        BIN: { title: 'BIN', fixedLeft: true },
        PCN: { title: 'PCN', fixedLeft: true },
        AdjudicatedAmount: { title: 'Promise to Pay', type: 'money', showTotal: true, },
        PaidAmount: { title: 'Remittance Paid', type: 'money', showTotal: true, },
        CoPay: { title: 'Co-Pay', type: 'money', showTotal: true, },
        NDC: { title: 'NDC' },
        Quantity: { title: 'Qty', type: 'number', showTotal: true, },
        DaysSupply: { title: 'Days Supply', type: 'number', showTotal: true, },
        AuthorizationID: { title: 'Authorization ID' },
        CardholderID: { title: 'Cardholder ID' },
        LastCheckNo: { title: 'Last Received Check #' },
        LastCheckDate: { title: 'Last Received Check Date', type: 'date' },
        LastCheckPaymentDate: { title: 'Last Received Check Payment Date', type: 'date' },
        ArchiveDate: { title: 'Archive Date', type: 'date' }
    }
}