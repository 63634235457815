import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "../../Accordion";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import AdminViewRestoreDeleted835Form from "./AdminViewRestoreDeleted835Form";
import { LogError } from "../../../../src/components/AppInsights/TelemetryService";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import SharedMainSelectableGrid, {
    AddGridButton,
} from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";
import 'font-awesome/css/font-awesome.min.css';
import { MoneyCell } from "../../grid/Kendo/CustomKendoGridCells";
import {
    DefaultColumnWidth, DefaultDateColumnWidth
} from "../../grid/Kendo/KendoGridAndColumnConstants";
import { GridColumn } from '@progress/kendo-react-grid';
import { ZIndexContext } from "@progress/kendo-react-common";
import 'font-awesome/css/font-awesome.min.css';

export default function AdminViewRestoreDeleted835(props) {
    const { passedProps } = props;
    const user = passedProps.user.email;
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [submittedFormData, setSubmittedFormData] = useState(false);
    const [mainGridData, setMainGridData] = useState([]);
    const [error, setError] = useState({});
    let requestDataKey = "id";

    useEffect(() => {
        if (submittedFormData) {
            getDeletedChecks();
        }
    }, [submittedFormData]);

    function getDeletedChecks() {
        let accessToken = passedProps.auth.getAccessToken();
        setIsLoading(true);
        try {
            let response = axios
                .get(`api/RemittanceLookup/GetAdmin835DeletedChecks`, {
                    params: {
                        user: user,
                        payerId: submittedFormData.selectedPayers,
                        parentCompantyID: submittedFormData.selectedParentCompany,
                        checkNumber: submittedFormData.checkNumber,
                        startDate: submittedFormData.startDate,
                        endDate: submittedFormData.endDate,
                        fileName: submittedFormData.fileName,
                    },
                    headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then(onSuccess)
                .catch(onFailure);
        } catch (exception) {
            onFailure(exception);
        }
    }

    function onSuccess(response) {
        switch (response.config.url) {
            case "api/RemittanceLookup/GetAdmin835DeletedChecks":
                presentGridData(response.data);
                setIsLoading(false);
                break;
            case "api/RemittanceLookup/AdminRestoreChecks":
                setIsLoading(false);
                if(response.data == 'This request is already in the Queue.'){
                    alert(response.data);
                    break;
                }    
                alert('Your Request is Sent to the Queue Successfully.You will get an email once its Restored.');
                getDeletedChecks();
               
                break;
            default:
                break;
        }
    }

    function onFailure(error) {
        LogError(error);

        switch (error.response.config.url || error.config.url) {
            case "api/RemittanceLookup/GetAdmin835DeletedChecks":
                setError(error.response);
                setMainGridData([]);
                break;
            case "api/RemittanceLookup/AdminRestoreChecks":                            
                    setError(error.response);                   
                    break;
        }
        setIsLoading(false);
        return;
    }

    function presentGridData(data) {        
        let dataWithCleanedDates = data.map((t) => {
            return {
                ...t,
                selectedAction: false,
                checkDate: IsNullOrWhitespace(t.checkDate)
                    ? ""
                    : new Date(Date.parse(t.checkDate)),
                loadDate: IsNullOrWhitespace(t.loadDate)
                    ? ""
                    : new Date(Date.parse(t.loadDate)),
                deletedDate: IsNullOrWhitespace(t.deletedDate)
                    ? ""
                    : new Date(Date.parse(t.deletedDate)),
            };
        });
        setMainGridData(dataWithCleanedDates);
    }

    const sendSelectablesToParent = async (selectablesFromSharedGrid) => {
        //setMainGridSelectedState(selectablesFromSharedGrid);
        var getOnlyTrueKeys = selectablesFromSharedGrid;
        if (getOnlyTrueKeys.length < 1) {
            alert("No rows selected.");
            return null;
        }
        var allSelectedId = [];
        getOnlyTrueKeys.forEach(function (key) {
            allSelectedId.push(parseInt(key));
        });

        var selectedChecks = mainGridData.filter(item => allSelectedId.includes(item.id));
        var selectedChecksWithPendingBatch = selectedChecks.map((item) => {
            return {
                id: item.id,
                fileName: item.fileName,
                pBID: item.pbid,
                checkNo: item.checkNumber,
                checkDate: item.checkDate,
                checkAmt: item.checkAmount,
                fileName: item.fileName,
            };
        });
        if (selectedChecksWithPendingBatch.length > 0) {
            setIsLoading(true);
            let accessToken = passedProps.auth.getAccessToken();
            axios.post("api/RemittanceLookup/AdminRestoreChecks", {
                user: user,
                rpid : submittedFormData.selectedParentCompany,
                CheckedItems: JSON.stringify(selectedChecksWithPendingBatch)
            }, {
                headers: { Authorization: `Bearer ${accessToken}` },
            })
                .then(onSuccess)
                .catch(onFailure);
        }

    };

    function GetGridButtons() {
        let buttonsArray = [];

        buttonsArray = AddGridButton(
            buttonsArray,
            "Restore Selected",
            sendSelectablesToParent
        );

        return buttonsArray;
    }
    const gridButtons = GetGridButtons();

    const sendAccordionParent = (props) => {
        setExpanded(props);
    };

    function handleFormSubmit(formInput) {
        setSubmittedFormData(formInput);
    }

    function handleFormReset() {
        setSubmittedFormData(null);
        setMainGridData([]);
    }
    return (
        <React.Fragment>
            <div className="feature">
                <LoadingSpinner
                    isDataLoading={isLoading}
                    controlsName={"AdminViewRestoreDeleted835"}
                />
                <Accordion
                    defaultExpanded
                    label=""
                    sendAccordionParentCallback={sendAccordionParent}
                >
                    <Row>
                        <Col className="higher-zindex-filters">
                                <AdminViewRestoreDeleted835Form
                                    passedProps={passedProps}
                                    handleFormSubmit={handleFormSubmit}
                                    handleFormReset={handleFormReset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="tight-grid multi-line-filter">
                            <SharedMainSelectableGrid
                                data={mainGridData}
                                aggregateColumnSettings={null}
                                sendAggregatesParentCallback={() => { }}
                                dataItemKey={requestDataKey}
                                styleOverride={{ height: "600px" }}
                                gridButtons={gridButtons}>                    
                                <GridColumn field="pbid" title="PBID" filter="text" width={DefaultColumnWidth()} />
                                <GridColumn field="checkNumber" title="Check Number" filter="text" width={DefaultColumnWidth()} />
                                <GridColumn field="checkDate" title="Check Date" format="{0:d}" filter="date" width={DefaultDateColumnWidth()} />
                                <GridColumn field="checkAmount" title="Check Amount" filter="numeric" 
                                cells={{
                                    data: MoneyCell,
                                }} width={DefaultColumnWidth()} />
                                <GridColumn field="fileName" title="File Name" filter="text" width={DefaultColumnWidth()} />                               
                                <GridColumn field="loadDate" title="Upload Date" format="{0:d}" filter="date" width={DefaultDateColumnWidth()} />
                                <GridColumn field="deletedBy" title="Deleted By" filter="text" width={DefaultColumnWidth()} />
                                <GridColumn field="deletedDate" title="Deleted On" format="{0:d}" filter="date" width={DefaultDateColumnWidth()} />
                               
                            </SharedMainSelectableGrid>
                        </Col>
                    </Row>
                </Accordion>
            </div>
        </React.Fragment>
    );
}
