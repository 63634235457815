import React, { useState, useEffect, useRef, useCallback } from 'react';

import HeaderRow from './HeaderRow'
import InlineFilterRow from './InlineFilterRow'

export default function DataTableHead(props) {
    const {
        filterable,
        tableXOffset,
        headerColumnData,
        filterColumnData,
        handleColumnSort,
        handleFilterChange,
        selectableRows,
        allRowsSelected,
        handleAllRowsSelected
    } = props
    return (
        <div className="data-table-head">
            <HeaderRow
                scrollOffset={tableXOffset}
                columns={headerColumnData}
                handleColumnSort={handleColumnSort}
                selectableRows={selectableRows}
                allRowsSelected={allRowsSelected}
                handleAllRowsSelected={handleAllRowsSelected}
            />
            <InlineFilterRow
                scrollOffset={tableXOffset}
                columns={filterColumnData}
                show={filterable}
                handleFilterChange={handleFilterChange}
                selectableRows={selectableRows}
            />
        </div>
    )
}