export const constants = {
    mainGridHeaderProps: {
        pmid:           { title: 'Pmid', hidden: true },
        payer:          { title: 'Payer Name' },
        checkNumber:    { title: 'Check #' },
        checkDate:      { title: 'Check Date', type: 'date' },
        remittanceDate: { title: 'Remit Date', type: 'date' },
        paymentDate:    { title: 'Payment Date', type: 'date', editable: true },
        ncpdp:          { title: 'NCPDP' },
        paid:           { title: 'Paid', type: 'money', showTotal: true },
        adjusted:       { title: 'Adjusted', type: 'money', showTotal: true },
        checkAmount:    { title: 'Check Amount', type: 'money', showTotal: true },
        archive:        { title: 'Archive', hidden: true }
    },
    mainGridAPI:        { URL: 'api/RemittanceReceiptVerification/Get' },
    mainGridDetailHeaderProps: {
        payer:          { title: 'Payer Name' },
        location:       { title: 'Location (NCPDP)' },
        checkNumber:    { title: 'Check #' },
        checkDate:      { title: 'Check Date', type: 'date' },
        remittanceDate: { title: 'Remit Date', type: 'date' },
        paymentDate:    { title: 'Payment Date', type: 'date' },
        paid:           { title: 'Paid', type: 'money', showTotal: true },
        adjusted:       { title: 'Adjusted', type: 'money', showTotal: true },
        checkAmount:    { title: 'Check Amount', type: 'money', showTotal: true }
    },
    mainGridDetailAPI:  { URL: 'api/RemittanceReceiptVerification/GetCheckDetails' },
    mainGridExportHeaderProps: {
        payer:          { title: 'Payer Name' },
        checkNumber:    { title: 'Check #' },
        checkDate:      { title: 'Check Date', type: 'date' },
        remittanceDate: { title: 'Remit Date', type: 'date' },
        paymentDate:    { title: 'Payment Date', type: 'date' },
        location:       { title: 'NCPDP' },
        paid:           { title: 'Paid', type: 'money', showTotal: true },
        adjusted:       { title: 'Adjusted', type: 'money', showTotal: true },
        checkAmount:    { title: 'Check Amount', type: 'money', showTotal: true }
    },
    archiveHistoryHeaderProps: {
        pmid:           { title: 'pmid', hidden: true },
        checkNumber:    { title: 'Check #' },
        checkDate:      { title: 'Check Date', type: 'date' },
        checkAmount:    { title: 'Check Amount', type: 'money' },
        archived:       { title: 'Activity' },
        addUser:        { title: 'User' },
        addDate:        { title: 'Date', type: 'date' }
    },
    archiveHistoryAPI:  { URL: 'api/RemittanceReceiptVerification/GetArchiveHistoryDetails' }
};