export const constants = {
    headerProps:
    {
        pbid: {title: 'PBID', hidden: true},
        payer: { title: 'Payer' },
        claimCount: { title: 'Rx Count', type: 'number', showTotal: true, },
        totalDifferenceAmount: { title: 'Total Difference Amount', type: 'money', showTotal: true, },
    },

    detailHeaderProps: {
        clid: { title: 'CLID',hidden: true},
        ncpdp: { title: 'NCPDP', fixedLeft: true },
        rxNumber: { title: 'Rx Number', fixedLeft: true },
        dispensedDate: { title: 'Dispensed Date', fixedLeft: true, type: 'date', fixedLeft: true  },
        transactionDate: { title: 'Transaction Date', type: 'date', fixedLeft: true  },
        patient: { title: 'Patient' },
        payer: { title: 'Payer' },
        pbid: {title: 'Pbid', hidden: true},
        claimNumber: { title: 'Claim #' },
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date' },
        adjudicatedCopay: { title: 'Adjudicated Copay', type: 'money', showTotal: true},
        paidCopay: { title: 'Paid Copay', type: 'money'},
        adjudicated: { title: 'Adjudicated', type: 'money', showTotal: true },
        paid: { title: 'Paid', type: 'money', showTotal: true },
        difference: { title: 'Difference', type: 'money', showTotal: true }
    },
    detailExportProps: {
        ncpdp: { title: 'NCPDP' },
        clid: { title: 'clid', hidden: true },
        rxNumber: { title: 'Rx#' },
        dispensedDate: { title: 'Dispensed Date', type: 'date' },
        transactionDate: { title: 'Transaction Date', type: 'date' },
        bin: { title: 'BIN' },
        patient: { title: 'Patient' },
        payer: { title: 'Payer' },
        claimNumber: { title: 'Claim Number' },
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date' },
        checkArchiveDate: { title: 'Check Archive Date' },
        payType: { title: 'Pay Type' },
        adjudicatedCopay: { title: 'Adjudicated Copay', type: 'money' },
        paidCopay: { title: 'Paid Copay', type: 'money' },
        adjudicated: { title: 'Adjudicated', type: 'money' },
        paid: { title: 'Paid' },
        difference: { title: 'Difference', type: 'money' }
    },
    detailExportDetailsProps: {
        ncpdp: { title: 'NCPDP' },
        clid: { title: 'clid', hidden: true },
        rxNumber: { title: 'Rx#' },
        dispensedDate: { title: 'Dispensed Date', type: 'date' },
        transactionDate: { title: 'Transaction Date', type: 'date' },
        bin: { title: 'BIN' },
        patient: { title: 'Patient' },
        payer: { title: 'Payer' },
        claimNumber: { title: 'Claim Number' },
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date', type: 'date' },
        checkArchiveDate: { title: 'Check Archive Date', type: 'date' },
        payType: { title: 'Pay Type', type: 'number' },
        adjudicatedCopay: { title: 'Adjudicated Copay', type: 'money' },
        paidCopay: { title: 'Paid Copay', type: 'money' },
        adjudicated: { title: 'Adjudicated', type: 'money' },
        paid: { title: 'Paid', type: 'money' },
        difference: { title: 'Difference', type: 'money' }
    },
        IgnoreOptions : [
        { value: 1, label: 'WO - RTS: HAVE RX RECEIPT' },
        { value: 2, label: 'RTS: CC OVER $75.00' },
        { value: 3, label: 'RTS: UNDER $75.00' },
        { value: 4, label: 'WO - DUPLICATE' },
        { value: 5, label: 'PAID PREVIOUS RA' },
        { value: 6, label: 'DROPPED' },
        { value: 7, label: 'PDR or APR' },
        { value: 8, label: 'DIFFERENCE /REBILL' },
        { value: 9, label: 'AUDIT APPROVED/RECOUP' },
        { value: 10, label: 'AUDIT NOT APPROVED' },
        { value: 11, label: 'RDC or CNP' },
        { value: 12, label: 'ADJ CLAIM BY PLAN' },
        { value: 13, label: 'OVERCHARGE ON COPAY' },
        { value: 14, label: 'RINGING ERROR' },
        { value: 15, label: 'W/O LAST RA' },
        { value: 16, label: 'OVER $75 CANCELED RX' },
        { value: 17, label: 'WO - PAID ON OTHER PLAN/PRIMARY' },
        { value: 18, label: 'PAID NEXT RA' },
        { value: 19, label: 'WO - OTHER' },
        { value: 20, label: 'EXP - FEES' },
        { value: 21, label: 'WO - OCER/UNDER BILLING ISSUE' },
        { value: 22, label: 'WO - AUDIT' },
        { value: 23, label: 'WO - SYSTEM ERROR' },
        { value: 24, label: 'EXP - TECHNICAL BILLING ISSUE' },
        { value: 25, label: 'IGN - NOT SETUP FOR 835s' },
        { value: 26, label: 'PAID ON PHYSICAL CHECK' },
        { value: 27, label: 'IGN - OTHER' },
        { value: 28, label: 'IGN - SYSTEM ERROR' },
        { value: 51, label: 'MEDICAL BENEFITS' },
        { value: 52, label: 'WRITE-OFF' },
        { value: 53, label: 'COST-SHARE PAYMENTS' },
        { value: 54, label: 'Forwarding Balance Ignore' },
        { value: 55, label: '100% Copay' },
        { value: 90, label: 'PATIENT RESPONSIBILITY' },
        { value: 91, label: 'VALID CLAIM NOT RECEIVED BY NET-RX' },
        { value: 92, label: 'SALES TAX' },
        { value: 93, label: 'LIC Adjustment' },
        { value: 94, label: 'REVERSAL' },
        { value: 95, label: 'PAID ON PAPER EOB' },
        { value: 98, label: 'Manual Ignore' },
        { value: 99, label: 'Overpaid Ignore' },
    ],
    scriptDetailheaderProps: {
        bin: { title: 'BIN' }, 
        pcn: { title: 'PCN' }, 
        ndc: { title: 'NDC' }, 
        followUps: { title: 'FollowUps' }, 
        authCode: { title: 'AuthCode' }, 
        cardholderID: { title: 'Cardholder ID' }, 
        quantity: { title: 'AuthCode' }, 
        reconciled: { title: 'Reconciled' }, 
    },
    checkDetailheaderProps: {
        pmid: { title: 'PMID', hidden:true },
        payer: { title: 'Payer' },
        checkNumber: { title: 'Check Number' },
        paid: { title: 'Paid' },
        paymentType: { title: 'Payment Type' }
    },
    mainGridCSVheaders :[
        { label: "Payer", key: "payer" },
        { label: "Rx Count", key: "claimCount" },
        {label: "Total Difference Amount", key: "totalDifferenceAmount" }
      ],
      detailGridCSVheaders :[
        { label: "NCPDP", key: "ncpdp" },
        { label: "Rx#", key: "rxNumber" },
        { label: "Dispensed Date", key: "dispensedDate" },
        { label: "Transaction Date", key: "transactionDate" },
        { label: "BIN", key: "bin" },
        { label: "Patient", key: "patient" },
        { label: "Payer", key: "payer" },
        { label: "Claim Number", key: "claimNumber" },
        { label: "Check Number", key: "checkNumber" },
        { label: "Check Date", key: "checkDate" },
        { label: "Check Archive Date", key: "checkArchiveDate" },
        { label: "Pay Type", key: "payType" },
        { label: "Adjudicated Copay", key: "adjudicatedCopay" },
        { label: "Paid Copay", key: "paidCopay" },
        { label: "Adjudicated", key: "adjudicated" },
        { label: "Paid", key: "paid" },
        { label: "Difference", key: "difference" }
      ]

}