import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import './TotalFooter.css'
export default function TotalFooter(props) {
    const { show, columns, rows, scrollOffset, selectableRows } = props

    if (!show || columns.length === 0 || rows.length === 0) return null

    let numberOfLeftFixedColumns = columns.filter(x => x.fixedLeft).length
    return (
        <div className='total-footer'>
            <div className="total-footer-row">
                <TotalFooterSelectableRowCell scrollOffset={scrollOffset} selectableRows={selectableRows}/>
                {columns.map((column, index) => {
                    if (column.hidden) {
                        return null;
                    }
                    const styles = (index < numberOfLeftFixedColumns && scrollOffset > 0) ? {
                        transform: `translate(${scrollOffset}px, 0px)`,
                        position: "relative",
                        zIndex: 1,
                        backgroundColor: "#034681"
                    } : null

                    if (index === 0) {
                        return (
                            <div style={styles} className='total-footer-cell grand-total-footer-text' key={index}>Grand Total</div>
                        )
                    } else if (column.showTotal) {

                        let sum = rows.reduce((a, b) => a + b[column.accessor], 0)
                        let isNegative = sum < 0

                        if (column.type === 'money') {
                            let stringProps = {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }
                            let value = sum.toLocaleString(undefined, stringProps)

                            let whitespace = '';

                            if (value.length < 12) {
                                whitespace = '\xa0'.repeat(12 - value.length)
                            }

                            let className = classNames(
                                { 'negative': isNegative }) 
                            return (
                                <div style={styles} key={index} className='total-footer-cell'>
                                    <pre>
                                        {whitespace}
                                    </pre>
                                    <span className={className}>
                                        {value}
                                    </span>
                                </div>
                            )
                        } else {
                            let value = sum.toLocaleString();

                            let className = classNames('total-footer-cell', { 'negative': isNegative }) 

                            let whitespace = '';

                            if (value.length < 12) {
                                whitespace = '\xa0'.repeat(12 - value.length)
                            }

                            return (
                                <div style={styles} key={index} className={className}>
                                    <pre>
                                        {whitespace}
                                    </pre>
                                    {value}
                                </div>
                            )
                        }    
                    } else {
                        return (
                            <div className="total-footer-cell" style={styles} key={index} />
                        )
                    }
                })}
            </div>
        </div>
    )
}

const columnExact = {
    accessor: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool','checkbox', 'date', 'custom']).isRequired,
    fixedLeft: PropTypes.bool,
    showTotal: PropTypes.bool.isRequired,
    hidden: PropTypes.bool
}

TotalFooter.propTypes = {
    show: PropTypes.bool.isRequired,
    columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    scrollOffset: PropTypes.number,
}

function TotalFooterSelectableRowCell(props) {
    const { selectableRows, scrollOffset } = props

    if (!selectableRows) return null;

    const styles = scrollOffset > 0 ? {
        transform: `translate(${scrollOffset}px, 0px)`,
        position: "relative",
        zIndex: 1,
        backgroundColor: "#034681"
    } : null

    return (
        <div className="total-footer-cell" style={styles} />
    )
}