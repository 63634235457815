export const constants = {
    headerProps:
    {
        contactID: {title: 'ContactID', hidden: true},
        contactEmail: {title: 'Contact Email'},
        firstName: { title: 'First Name' },
        lastName: { title: 'Last Name' },
        contactType: {title: 'Contact Type'},
        rec: { title: 'Rec 2.0',type: 'custom' },
        metric: { title: 'Metric Essentials',type: 'custom'},
        metricPremium: { title: 'Metric Elite',type: 'custom'},
    }
}