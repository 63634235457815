import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-bootstrap'
import { AccordionSection } from './AccordionSection';

export class Accordion extends Component {
    // static propTypes = {
    //     children: PropTypes.instanceOf(Object).isRequired,
    // };

    constructor(props) {
        super(props);
        this.state = {
            openSections:
            {
                [this.props.selected]: true
            },
            hover: true,
        };

        //NOTE: Binding "this" keyword within the following 3 functions;
        //Otherwise, "this" comes through as being "undefined" within those 3 functions
        this.hoverOn = this.hoverOn.bind(this);
        this.hoverOff = this.hoverOff.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    hoverOn() {
        this.setState({ hover: true });
    };

    onToggle() {
        this.setState({ hover: !this.state.hover });
    };
    
    hoverOff() {
        this.setState({ hover: false });
    };

    onClick(label) {
        const {
            state: { openSections },
        } = this;

        const isOpen = !!openSections[label];
        this.setState({
            openSections: {
                [label]: !isOpen
            }
        });
    };

    render() {
        const {
            onClick,
            props: { children },
            state: { openSections },
        } = this;

        return (
            <Col className="col-md-auto accordion-wrapper">
                <span className={this.state.hover ? "accordion-pin-toggle-expanded" : "accordion-pin-toggle-collapsed"} onClick={this.onToggle} >
                    <i className={this.state.hover ? "fa fa-angle-left accordion-pin-toggle-icon" : "fa fa-angle-right accordion-pin-toggle-icon"}></i>
                </span>
                <div id="accordion" className={this.state.hover ? "accordion-expanded" : "accordion-collapsed"}>
                    {children.map(child => 
                    child ? 
                    (
                        <AccordionSection
                            parentHover={this.state.hover}
                            key={child.props.label}
                            isOpen={!!openSections[child.props.label]}
                            icon={child.props.icon}
                            label={child.props.label}
                            onClick={onClick}
                        >
                            {child.props.children}
                        </AccordionSection>
                    ) : null
                )}
                </div>
            </Col>
        );
    }
}
