import DatePicker from "react-datepicker";
import React, { useContext, useState, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";

import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "../../context/UserContext";

export default function FeeAndAdjustmentResearchForm(props) {
  //NOTE: These are the original date values
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  //NOTE: These are just test values
  // const [toDate, setToDate] = useState(new Date(2021, 1, 1));
  // const [fromDate, setFromDate] = useState(new Date(2021, 0, 1));

  const [selectedPayers, setSelectedPayers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedAdjustmentType, setSelectedAdjustmentType] = useState("all");
  const [selectedRemitStatus, setSelectedRemitStatus] = useState("all");
  const [inputCheckNumber, setInputCheckNumber] = useState();
  const [inputFeeCode, setInputFeeCode] = useState();

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations, selectedPayers, loaded]);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);

  function handleFormSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  function submitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      checkNumber: inputCheckNumber,
      selectedLocations: selectedLocations,
      selectedPayers: selectedPayers,
      //NOTE: The "noSpecialCharacters" property was removed here because the logic is no longer needed
      //It was not part of the requirements of the ticket, but it's related
      requiredFields: ["checkNumberOrDateRange"],
    };
    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        fromDate: fromDate,
        toDate: toDate,
        payers: selectedPayers.join(","),
        locations: selectedLocations.join(","),
        adjustmentType: selectedAdjustmentType,
        remitStatus: selectedRemitStatus,
        checkNumber: inputCheckNumber,
        feeCode: inputFeeCode,
      });
    }
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            minDate={fromDate}
            maxDate={new Date()}
            onChange={(date) => setToDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Form.Group>

        <Form.Group as={Col} md="auto">
          <Form.Label>Adjustment Type</Form.Label>
          <div key="adjustmentType" className="mb-3 mr-2">
            <Form.Check
              custom={"true"}
              type="radio"
              id={`adjustmentType{props._uid}-1`}
              name="btnAdjustmentType"
              onChange={(event) =>
                setSelectedAdjustmentType(event.target.value)
              }
              label="All"
              value="all"
              defaultChecked
            />
            <Form.Check
              custom={"true"}
              type="radio"
              id={`adjustmentType{props._uid}-2`}
              name="btnAdjustmentType"
              onChange={(event) =>
                setSelectedAdjustmentType(event.target.value)
              }
              label="Claim Level"
              value="claimLevel"
            />
            <Form.Check
              custom={"true"}
              type="radio"
              id={`adjustmentType{props._uid}-3`}
              name="btnAdjustmentType"
              onChange={(event) =>
                setSelectedAdjustmentType(event.target.value)
              }
              label="Check Level"
              value="checkLevel"
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label>Remit Status</Form.Label>
          <div key="remitStatus" className="mb-3 mr-2">
            <Form.Check
              custom={"true"}
              type="radio"
              id={`remitStatus{props._uid}-1`}
              name="btnRemitStatus"
              onChange={(event) => setSelectedRemitStatus(event.target.value)}
              label="All"
              value="all"
              defaultChecked
            />
            <Form.Check
              custom={"true"}
              type="radio"
              id={`remitStatus{props._uid}-2`}
              name="btnRemitStatus"
              onChange={(event) => setSelectedRemitStatus(event.target.value)}
              label="Pending"
              value="pending"
            />
            <Form.Check
              custom={"true"}
              type="radio"
              id={`remitStatus{props._uid}-3`}
              name="btnRemitStatus"
              onChange={(event) => setSelectedRemitStatus(event.target.value)}
              label="Archived "
              value="archived"
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label>Check Number</Form.Label>
          <div key="txtCheckNumber" className="mb-3 mr-2">
            <Form.Control
              type="text"
              maxLength="60"
              placeholder="Optional"
              onChange={(e) => {
                setInputCheckNumber(e.target.value);
              }}
              value={inputCheckNumber}
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label>Fee Code or Description</Form.Label>
          <div key="txtFeeCode" className="mb-3 mr-2">
            <Form.Control
              type="text"
              placeholder="Optional"
              onChange={(e) => setInputFeeCode(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Form.Group>
      </Row>
    </Form>
  );
}
