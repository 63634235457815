import React, { useState, useRef } from 'react';
import { Popover, Overlay } from 'react-bootstrap'

import OrganizationDropdownOption from './OrganizationDropdownOption'
import OrganizationDropdownFilter from './OrganizationDropdownFilter'
import './OrganizationDropdown.css'

export default function OrganizationDropdown(props) {
    const { onChange, organizations, children, selectedOrganization } = props

    const [show, setShow] = useState(false);
    const target = useRef(null);

    const [isFiltered, setIsFiltered] = useState(false)
    const [viewableOptions, setViewableOptions] = useState([]);
    const [filter, setFilter] = useState('');

    function onOrganizationChange(organization) {
        setShow(false)
        onChange(organization)
    }

    function handleFilterChange(filter) {
        if (filter === '') {
            setIsFiltered(false)
        } else {
            let options = organizations;
            options = options.filter(option => {
                return option.label.toLowerCase().includes(filter)
            });

            setIsFiltered(true)
            setViewableOptions(options)
        }
        setFilter(filter)
    }

    let optionsToUse = isFiltered ? viewableOptions : organizations

    function getDropdownEntries() {
        return optionsToUse.map((organization) => {
            return (
                <OrganizationDropdownOption
                    key={organization.value}
                    option={organization}
                    onClick={onOrganizationChange}
                    selected={selectedOrganization.value === organization.value}
                />
            )
        })
    }

    const dropdownEntries = getDropdownEntries()
    // const popover = (
    //     <Popover>
    //         <Popover.Content>
    //             <ul className="Organization-Dropdown--body">
    //                 <OrganizationDropdownFilter onFilterChange={handleFilterChange} filter={filter}/>
    //                 {dropdownEntries}
    //             </ul>
    //         </Popover.Content>
    //     </Popover>
    // )

    const popover = (
        <Popover>
            <ul className="Organization-Dropdown--body">
                <OrganizationDropdownFilter onFilterChange={handleFilterChange} filter={filter} />
                {dropdownEntries}
            </ul>
        </Popover>
    )

    //     return (
    //         <React.Fragment>
    //             <span onClick={() => setShow(!show)} ref={target}>
    //                 {children}
    //             </span>
    //             <Overlay target={target.current} show={show} placement="bottom" rootClose onHide={() => setShow(!show)}>
    //                 {popover}
    //             </Overlay>
    //         </React.Fragment>
    //     )
    // }
    return (
        <React.Fragment>
            <span onClick={() => setShow(!show)} ref={target}>
                {children}
            </span>
            <Overlay target={target.current} show={show} placement="bottom" rootClose onHide={() => setShow(!show)}>
                {popover}
            </Overlay>
        </React.Fragment>
    )
}
