import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { Form, Button, Col, Row } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelectDropdown from "../../MultiSelectDropdown";
import SingleSelectDropdown from "../../SingleSelectDropdown";
import QueryBuilder from "../../form/QueryBuilder/QueryBuilder.js";
import { UserContext } from "../../context/UserContext";
import { constants } from "./LocationComparisonConstants";

export function LocationComparisonForm(props) {
  //NOTE: Here, we're setting the default values
  const [fromDate, setFromDate] = useState(getFromDate());
  const [toDate, setToDate] = useState(new Date());
  //NOTE: These are all of the possible location options locationOptions
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedComparisonLocations, setSelectedComparisonLocations] =
    useState([]);

  const userContext = useContext(UserContext);
  const [queryBuilderInput, setQueryBuilderInput] = useState({});

  function getFromDate() {
    let date = new Date();
    date.setMonth(date.getMonth() - 3);
    return new Date(date);
  }

  const [measureOptions, setMeasureOptions] = useState([
    { label: "Claim volume", value: "1" },
    { label: "Reimbursement", value: "2" },
    { label: "Profit $", value: "3" },
    { label: "Dispense fee paid", value: "4" },
    { label: "Average cost per claim", value: "5" },
    { label: "Average revenue per claim", value: "6" },
    { label: "Average profit $ per claim", value: "7" },
    { label: "Average profit % per claim", value: "8" },
  ]);
  const [selectedMeasure, setSelectedMeasure] = useState(null);

  const [displayTypeOptions, setDisplayTypeOptions] = useState([
    { label: "High", value: "2" },
    { label: "Median", value: "1" },
    { label: "Low", value: "3" },
  ]);
  const [selectedDisplayType, setSelectedDisplayType] = useState(null);

  function submitForm() {
    props.handleFormSubmit({
      toDate: toDate.toLocaleDateString(),
      fromDate: fromDate.toLocaleDateString(),
      selectedLocations: selectedLocations,
      selectedMeasure: selectedMeasure,
      selectedComparisonLocations: selectedComparisonLocations,
      selectedDisplayType: selectedDisplayType,
      queryBuilderInput: queryBuilderInput,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    let isMoreThanFiveSearchTerms = false;

    for (let x = 0; x < queryBuilderInput.length; x++) {
      let selectedInput = queryBuilderInput[x].options;

      if (
        typeof selectedInput === "string" &&
        (selectedInput.match(/;/g) || []).length > 5
      ) {
        isMoreThanFiveSearchTerms = true;
      }
    }

    if (isMoreThanFiveSearchTerms) {
      alert(
        "A maximum of 5 different search values are allowed on the Query Builder, please review your search values to ensure this limit is not exceeded."
      );
    } else if (!toDate || !fromDate) {
      alert("Date values must be entered.");
    } else {
      submitForm();
    }
  }

  function handleQueryBuilderOptionChanges(queryBuilderRows) {
    setQueryBuilderInput(queryBuilderRows);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-between">
        <Col md="auto">
          <Row>
            <Form.Group
              as={Col}
              md="auto"
              className="d-flex flex-column"
              controlId="formGridFromDate"
            >
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                name="fromDate"
                as={DatePicker}
                selected={fromDate}
                onChange={(date) => {
                  date <= toDate
                    ? setFromDate(date)
                    : alert("Please choose a valid start date!");
                }}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md="auto"
              className="d-flex flex-column"
              controlId="formGridToDate"
            >
              <Form.Label>End Date</Form.Label>
              <Form.Control
                name="toDate"
                as={DatePicker}
                selected={toDate}
                onChange={(date) => {
                  date >= fromDate
                    ? setToDate(date)
                    : alert("Please choose a valid end date!");
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="auto" className="d-flex flex-column">
              <Form.Label>Location (NCPDP)</Form.Label>
              <MultiSelectDropdown
                options={userContext.metricLocations || []}
                onChange={(locations) => {
                  setSelectedLocations(locations);
                }}
                externalDataReference={selectedComparisonLocations}
                isDisableOptionsFromExternalDataReference
                filterable
                maxAllowedSelections={7}
              />
            </Form.Group>
            <Form.Group as={Col} md="auto" className="d-flex flex-column">
              <Form.Label>Measure</Form.Label>
              <SingleSelectDropdown
                options={measureOptions}
                defaultSelected={measureOptions[5]}
                onChange={(selectedMeasure) =>
                  setSelectedMeasure(selectedMeasure)
                }
              />
            </Form.Group>
          </Row>
        </Col>
        <Col md="auto">
          <Row>
            <Form.Group as={Col} md="auto">
              <Form.Label>Comparison Locations</Form.Label>
              <MultiSelectDropdown
                options={userContext.metricLocations || []}
                onChange={(selectedComparisonLocations) => {
                  setSelectedComparisonLocations(selectedComparisonLocations);
                }}
                externalDataReference={selectedLocations}
                isDisableOptionsFromExternalDataReference
                filterable
              />
            </Form.Group>
            <Form.Group as={Col} md="auto">
              <Form.Label>Display Type</Form.Label>
              <SingleSelectDropdown
                options={displayTypeOptions}
                defaultSelected={displayTypeOptions[1]}
                onChange={(selectedDisplayType) =>
                  setSelectedDisplayType(selectedDisplayType)
                }
                externalDataReference={selectedMeasure}
              />
            </Form.Group>
            <Form.Group as={Col} md="auto" controlId="show-result-button">
              <Button
                className="inline-show-result-button"
                size="sm"
                type="submit"
              >
                Show Results
              </Button>
            </Form.Group>
          </Row>
        </Col>
      </Row>

      <QueryBuilder
        optionsFromForm={constants.queryBuilderOptions}
        handleOptionChangeOnForm={(options) =>
          handleQueryBuilderOptionChanges(options)
        }
      />
    </Form>
  );
}

LocationComparisonForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
