import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MainLogo from "../assets/MHA-NetRx-Logo.svg";
import Background from "../assets/Login-Background.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useOktaAuth } from "@okta/okta-react";

import OktaSignInWidget from "./OktaSignInWidget";

export default function LoginForm() {
  const navigate = useNavigate();

  const [authenticationErrored, setAuthenticationErrored] = useState(null);

  //Encountered situations where authentication threw error (due to pkce mismatch or other reasons), however the user was still authenticated
  //therefore add this test to double check if use is authenticated and if so navigate to home page
  useEffect(() => {
    if (!authenticationErrored) return;

    if (authState.isAuthenticated) navigate("/");
  }, [authenticationErrored]);

  const { oktaAuth, authState } = useOktaAuth();
  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    if (!authenticationErrored) setAuthenticationErrored(true);

    //alert("Sign in error");
    console.log("Sign in error:", err);
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  return authState.isAuthenticated ? (
    navigate("/")
  ) : (
    <Container
      id="content"
      fluid
      className="login-screen"
      style={{
        backgroundImage: "url(" + Background + ")",
        backgroundSize: "auto 100%",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Row className="h-100">
        <Col className="mx-auto align-self-center">
          <center>
            <Card className="rounded size-to-content">
              <Card.Img
                variant="top"
                src={MainLogo}
                width="210"
                height="80"
                alt="logo"
              />
              <Card.Body>
                <Card.Title id="login-title">
                  Reimbursement Management Suite
                </Card.Title>
                {<OktaSignInWidget onSuccess={onSuccess} onError={onError} />}
              </Card.Body>
            </Card>
          </center>
        </Col>
      </Row>
    </Container>
  );
}
