import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Col, Button, Row } from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";

import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "../../context/UserContext";

export function CurrentAgingForm(props) {
  const [reportType, setReportType] = useState("unreconciled");
  const [dateType, setDateType] = useState("dispense");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);
  
  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations]);

  function handleFormSubmit(event) {
    event.preventDefault();
    submitForm();
  }

  function submitForm() {
    let parameters = {
      selectedLocations: selectedLocations,
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        reportType: reportType,
        dateType: dateType,
        mmid: selectedLocations.join(","),
      });
    }
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start">
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Date Type</Form.Label>
          <div key="DateType" className="mb-3 mr-2">
            <Form.Check
              custom="true"
              type="radio"
              id="DateTypeCA-1"
              name="DateType"
              label="Dispense date"
              value="dispense"
              onChange={(event) => setDateType(event.target.value)}
              defaultChecked
            />
            <Form.Check
              custom="true"
              type="radio"
              id="DateTypeCA-2"
              name="DateType"
              label="Transaction date"
              value="transaction"
              onChange={(event) => setDateType(event.target.value)}
            />
          </div>
        </Col>

        <Col md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

CurrentAgingForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
