import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

export default function MultiSelectDropdownFilter(props) {

    const { show } = props
    const [text, setText] = useState('');

    function handleTextChange(text) {
        setText(text)
        props.handleFilterChanged(text)
    }

    return show ? (
        <InputGroup>
            <FormControl
                type='text'
                value={text}
                onChange={event => handleTextChange(event.target.value)}
                placeholder="Search..."
            />
            <InputGroup.Text>
                <i className="fa fa-search multi-select-dropdown-filter-icon" />
            </InputGroup.Text>
        </InputGroup>
    ) : null
}
