import React from 'react';
import axios from "axios";

// import SharedMainSelectableGridNewImplementation, {
//     AddGridButton,
// } from "../../grid/Kendo/SharedMainSelectableGridNewImplementation";

import SharedMainGrid from '../../grid/Kendo/SharedMainGrid';

import { GridColumn as Column } from '@progress/kendo-react-grid';
import 'font-awesome/css/font-awesome.min.css';
import { Row, Col } from "react-bootstrap";

import { DefaultColumnWidth, DefaultDateColumnWidth, DefaultBooleanOrShortWidthColumnWidth } from "../../grid/Kendo/KendoGridAndColumnConstants";
import { MoneyCell } from "../../grid/Kendo/CustomKendoGridCells";

export function AdminExportBatchDetailsHistoryGrid(props) {
    const { data, user, getAccessToken, onFailure, setParentIsLoading } = props;
    const pmidKey = "pmid";

    return (
        <Row>
            <Col className="tight-grid multi-line-filter">
                <SharedMainGrid
                    data={data}
                    aggregateColumnSettings={null}
                    sendAggregatesParentCallback={() => { }}
                    dataItemKey={pmidKey}
                    styleOverride={{ height: "600px" }}
                >
                    <Column field="batchID" title="Batch Id" width={DefaultBooleanOrShortWidthColumnWidth()} />
                    <Column field="payer" title="Payer" width={DefaultColumnWidth()} />
                    <Column field="checkNumber" title="Check Number" width={DefaultColumnWidth()} />
                    <Column field="checkDate" title="Check Date" width={DefaultDateColumnWidth()} />
                    <Column field="checkAmount" title="Check Amount" width={DefaultColumnWidth()} cells={{ data: MoneyCell }}/>
                    <Column field="remitDate" title="Remit Date" width={DefaultDateColumnWidth()} />
                    <Column field="paymentDate" title="Payment Date" width={DefaultDateColumnWidth()} />
                    <Column field="archiveDate" title="Archive Date" width={DefaultDateColumnWidth()} />
                    <Column field="ncpdp" title="NCPDP" width={DefaultColumnWidth()} />
                    <Column field="fileStatus" title="Status" width={DefaultColumnWidth()} />
                    <Column field="fileName" title="File Name" width={DefaultColumnWidth()} />
                </SharedMainGrid>
            </Col>
        </Row>
    );
}