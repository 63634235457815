import React from 'react'

export const constants = {
    queryBuilderOptions: [
        {
            id: "BIN",
            isMultipleAllowed: false,
            controlCategory: "text"
        },
        {
            id: "PCN",
            isMultipleAllowed: false,
            controlCategory: "text"
        },
        {
            id: "Drug Type",
            controlCategory: "multiSelectDropdown",
            values: [
                {
                    value: "B",
                    label: "Brand (Single Source, no generic available)"
                },
                {
                    value: "G",
                    label: "Generic"
                },
                {
                    value: "BO",
                    label: "Brand Originator (Generic available)"
                },
                {
                    value: "BG",
                    label: "Branded Generic"
                },
                {
                    value: "MB",
                    label: "Multisource Brand"
                },
                {
                    value: "MG",
                    label: "Generic for Multisource Brand"
                },
                {
                    value: "OB",
                    label: "OTC Brand (Single Source, no generic available)"
                },
                {
                    value: "OG",
                    label: "OTC Generic"
                },
                {
                    value: "OBO",
                    label: "OTC Brand Originator (Generic available)"
                },
                {
                    value: "OBG",
                    label: "OTC Branded Generic"
                },
                {
                    value: "OMB",
                    label: "OTC Multisource Brand"
                },
                {
                    value: "OMG",
                    label: "OTC Generic for Multisource Brand"
                }
            ]
        },
        {
            id: "Patient Residence Code",
            controlCategory: "multiSelectDropdown",
            values: [
                {
                    value: "00",
                    label: "Not Specified"
                },
                {
                    value: "01",
                    label: "Home"
                },
                {
                    value: "02",
                    label: "SNF Medicare B Use Only"
                },
                {
                    value: "03",
                    label: "SNF"
                },
                {
                    value: "04",
                    label: "ALF"
                },
                {
                    value: "05",
                    label: "Custodial Care/Part B"
                },
                {
                    value: "06",
                    label: "Group Home"
                },
                {
                    value: "07",
                    label: "Inpatient Psychiatic Facility/non-pharmacy"
                },
                {
                    value: "08",
                    label: "Psychiatic Facility Partial Hospitalization/non-pharmacy"
                },
                {
                    value: "09",
                    label: "ICF/MR"
                },
                {
                    value: "10",
                    label: "Substance Abuse Facility/non-pharmacy"
                },
                {
                    value: "11",
                    label: "Hospice"
                },
                {
                    value: "12",
                    label: "Psychiatic Residential Treatment Facility/non-pharmacy"
                },
                {
                    value: "13",
                    label: "Rehabilitation/non-pharmacy"
                },
                {
                    value: "14",
                    label: "Homeless Shelter/non-pharmacy"
                },
                {
                    value: "15",
                    label: "Correctional Institution"
                }
            ]
        },
        {
            id: "Therapeutic Class",
            isMultipleAllowed: false,
            controlCategory: "text"
        },
        {
            id: "Therapeutic Class Code",
            isMultipleAllowed: false,
            controlCategory: "text"
        }
    ]
}

