import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export function HistoryModal(props) {
    const { show, title, children, handleClose } = props

    return (
        <Modal className="grid-remittance-history-modal"  show={show} onHide={handleClose} enforceFocus={false} >
            <Modal.Header>
                <h3>{title}</h3>
            </Modal.Header>
            <Modal.Body>           
                {children}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export function EditConfirmationModal(props) {
    const { show, title, children, handleClose,handleOk } = props

    return (
        <Modal show={show} onHide={handleClose} enforceFocus={false} >
            <Modal.Header>
                <h4>{title}</h4>
            </Modal.Header>
            <Modal.Body>           
                {children}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleOk}>
                    Ok
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>

    )
}