import React, { useState } from "react";
import classNames from 'classnames'

import MultiSelectDropdownBody from './MultiSelectDropdownBody'
import useComponentVisible from '../hooks/useComponentVisible'

export default function MultiSelectDropdown(props) {
    const { options, selectAllOption, filterable, onChange, maxAllowedSelections = options.length, onLoadAllSelected = false, externalDataReference, isDisableOptionsFromExternalDataReference, defaultSelected = [] } = props
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [label, setLabel] = useState('Select...')
    const [expanded, setExpanded] = useState(false)

    function handleDropdownClick() {
        if (!isComponentVisible) {
            setExpanded(true)
            setIsComponentVisible(true)
        } else {
            setExpanded(!expanded)
            setIsComponentVisible(false)
        }
    }

    function onOptionChange(selectedOptions) {
        if (selectedOptions.length <= maxAllowedSelections) {
            setLabel(selectedOptions.filter(option => option).length + ' Selected')
            onChange(selectedOptions)
        }
    }

    let className = classNames('multi-select-dropdown', { 'expanded': expanded }, { 'collapsed': !expanded });

    return (
        <div ref={ref}>
            <button type="button" className={className} onClick={handleDropdownClick}>
                <span className="multi-select-dropdown-label">{label}</span>
                <i className={isComponentVisible ? "fa fa-angle-up" : "fa fa-angle-down"} />
            </button>
            <MultiSelectDropdownBody
                show={isComponentVisible && expanded}
                onChange={onOptionChange}
                options={options}
                filterable={filterable}
                selectAllOption={selectAllOption}
                onLoadAllSelected={onLoadAllSelected}
                externalDataReference={isDisableOptionsFromExternalDataReference ? externalDataReference : null}
                maxAllowedSelections={maxAllowedSelections}
                defaultSelected={defaultSelected}
            />
        </div>
    )
}