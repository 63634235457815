const FormatMoney = (input) => {
  let currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return input < 0
    ? "(" + currency.format(-input) + ")"
    : currency.format(input);
};

function FormatPercent(input) {
  let percent = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return input < 0 ? "(" + percent.format(input) + ")" : percent.format(input);
}

function FormatBool(input) {
  return input ? "Yes" : "No";
}

function FormatUnkownType(input) {
  switch (typeof input) {
    case "number":
      return input.toString();
    case "string":
      return input.toLocaleUpperCase();
    default:
      return "";
  }
}

function FormatDate(input) {
  return input
    ? new Intl.DateTimeFormat("en-US").format(new Date(input)).toString()
    : "";
}

function FormatValue(value, type) {
  switch (type) {
    case "date":
      return FormatDate(value);
    case "money":
      return FormatMoney(value);
    case "percent":
      return FormatPercent(value);
    case "bool":
      return FormatBool(value);
    default:
      return FormatUnkownType(value);
  }
}

export { FormatValue };
