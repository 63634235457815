import React, { useState } from "react";
import classNames from 'classnames'

import SingleSelectDropdownBody from './SingleSelectDropdownBody'
import useComponentVisible from '../hooks/useComponentVisible'

export default function SingleSelectDropdown(props) {

    const { options, onChange, onLoadAllSelected, defaultSelected, externalDataReference, isDisableOptionsFromExternalDataReference,highlightSelection } = props

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const [label, setLabel] = useState('Select...')
    const [expanded, setExpanded] = useState(false)

    function handleDropdownClick() {
        if (!isComponentVisible) {
            setExpanded(true)
            setIsComponentVisible(true)
        } else {
            setExpanded(!expanded)
            setIsComponentVisible(false)
        }
    }

    function onOptionChange(label, selectedOptions) {
        if (!selectedOptions || selectedOptions.length === 0) {
            setLabel('Select...');
        }
        else {
            setLabel(label);
        }

        onChange(selectedOptions)
        setExpanded(false)
        setIsComponentVisible(false)

    }

    let className = classNames('multi-select-dropdown', { 'expanded': expanded }, { 'collapsed': !expanded });

    return (
        <div ref={ref}>
            <button type="button" className={className} onClick={handleDropdownClick}>
                <span className={highlightSelection ? "multi-select-dropdown-label selection-highlight":"multi-select-dropdown-label"}>{label}</span>
                <i className={isComponentVisible ? "fa fa-angle-up" : "fa fa-angle-down"} />
            </button>
            <SingleSelectDropdownBody
                show={isComponentVisible && expanded}
                onChange={onOptionChange}
                options={options}
                externalDataReference={isDisableOptionsFromExternalDataReference ? externalDataReference : null}
                onLoadAllSelected={onLoadAllSelected}
                defaultSelected={defaultSelected}
            />
        </div>
    )
}