import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { SharedFormRow, SharedFormColumn } from "../../layout/SharedFormElements/SharedFormRowAndColumns";

import FormValidation from "../../form/validation/FormValidation";
import { UserContext } from "../../context/UserContext";

export function AdminExportBatchHistoryForm(props) {
  const { handleFormSubmit } = props;

  const [fromDate, setFromDate] = useState(getFromDate());
  const [toDate, setToDate] = useState(new Date());

  const [selectedParentCompany, setSelectedParentCompany] = useState("");
  const [batchId, setBatchId] = useState("");
  const [batchStatus, setBatchStatus] = useState("all");

  const userContext = useContext(UserContext);
  const parentCompanyOrganizations = userContext.organizations.filter(org => org.type === "ParentCompany");

  function getFromDate() {
    let date = new Date();
    let currentMilliseconds = date.getUTCMilliseconds();
    //NOTE: Set it for 6 months ago
    date.setUTCMilliseconds(currentMilliseconds - 15780000000);
    return new Date(date);
  }

  function submitForm() {
    let parameters = {
      toDate: toDate,
      fromDate: fromDate,
      batchId: batchId,
      batchStatus: batchStatus,
      selectedParentCompany: selectedParentCompany,
      requiredFields: ["toDate", "fromDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      handleFormSubmit(parameters);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitForm();
  }

  let parentCompanyDropdown = (
    <SharedFormColumn>
      See this dropdown only for Admins
    </SharedFormColumn>
  )

  return (
    <Form onSubmit={handleSubmit}>
      <SharedFormRow>
        <SharedFormColumn>
          <Form.Label>Parent Company</Form.Label>
          <Form.Control
            as={ComboBox}
            data={parentCompanyOrganizations || []}
            id="value"
            textField="labelNoSuffix"
            allowCustom={false}
            style={{
              textSizeAdjust: "small",
            }}
            value={selectedParentCompany}
            onChange={(e) => {
              if (e.target.value) {
                setSelectedParentCompany(e.target.value);
              } else setSelectedParentCompany(null);
            }}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Batch ID</Form.Label>
          <Form.Control
            name="batchId"
            type="text"
            placeholder=""
            onChange={(e) => {
              setBatchId(e.target.value);
            }}
            value={batchId}
          />
        </SharedFormColumn>
        <SharedFormColumn>
          <Form.Label>Batch Status</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              setBatchStatus(e.target.value);
            }}
          >
            <option value="all">All</option>
            <option value="readyToDownload">Ready to Download</option>
            <option value="downloaded">Downloaded</option>
            <option value="pendingBuildProcess">Pending Build Process</option>
            <option value="completedWithErrors">Completed With Errors - Please Review</option>
          </Form.Control>
        </SharedFormColumn>
      </SharedFormRow>
      <SharedFormRow>
        <SharedFormColumn>
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </SharedFormColumn>
      </SharedFormRow>
    </Form>
  );
}

AdminExportBatchHistoryForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
