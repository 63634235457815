import React from 'react'
import "./SelectableHeaderCell.css"

export default function SelectableHeaderCell(props) {
    const { show, leftOffset, selected, handleAllRowsSelected } = props

    if (!show) return null

    const styles = leftOffset > 0 ? {
        transform: `translate(${leftOffset}px, 0px)`,
        position: "relative",
        zIndex: 1
    } : null

    return (
        <div className="selectable-header-cell" style={styles}>
            <form>
                <input className="selectable-header-cell-input"
                    name="selected"
                    type="checkbox"
                    checked={selected}
                    onChange={handleAllRowsSelected}
                />
            </form>
        </div>
    )
}