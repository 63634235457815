import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Button, Alert, Modal, Form } from "react-bootstrap";
import ErrorHandler from "../../core/ErrorHandler";
import { LoadingSpinner } from "../../layout/LoadingSpinner";
import Accordion from "../../Accordion";
import AdminRemittanceLookupForm from "./AdminRemittanceLookupForm";
import SharedMainGridMultipleCheckBox from "../../grid/Kendo/SharedMainGridMultipleCheckBox";
import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";
import { ZIndexContext } from "@progress/kendo-react-common";

import { constants } from "./AdminRemittanceLookupContants";
import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultNoFilterColumnWidth,
  DefaultNoFilterCheckBoxColumnWidth
} from "../../grid/Kendo/KendoGridAndColumnConstants";
import { LogError } from "../../../../src/components/AppInsights/TelemetryService";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import {
  MoneyCell,
  AggregateMoneyFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";
import './AdminRemittance.css';
export default function AdminRemittanceLookup(props) {
  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [expanded, setExpanded] = useState(false);
  const _container = React.useRef()
  const sendAccordionParent = (props) => {
    setExpanded(props);
  };
  const [submittedFormData, setSubmittedFormData] = useState(false);
  const [mainGridData, setMainGridData] = useState([]);
  const [enableFilterSetting, setEnableFilterSetting] = useState(true);
  const [mainGridColumns, setMainGridColumns] = useState([]);
  const [
    mainGridMoneyColumnAggregateSettings,
    setMainGridMoneyColumnAggregateSettings,
  ] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);
  const [mainGridFilters, setMainGridFilters] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [reloadCheckConfirmation, setReloadCheckConfirmationModel] =
    useState(false);
  const [deleteCheckConfirmation, setDeleteCheckConfirmationModel] =
    useState(false);

  function handleFormSubmit(formInput) {
    setEnableFilterSetting(true);
    setSubmittedFormData(formInput);
  }

  function handleForReloadCheck() {
    setReloadCheckConfirmationModel(true);
    //saveReloadCheck();
  }

  function handleReloadCheckConfirmationYesButton() {
    setReloadCheckConfirmationModel(false);
    saveReloadCheck();
  }

  function handleForDeleteCheck() {
    setDeleteCheckConfirmationModel(true);
  }

  function handleDeleteCheckConfirmationYesButton() {
    setDeleteCheckConfirmationModel(false);
    deleteCheck();
  }

  function refrehGridActiveColors(){
    var grid = _container.current.querySelector('.k-table-tbody');
    if(grid){
      var rows = grid.querySelectorAll('tr');
      rows.forEach((row) => {
        var colorChanges = "applyRedColor";
        var cells = row.querySelectorAll('td');
        var applyColor = false;       
        cells.forEach((cell) => {
          if(cell.className.indexOf('redcolor')>0){
            applyColor = true;
          }          
          if(applyColor && cell.className.indexOf('positiveCurrency')>0){
            cell.className = cell.className.replace('positiveCurrency', '');
          }
        });
        if(applyColor){
          row.className = row.className + " " + colorChanges;
        }else{
          row.className = row.className.replace(colorChanges, '');
        }
      });
    }
  }

  function handleFormReset() {
    setEnableFilterSetting(true);
    setMainGridData([]);
    setMainGridColumns([]);
  }
  useEffect(() => {
    if (submittedFormData) {
      getRemittance();
    }
  }, [submittedFormData]);

  function getRemittance() {
    let accessToken = passedProps.auth.getAccessToken();
    setIsLoading(true);
    try {
      let response = axios
        .get(`api/RemittanceLookup/GetAdminRemittanceReport`, {
          params: {
            user: user,
            checkAmount: submittedFormData.checkAmount,
            parentCompantyID: submittedFormData.selectedParentCompany,
            nCPDP: submittedFormData.selectedLocations,
            checkNumber: submittedFormData.checkNumber,
            startDate: submittedFormData.startDate,
            endDate: submittedFormData.endDate,
            suppressTypeId: submittedFormData.suppressTypeId,
            payerId: submittedFormData.selectedPayers,
            dateTypeId: submittedFormData.dateTypeId,
            fileName: submittedFormData.fileName,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }
  function onSuccess(response) {
    switch (response.config.url) {
      case "api/RemittanceLookup/GetAdminRemittanceReport":
        presentGridData(response.data);        
        setIsLoading(false);
        break;
      case "api/RemittanceLookup/AdminRemittanceReloadChecks":        
        setEnableFilterSetting(true);
        setMainGridData([]);
        getRemittance(); 
        break;
      case "api/RemittanceLookup/AdminRemittanceDeleteChecks":
        setEnableFilterSetting(true);
        setMainGridData([]);
        getRemittance();
        break;
      default:
        break;
    }
  }

  function presentGridData(data) {
    let columns = [];

    if (data.length > 0) {
      let headerProps = constants.headerProps;
      columns.push({
        accessor: "selectedAction",
        title: "Action",
        type: "CheckBox",
      });
      // columns.push({
      //   accessor: "centralPay",
      //   title: "CentralPay",
      //   type: "CheckBox",
      // });
      // columns.push({
      //   accessor: "autoArchive",
      //   title: "Unsuppress",
      //   type: "CheckBox",
      // });

      Object.keys(data[0]).map((key) => {
        if (key !== "PBID") {
          if (headerProps[key]) {
            columns.push({
              accessor: key,
              title: headerProps[key] ? headerProps[key].title : key,
              type: headerProps[key] ? headerProps[key].type : "money",
              showTotal: headerProps[key] ? headerProps[key].showTotal : true,
              selectable: headerProps[key] ? headerProps[key].selectable : true,
              hidden: headerProps[key] ? headerProps[key].hidden : false,
            });
          }
        }
      });
      columns.push({
        accessor: "exportFile",
        title: "Export File",
        type: "DownloadLink",
      });
      columns.push({
        accessor: "filename",
        title: "Split File",
        type: "DownloadLink",
      });
      columns.push({
        accessor: "filename",
        title: "Raw File",
        type: "DownloadLink",
      });
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }

    let dataWithCleanedDates = data.map((t) => {
      return {
        ...t,
        selectedAction: false,
        checkDate: IsNullOrWhitespace(t.checkDate)
          ? ""
          : new Date(Date.parse(t.checkDate)),
        remitDate: IsNullOrWhitespace(t.remitDate)
          ? ""
          : new Date(Date.parse(t.remitDate)),
        paymentDate: IsNullOrWhitespace(t.paymentDate)
          ? ""
          : new Date(Date.parse(t.paymentDate)),
        archiveDate: IsNullOrWhitespace(t.archiveDate)
          ? ""
          : new Date(Date.parse(t.archiveDate)),
        loadDate: IsNullOrWhitespace(t.loadDate)
          ? ""
          : new Date(Date.parse(t.loadDate)),
        exportDate: IsNullOrWhitespace(t.exportDate)
          ? ""
          : new Date(Date.parse(t.exportDate)),
      };
    });
    setMainGridData(dataWithCleanedDates);
    setMainGridColumns(columns);

    let moneyColumns = [];
    columns.forEach((column) => {
      if (column.type === "money") {
        moneyColumns.push({ aggregate: "sum", field: column.accessor });
      }
    });

    setMainGridMoneyColumnAggregateSettings(moneyColumns);
  }

  function saveReloadCheck() {
    var currentItem = mainGridData.filter(
      (item) => item["selectedAction"] == true
    );
    if (currentItem.length <= 0) {
      alert("Please select at least one record to reload check");
    }
    let accessToken = passedProps.auth.getAccessToken();
    setIsLoading(true);
    try {
      var formatSelectedItem = currentItem.map((item, i) => {
        return {
          id: i,
          pMID: item.pmid,
          rPID: item.pM_RPID,
          pBID: item.pB_PBID,
          checkNo: item.checkNumber,
          checkDate: item.checkDate,
          checkAmt: item.checkAmount,
          fileName: item.filename,
          queueStatus: 'A',
        };
      });

      let response = axios
        .post(
          "api/RemittanceLookup/AdminRemittanceReloadChecks",
          {
            user: user,
            rpid: null,
            comments: "",
            queueID: null,
            newRawFileName: "",
            status: "3",
            checkedItems: JSON.stringify(formatSelectedItem),
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function deleteCheck() {
    var currentItem = mainGridData.filter(
      (item) => item["selectedAction"] == true
    );
    if (currentItem.length <= 0) {
      alert("Please select at least one record to reload check");
    }
    let accessToken = passedProps.auth.getAccessToken();
    setIsLoading(true);
    try {
      var formatSelectedItem = currentItem.map((item, i) => {
        return {
          id: i,
          pMID: item.pmid,
          rPID: item.pM_RPID,
          pBID: item.pB_PBID,
          checkNo: item.checkNumber,
          checkDate: item.checkDate,
          checkAmt: item.checkAmount,
          fileName: item.filename,
          queueStatus: item.exportStatus,
        };
      });

      let response = axios
        .post(
          "api/RemittanceLookup/AdminRemittanceDeleteChecks",
          {
            user: user,
            rpid: submittedFormData.selectedParentCompany,
            Notes: "",
            checkedItems: JSON.stringify(formatSelectedItem),
          },
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  const checkboxCell = (props) => {
    var checked = true;
    var disabled = false;
    var colorChanges = "";
    colorChanges = props.dataItem['reloadStatus'] == 'A' ? "redcolor" : "";
    props.tdProps.className = props.tdProps.className + " " + colorChanges;
    if (props.field === "autoArchive") {
      checked = props.dataItem[props.field];
    } else if (props.field === "centralPay") {
      checked = props.dataItem[props.field];
      disabled = props.dataItem["pM_GPID"] === null;
    } else if (props.field === "pMID") {
      checked = props.dataItem[props.field];
    }
    return (
      <td {...props.tdProps} style={{ ...props.style }}>
        <Form.Check
          custom="true"
          type="checkbox"
          label=""
          checked={props.dataItem[props.field]}
          onChange={(event) => {
            if (props.dataItem["pmid"]) {
              var result = mainGridData.map((item) => {
                if (item["pmid"] === props.dataItem["pmid"]) {
                  item[props.field] = event.target.checked;
                }
                return item;
              });
              setEnableFilterSetting(false);
              setMainGridData(result);
            }
          }}
          defaultChecked={checked}
          disabled={disabled}
        />
      </td>
    );
  };

  const checkboxHeaderCell = (props) => {
    var selectAll = false;
    mainGridData.filter(filterItem => filterItem[props.children[0].props.field] === false).length > 0 ? selectAll = false : selectAll = true;
    return (
      <td {...props.tdProps} style={{ ...props.style }}>
        {props.children}
        <Form.Check
          custom="true"
          type="checkbox"
          label=""
          checked={selectAll}
          onChange={(event) => {
            var result = mainGridData.map((item) => {
              item[props.children[0].props.field] = event.target.checked;
              return item;
            });
            setEnableFilterSetting(false);
            setMainGridData(result);
          }}
        />
      </td>
    );
  };

  function onDownloadFileFailure(fileType) {
    setIsLoading(false);
    let msg = `Unable to download ${fileType} payment details.`;
    alert(msg);
  }

  function GetDownloadFileControllerName(fileType) {

    switch (fileType.toLowerCase()) {
      case "rawfile":
        return "GetAdminRawFile";
      case "splitfile":
        return "GetAdminSplitFile";
      case "exportfile":
        return "GetAdminExportFile";
      default:
        throw new Error("Invalid fileType");
    }
  }

  function DownloadFileContent(fileType, paymentId) {
    try {
      setIsLoading(true);
      let methodName = GetDownloadFileControllerName(fileType);

      let accessToken = passedProps.auth.getAccessToken();

      let parameters = {
        paymentId: paymentId,
      };

      axios
        .get(`api/RemittanceLookup/${methodName}`, {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          let fileName = response.data.fileName;
          let downloadBytes = Base64ToArrayBuffer(response.data.fileStream);
          setIsLoading(false);
          SaveByteArray(fileName, downloadBytes);
        })
        .catch((error) => onDownloadFileFailure(fileType));
    } catch (exception) {
      onDownloadFileFailure(fileType);
   
    }
  }

  const downloadFileCell = (props) => {
    function DownloadFile() {
      let paymentId = props.dataItem["pmid"];

      let fileType = "rawfile";

      if (props.field === "exportFile") {
        fileType = 'exportFile';
      } else if (props.columnIndex == 15) {
        fileType = 'rawfile';
      }
      else if (props.columnIndex == 14) {
        fileType = 'splitfile';
      }
      DownloadFileContent(fileType, paymentId);
    }
    if (props.field == "exportFile") {
      return (
        <td {...props.tdProps} style={{ ...props.style }}>
          {!(props.dataItem['batchcompleted'] == 'X' || props.dataItem['batchcompleted'] == null || props.dataItem['batchcompleted'] == '0') ? (
            <span style={{ cursor: "pointer" }} onClick={DownloadFile}>
              <i class="fa fa-download" aria-hidden="true"></i>{" "}
            </span>
          ) : (
            ""
          )}{" "}
        </td>
      );
    } else {
      return (
        <td {...props.tdProps} style={{ ...props.style }}>
          {props.dataItem[props.field] ? (
            <span style={{ cursor: "pointer" }} onClick={DownloadFile}>
              <i class="fa fa-download" aria-hidden="true"></i>{" "}
            </span>
          ) : (
            ""
          )}{" "}
        </td>
      );
    }


  };

  const exportStatusCell = (props) => {
    function ShowAlertMessage() {
      alert(props.dataItem[props.field]);
    }
    return (
      <td {...props.tdProps} style={{ ...props.style }}>
        {props.dataItem[props.field] == "Completed" || props.dataItem[props.field] == null ? (<span>
          {props.dataItem[props.field]}
        </span>) : (
          <span class="hyperlinkStyle" onClick={ShowAlertMessage}>
            Error
          </span>)}
      </td>
    );
  };

  function onFailure(error) {
    LogError(error);

    switch (error.response.config.url || error.config.url) {
      case "api/RemittanceLookup/GetAdminRemittanceReport":
        setError(error.response);
        setMainGridColumns([]);
        setMainGridData([]);
        break;
    }
    setIsLoading(false);
    return;
  }

  const sendAggregatesToParent = (aggregatesFromSharedMainAgingGrid) => {
    setMainGridAggregates(aggregatesFromSharedMainAgingGrid.data);

    //NOTE: This is really just needed because we need to get the list of BIN-PCN combos used for the details data call for the aggregates
    setMainGridFilters(aggregatesFromSharedMainAgingGrid.filter);
  };

  useEffect(() => {refrehGridActiveColors();}, [mainGridAggregates]);
  const AggregateMainGridMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates,
    };

    return AggregateMoneyFooterCell(props, options);
  };

  function CreateGridColumn(column) {
    let columnWidth = DefaultColumnWidth();

    let filterType = "text";
    if (column.type === "money") {
      filterType = "numeric";
    } else if (column.type === "bool") {
      filterType = "boolean";
    } else if (column.type === "date") {
      filterType = "date";
    }

    if (column.hidden) {
      return null;
    }

    if (column.type === "money") {
      return (
        <GridColumn
          key={column.accessor}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          cells={{
            data: MoneyCell,
            footerCell: AggregateMainGridMoneyFooterCell,
          }}
          footerCell={AggregateMainGridMoneyFooterCell}
        />
      );
    } else if (column.type === "date") {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          format="{0:d}"
          width={DefaultDateColumnWidth()}
        />
      );
    } else if (column.type === "CheckBox") {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filterable={false}
          //filter={filterType}
          field={column.accessor}
          title={column.title}
          cells={{
            data: checkboxCell,
            headerCell: checkboxHeaderCell,
          }}
          width={DefaultNoFilterCheckBoxColumnWidth()}
        />
      );
    } else if (column.type === "DownloadLink") {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filterable={false}
          field={column.accessor}
          title={column.title}
          cells={{
            data: downloadFileCell,
          }}
          width={DefaultNoFilterColumnWidth()}
        />
      );
    } else if (column.accessor === "exportStatus") {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filterable={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
          cells={{
            data: exportStatusCell,
          }}
        />
      );
    }
    else {
      return (
        <GridColumn
          key={column.accessor + "Column"}
          filter={filterType}
          field={column.accessor}
          title={column.title}
          width={columnWidth}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <div className="feature">
        <LoadingSpinner
          isDataLoading={isLoading}
          controlsName={"AdminRemittanceLookup"}
        />
        <Accordion
          defaultExpanded
          label=""
          sendAccordionParentCallback={sendAccordionParent}
        >
          <Row>
            <Col>
              <AdminRemittanceLookupForm
                passedProps={passedProps}
                handleFormSubmit={handleFormSubmit}
                handleForDeleteCheck={handleForDeleteCheck}
                handleFormReset={handleFormReset}
                handleForReloadCheck={handleForReloadCheck}
                enabledButton={mainGridData.length > 0}
              />
            </Col>
          </Row>         
        </Accordion> 
        <div ref={_container}>
        <Row>
            <Col className="tight-grid multi-line-filter">
              <SharedMainGridMultipleCheckBox
                data={mainGridData}
                hasfilterable={enableFilterSetting}
                aggregateColumnSettings={mainGridMoneyColumnAggregateSettings}
                sendAggregatesParentCallback={sendAggregatesToParent}
                exportFileNamePrefix={"RemittanceLookup"}
              >
                {mainGridColumns.length > 0
                  ? mainGridColumns.map((singleColumn) =>
                    CreateGridColumn(singleColumn)
                  )
                  : null}
              </SharedMainGridMultipleCheckBox>
            </Col>
          </Row>       
          </div>
      <ZIndexContext.Provider value={10005}>
      <Modal
          show={reloadCheckConfirmation}
          onHide={() => {
            setReloadCheckConfirmationModel(false);
          }}
        >
          <Modal.Header closeButton>
            <h4>Reload Check</h4>
          </Modal.Header>
          <Modal.Body>
            You are about to Reload Check, Press Yes to Continue
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleReloadCheckConfirmationYesButton}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setReloadCheckConfirmationModel(false);
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        </ZIndexContext.Provider>
        <ZIndexContext.Provider value={10002}>
        <Modal
          show={deleteCheckConfirmation}
          onHide={() => {
            setDeleteCheckConfirmationModel(false);
          }}
        >
          <Modal.Header closeButton>
            <h4>Delete Check</h4>
          </Modal.Header>
          <Modal.Body>Do you want to delete selected checks?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleDeleteCheckConfirmationYesButton}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteCheckConfirmationModel(false);
              }}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        </ZIndexContext.Provider>
        </div>
    </React.Fragment>

    
  );
}
