import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";

import { IsDataDateType } from "../../../helpers/DateHelpers";

import { Grid, GridToolbar } from "@progress/kendo-react-grid";

import { getter } from "@progress/kendo-react-common";
import ServerGridExport from "../../export/ServerGridExport";

import { LoadingSmallControlSpinner } from "../../layout/LoadingSmallControlSpinner";

export default function SharedMainSelectableServerGrid(props) {
  const { styleOverride, pageSize = 50 } = props;

  const { oktaAuth } = useOktaAuth();

  const defaultButtonStyleClasses =
    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary";

  const INITIAL_MAIN_GRID_DATA_STATE = {
    skip: 0,
    take: 10,
    sort: [],
    filter: null,
  };

  const SELECTED_FIELD = "SelectedId";
  const DATA_ITEM_KEY = props.dataItemKey;
  const idGetter = getter(DATA_ITEM_KEY);

  const gridButtons = props.gridButtons;

  const setServerGridParentData = props.setServerGridParentData;

  const gridErrorHandlerCallback = props.gridErrorHandlerCallback;

  const [serverGridData, setServerGridData] = useState([]);

  const [serverGridDataState, setServerGridDataState] = useState(
    INITIAL_MAIN_GRID_DATA_STATE
  );

  const [error, setError] = useState({});
  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [exportFormat, setExportFormat] = useState();
  const [fullExportData, setFullExportData] = useState();

  const [currentSubmittedDataParams, setCurrentSubmittedDataParams] =
    useState();

  const [selectedMainGridState, setSelectedMainGridState] = useState({});

  const [currentSubmittedFormData, setCurrentSubmittedFormData] = useState("");

  //This is the largest number of records that can be exported
  const maxExportRecordsCount = 100000;

  const exportReportOptions = {
    hasGenerateReportOption: props.hasGenerateReportOption,
    generateReportButtonTitle: props.generateReportButtonTitle,
    generateReportForSelectablesCallback:
      props.generateReportForSelectablesCallback,
  };

  const apiCallSourceData = {
    apiGetEndpoint: props.apiGetEndpoint,
    apiSubmittedFormData: props.apiSubmittedFormData,
  };

  const _grid = React.useRef();

  const onItemClick = (event) => {
    exportReportOptions.generateReportForSelectablesCallback(
      //selectedMainGridState,
      event.item.text
    );
  };

  //If we have new submitted form data, then data is mopst likely not in cacheand will be slower, we want to set the isLoading to true
  useEffect(() => {
    //if isLoading is already true, and the currentSubmittedDataParams is the same as the new submitted data, then no need to do anything
    if (
      isLoading &&
      JSON.stringify(currentSubmittedDataParams) ===
        JSON.stringify(props.apiSubmittedFormData)
    ) {
      return;
    }

    setIsLoading(true);
    setCurrentSubmittedDataParams(apiCallSourceData.apiSubmittedFormData);
    getServerGridData();
  }, [props.apiSubmittedFormData]);
  // useMemo(() => {
  //   setIsLoading(true);
  //   getServerGridData();
  // }, [JSON.stringify(props.apiSubmittedFormData)]);

  //Here we are just changing the grid state (sortiong, filtering, paging). Data already pulled and likely in cache.
  //No need to set isLoading to true as we do not want flickers while sorting, filtering, paging
  useEffect(() => {
    getServerGridData();
  }, [serverGridDataState]);

  useEffect(() => {
    //we want fullExportData to only have data one time
    //so if it is not null, and has a length then set it to null

    if (fullExportData && fullExportData.length > 0) {
      setFullExportData(null);
    }
  }, [fullExportData]);

  function onServerGridSuccess(response) {
    //let columns = [];

    let data = response.data.gridData;

    if (data.length > 0) {
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }

    //process the dates to ensure they are date objects and can be formatted correctly
    //Logic will be check all records that have the word date in the header and if they are a string then convert them to a date object, then make sure it is a date object
    let dataWithCleanedDates = data.map((t) => {
      let cleanedData = { ...t };
      for (let key in cleanedData) {
        if (
          key.toLowerCase().includes("date") &&
          IsDataDateType(cleanedData[key])
        ) {
          cleanedData[key] = new Date(Date.parse(cleanedData[key]));
        }
      }
      return cleanedData;
    });

    setExportData(dataWithCleanedDates);

    if (_grid.current && _grid.current.columns)
      setExportColumns(_grid.current.columns);

    //set the total which is needed for paging
    dataWithCleanedDates.total = response.data.total;

    dataWithCleanedDates.fieldTotals = response.data.fieldTotals;
    setServerGridParentData(dataWithCleanedDates);
    setServerGridData(dataWithCleanedDates);
    setIsLoading(false);
  }

  function onFailure(e) {
    setIsLoading(false);

    //If a callback is provided, then call it
    if (gridErrorHandlerCallback) gridErrorHandlerCallback(e);

    //console.log(error);
  }

  function getServerGridData() {
    try {
      //setIsLoading(true);

      let accessToken = oktaAuth.getAccessToken();

      //Start with the serverGridDataState and then add the form data to it
      //This way we can keep the serverGridDataState in sync with the grid
      //The formParameters will be used as a separate argument on the controller

      //Set the parameters to the serverGridDataState
      let parameters = serverGridDataState;

      //now go through the form data and add every propert to the parameters
      for (const [key, value] of Object.entries(
        apiCallSourceData.apiSubmittedFormData
      )) {
        parameters[key] = value;
      }

      axios
        .get(apiCallSourceData.apiGetEndpoint, {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onServerGridSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  const GetSelectedGridDataKeys = () => {
    var selectableKeys = Object.keys(selectedMainGridState);

    var selectedKeys = selectableKeys.filter(function (key) {
      return selectedMainGridState[key] === true;
    });

    return selectedKeys;
  };

  const ShouldDisableToolbarButton = (
    shouldDisableWhenNoRecordSelectedOption
  ) => {
    //if shouldDisableWhenNoRecordSelectedOption is not true, then no need to test for anything and njust return false
    if (shouldDisableWhenNoRecordSelectedOption !== true) return false;

    //now we need to run some tests to see if the button should be disabled
    //if any records are selected, then the button should be enabled

    var selectedKeys = GetSelectedGridDataKeys();

    //if there are no selected keys, then the button should be disabled
    if (selectedKeys.length === 0) return true;

    return false;
  };

  const onServerGridDataStateChange = (e) => {
    setServerGridDataState(e.dataState);
  };

  function onFullExportServerGridSuccess(response) {
    let data = response.data.gridData;

    let dataWithCleanedDates = data.map((t) => {
      let cleanedData = { ...t };
      for (let key in cleanedData) {
        if (
          key.toLowerCase().includes("date") &&
          IsDataDateType(cleanedData[key])
        ) {
          cleanedData[key] = new Date(Date.parse(cleanedData[key]));
        }
      }
      return cleanedData;
    });

    setFullExportData(dataWithCleanedDates);
  }

  function getFullExportServerGridData() {
    try {
      //setIsLoading(true);

      let accessToken = oktaAuth.getAccessToken();

      //Start with the serverGridDataState and then add the form data to it
      //This way we can keep the serverGridDataState in sync with the grid
      //The formParameters will be used as a separate argument on the controller

      //Set the parameters to the serverGridDataState
      let parameters = { ...serverGridDataState };

      //set the pagesizde to a large number to get all the data
      parameters.take = maxExportRecordsCount;
      //Also make sure no records are skipped for the export
      parameters.skip = 0;

      //now go through the form data and add every propert to the parameters
      for (const [key, value] of Object.entries(
        apiCallSourceData.apiSubmittedFormData
      )) {
        parameters[key] = value;
      }

      axios
        .get(apiCallSourceData.apiGetEndpoint, {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onFullExportServerGridSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function getAllExportDataCallback(exportFormat) {
    setExportFormat(exportFormat);
    getFullExportServerGridData();
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSmallControlSpinner />
      ) : (
        <React.Fragment>
          <ServerGridExport
            exportData={exportData}
            exportDataColumns={exportColumns}
            exportFileNamePrefix={props.exportFileNamePrefix}
            getAllExportDataCallback={getAllExportDataCallback}
            exportFormat={exportFormat}
            realFullExportData={fullExportData}
          />

          <Grid
            ref={_grid}
            data={serverGridData}
            pageable
            sortable
            filterable
            {...serverGridDataState}
            onDataStateChange={onServerGridDataStateChange}
            total={serverGridData.total}
          >
            <GridToolbar>
              {gridButtons &&
                gridButtons.map((thisButton) => (
                  <button
                    key={thisButton.text
                      .replace(/\s+/g, "")
                      .trim()
                      .toLowerCase()}
                    title={thisButton.text}
                    className={
                      thisButton.styleOverride || defaultButtonStyleClasses
                    }
                    disabled={ShouldDisableToolbarButton(
                      thisButton.disableWhenNoRecordSelected
                    )}
                    onClick={() => {
                      thisButton.callback(GetSelectedGridDataKeys());
                    }}
                  >
                    {thisButton.text}
                  </button>
                ))}
            </GridToolbar>
            {props.children}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

//This method takes in a currentArray, buttonText, and buttonCallback and returns a new array with the button added to the end of the currentArray
export function AddGridButton(
  currentArray,
  buttonText,
  buttonCallback,
  disableWhenNoRecordSelected = true,
  styleOverride = null
) {
  let newArrayItem = {
    text: buttonText,
    callback: buttonCallback,
    disableWhenNoRecordSelected: disableWhenNoRecordSelected,
    styleOverride: styleOverride,
  };

  let newArray = [...currentArray, newArrayItem];

  return newArray;
}
