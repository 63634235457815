import React, { useState } from "react";
import PropTypes from 'prop-types'

import HeaderCell from './HeaderCell'
import SelectableHeaderCell from './SelectableHeaderCell'
import './HeaderRow.css'

export default function HeaderRow(props) {
    const { columns,
        handleColumnSort,
        scrollOffset,
        selectableRows,
        allRowsSelected,
        handleAllRowsSelected
    } = props

    const [isSorted, setIsSorted] = useState(false)
    const [isDesc, setIsDesc] = useState(false)
    const [sortedColumn, setSortedColumn] = useState('')

    function handleCellClick(column, type) {

        if (sortedColumn === column) {
            if (isDesc) {
                setIsDesc(false)
                setIsSorted(false)
                handleColumnSort(column, false, false, type)

            } else if (isSorted) {
                setIsDesc(true)
                handleColumnSort(column, isSorted, true, type)

            } else {
                setIsSorted(true)
                handleColumnSort(column, true, false, type)
            }
        } else {
            setSortedColumn(column)
            setIsSorted(true)
            setIsDesc(false)
            handleColumnSort(column, true, false, type)
        }
        
    }

    return (
        <div className='header-row'>
            <SelectableHeaderCell
                show={selectableRows}
                leftOffset={scrollOffset || 0}
                selected={allRowsSelected}
                handleAllRowsSelected={handleAllRowsSelected}
            />
            {columns.map((column) => {
                return (
                    <HeaderCell
                        key={column.accessor}
                        accessor={column.accessor}
                        title={column.title}
                        type={column.type}
                        sortedColumn={sortedColumn}
                        sorted={sortedColumn === column.accessor ? isSorted : false}
                        desc={sortedColumn === column.accessor ? isDesc : false}
                        handleCellClick={handleCellClick}
                        leftOffset={column.fixedLeft ? scrollOffset : 0}
                        headerInfo={column.headerInfo}
                        hidden={column.hidden}
                    />
                )
            })}
        </div>
    )
}

const columnExact = {
    accessor: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'checkbox', 'date', 'custom']).isRequired,
    title: PropTypes.string.isRequired,
    fixedLeft: PropTypes.bool.isRequired,
    headerInfo: PropTypes.string,
    hidden: PropTypes.bool
}

HeaderRow.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
    scrollOffset: PropTypes.number,
    handleColumnSort: PropTypes.func.isRequired,
}