import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy, orderBy } from "@progress/kendo-react-data-tools";
import React, { useState, useEffect, useContext } from "react";

export default function KendoDropdown(props) {
    const { dataSource, textField, idField, onChange, value, sortBy } = props;
    const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
    const inititalSort = [{ field: textField, dir: sortBy }];

    const filterChange = (e) => {
        if (e.filter.value.length > 2) {
            var data = dataSource.slice()
            var result = filterBy(data, [e.filter])
            setFilteredDataSource(result);
        }
        else {
            setFilteredDataSource(dataSource.slice());
        }
    }

    const onChangeEvent = (e) => {
        if (e.target.value) {
            onChange(e.target.value);
        } else {
            onChange(null);
        }
    }

    useEffect(() => {
        if (value == null || value == '') {
            setFilteredDataSource(dataSource.slice());
        }
    }, [value]);

    useEffect(() => {
        if (dataSource.length > 0) {
            if (sortBy == 'asc' || sortBy == 'desc') {
                setFilteredDataSource(orderBy(dataSource, inititalSort));
            }
            else {
                setFilteredDataSource(dataSource.slice());
            }
        }
    }, [dataSource]);
    
    return (
        <ComboBox
            data={filteredDataSource}
            id={idField}
            textField={textField}
            filterable={true}
            value={value}
            onFilterChange={filterChange}
            onChange={onChangeEvent}
        >
        </ComboBox>)
}