import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${process.env.REACT_APP_ApplicationInsightsKey}`,
  },
});
appInsights.loadAppInsights();

//Identify the user logs are being written for
function SetAuthenticatedUser(userId) {
  appInsights.setAuthenticatedUserContext(userId);
}

function LogTrace(message, properties, severityLevel = 1) {
  appInsights.trackTrace({ message, properties, severityLevel });
}

function LogError(message, properties, severityLevel = 1) {
  appInsights.trackException({
    exception: message,
    properties,
    severityLevel,
  });
}

export {
  appInsights as ApplicationInsights,
  SetAuthenticatedUser,
  LogTrace,
  LogError,
};
