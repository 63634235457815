const GetExportFileNameDated = (prefix, fileExtension) => {
  const now = new Date();
  const dateTimeString = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}_${now.getHours()}${now.getMinutes()}`;

  return `${prefix}_${dateTimeString}.${fileExtension}`;
};

export { GetExportFileNameDated };
