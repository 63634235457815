import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types'

import Row from './Row'
import ActionBar from './ActionBar'
import DataTableHead from './DataTableHead'
import DataTableSorter from './DataTableSorter'
import TotalFooter from './TotalFooter'
import Paging from './Paging'
import './DataTable.css'

export default function DataTable(props) {
    const {
        columns,
        rows,
        customExport = null,
        exportable = false,
        exportOptions = [`xlsx`],
        filterable = false,
        totalFooter = false,
        pagination = false,
        id,
        handleCellClick = () => null,
        handleCellEdit,
        selectableRows,
        handleRowSelected = () => null,
        customActionBarActions,
        handleActionBarStartAction = () => console.log("hmmm see the action bar start from the data table"),
        handleActionBarCompleteAction = () => null
    } = props

    const [sorted, setSorted] = useState(false)
    const [sortedColumn, setSortedColumn] = useState({})
    const [filtered, setFiltered] = useState(false)
    const [viewableRows, setViewableRows] = useState([])
    const [pageOfData, setPageOfData] = useState([])
    const [scrollOffset, setScrollOffset] = useState(0);
    const [headerColumnData, setheaderColumnData] = useState([])
    const [filterColumnData, setFilterColumnData] = useState([])
    const [rowColumnData, setRowColumnData] = useState([])
    const [totalFooterColumnData, setTotalFooterColumnData] = useState([])
    const [actionBarColumnData, setActionBarColumnData] = useState([])
    const [filters, setFilters] = useState([])
    const [allViewableRowsSelected, setAllViewableRowsSelected] = useState(false)

    useEffect(() => {
        if (columns.length > 0) {
            let normalizedColumns = columns.map(col => {
                return {
                    accessor: col.accessor,
                    title: col.title,
                    type: col.type || "text",
                    showTotal: col.showTotal || false,
                    fixedLeft: col.fixedLeft || false,
                    selectable: col.selectable || false,
                    truncatable: col.truncatable || false,
                    headerInfo: col.headerInfo,
                    editable: col.editable || false,
                    handleEdit: col.handleEdit,
                    isCellAlert: col.isCellAlert,
                    hidden: col.hidden || false
                }
            })
            setActionBarColumnData(getActionBarColumnData(normalizedColumns))
            setheaderColumnData(getHeaderColumnData(normalizedColumns))
            setFilterColumnData(getFilterColumnData(normalizedColumns))
            setRowColumnData(getRowColumnData(normalizedColumns))
            setTotalFooterColumnData(getTotalFooterColumnData(normalizedColumns))
        }
    }, [columns])

    useEffect(() => {
        if (columns.length > 0) {
            if (filtered) handleFilterChange(filters)
            if (sorted && !filtered) handleColumnSort(sortedColumn.column, sorted, sortedColumn.isDesc, sortedColumn.type)
        }
    }, [columns, rows])

    useEffect(() => {
        if (selectableRows) {
            if (pageOfData.length > 0) {
                let allRowsSelected = pageOfData.every(x => {
                    return x.selected === true
                })
                setAllViewableRowsSelected(allRowsSelected)
            }
        }
    }, [pageOfData])

    function getActionBarColumnData(columns) {
        return columns.map(x => ({
            accessor: x.accessor,
            type: x.type,
            title: x.title,
            showTotal: x.showTotal,
            selectable: x.selectable,
            hidden: x.hidden
        }))
    }

    function getHeaderColumnData(columns) {
        return columns.map(x => ({
            accessor: x.accessor,
            type: x.type,
            title: x.title,
            fixedLeft: x.fixedLeft,
            headerInfo: x.headerInfo,
            hidden: x.hidden
        }))
    }

    function getFilterColumnData(columns) {
        return columns.map(x => ({
            accessor: x.accessor,
            type: x.type,
            fixedLeft: x.fixedLeft,
            hidden: x.hidden
        }))
    }

    function getRowColumnData(columns) {
        return columns.map(x => ({
            accessor: x.accessor,
            type: x.type,
            fixedLeft: x.fixedLeft,
            selectable: x.selectable,
            truncatable: x.truncatable,
            editable: x.editable,
            handleEdit: x.handleEdit,
            isCellAlert: x.isCellAlert,
            hidden: x.hidden
        }))
    }

    function getTotalFooterColumnData(columns) {
        return columns.map(x => ({
            accessor: x.accessor,
            type: x.type,
            fixedLeft: x.fixedLeft,
            showTotal: x.showTotal,
            hidden: x.hidden
        }))
    }

    function handleColumnSort(column, isSorted, isDesc, type) {

        if (isSorted) {
            setSorted(isSorted)
            // Get sorter based on column type
            let sorter = DataTableSorter(type)

            // If table is already filtered we sort only filtered rows
            let data = filtered ? viewableRows.slice() : rows.slice()
            setViewableRows(data.sort(isDesc ? sorter.sortDescending(column) : sorter.sortAscending(column)))
        } else if (sorted) {
            setSorted(false)
        }

        // We set sorted column so we can sort on filter changes
        setSortedColumn({ column: column, isSorted: isSorted, isDesc: isDesc, type: type })
    }

    function handleFilterChange(filters) {
        // Check if any filters exist and set state
        let anyFilters = false;
        for (let key in filters) {
            if (filters[key].text !== '' || filters[key].text !== '$' || filters[key].text !== '%') {
                anyFilters = true;
                break;
            }
        }
        setFiltered(anyFilters)
        setFilters(filters)
        if (anyFilters) {
            let filteredRows = filterRows(filters)

            // if data was previously sorted, we sort the new filtered data
            if (sorted) {
                const { column, type, isDesc } = sortedColumn
                let sorter = DataTableSorter(type)

                let filteredAndSortedRows = filteredRows.sort(isDesc ? sorter.sortDescending(column) : sorter.sortAscending(column));
                setViewableRows(filteredAndSortedRows);
            } else {
                setViewableRows(filteredRows);
            }
        } else if(sorted){
            const { column, type, isDesc } = sortedColumn
            // if data was previously sorted, sort again
            let sorter = DataTableSorter(type)
            setViewableRows(rows.sort(isDesc ? sorter.sortDescending(column) : sorter.sortAscending(column)))
        }
    }

    function filterRows(filters) {
        console.log(filters)
        return rows.slice().filter(row => {
            for (var key in filters) {
                let filter = filters[key]
                let columnType = columns.find(x => x.accessor === key).type
                // We can immediately return false if one of the columns are filtered out
                switch (columnType) {
                    case 'number': {
                        let cellValue = Number((row[key] || 0).toString())

                        if (filter.type === 'contains' && !cellValue.toString().includes(filter.text)) {
                            return false
                        } else {
                            if (filter.type === '>' && cellValue <= filter.text) {
                                return false
                            } else if (filter.type === '<' && cellValue >= filter.text) {
                                return false
                            }
                        }
                        break;
                    }
                    case 'money': {
                        const stringOptions = {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }
                        let cellValue = (row[key] || 0).toLocaleString(undefined, stringOptions)

                        let filterValue = filter.text.replace("$", "")
                        if (filter.type === 'contains') {
                            cellValue = cellValue.replace(",", "");
                            filterValue = filterValue.replace(",", "");
                            if (!cellValue.includes(filterValue)) {
                                return false

                            }
                        } else {
                            filterValue = filterValue.toLocaleString(undefined, stringOptions)
                            let filterValueNum = Number(filterValue.replace(/[^0-9.-]+/g, ""))
                            let cellValueNum = Number(cellValue.replace(/[^0-9.-]+/g, ""))

                            if (filter.type === '>' && cellValueNum <= filterValueNum) {
                                return false
                            } else if (filter.type === '<' && cellValueNum >= filterValueNum) {
                                return false
                            }
                        }
                        break;
                    }
                    case 'percent': {
                        const stringOptions = {
                            style: 'percent',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }
                        let cellValue = row[key].toLocaleString(undefined, stringOptions)

                        let num = Number(filter.text.replace("%", ""))
                        num /= 100

                        let filterValue = num.toLocaleString(undefined, stringOptions)

                        if (filter.type === 'contains' && !cellValue.includes(filter.text.replace("%", ""))) {
                            return false
                        } else {

                            if (filter.type === '>' && cellValue <= filterValue) {
                                return false
                            } else if (filter.type === '<' && cellValue >= filterValue) {
                                return false
                            }
                        }
                        break;
                    }
                    default:
                        if (!row[key]) {
                            return false
                        } else if (!row[key].toLowerCase().includes(filter.text)) {
                            return false
                        }
                        break;
                }
            }
            return true
        })
    }

    function handleScroll() {
        let tableWrapper = document.getElementById(`${id}-data-table-wrapper`)
        setScrollOffset(tableWrapper.scrollLeft)
    }

    function handleAllRowsSelect() {
        handleRowSelected(pageOfData)
    }

    if (columns.length === 0 || rows.length === 0) return null

    let data = (sorted || filtered) ? viewableRows : rows

    return (
        <React.Fragment>        
            <ActionBar
                exportable={exportable}
                customExport={customExport}
                exportOptions={exportOptions}
                columns={actionBarColumnData}
                rows={data}
                customActions={customActionBarActions}
                handleActionBarStartAction={handleActionBarStartAction}
                handleActionBarCompleteAction={handleActionBarCompleteAction}
            />
            <div className='data-table-wrapper' id={`${id}-data-table-wrapper`} onScroll={handleScroll}>
                <div className="data-table" id={`${id}-data-table`}>
                    <DataTableHead
                        filterable={filterable}
                        headerColumnData={headerColumnData}
                        filterColumnData={filterColumnData}
                        tableXOffset={scrollOffset}
                        handleColumnSort={handleColumnSort}
                        handleFilterChange={handleFilterChange}
                        selectableRows={selectableRows}
                        allRowsSelected={allViewableRowsSelected}
                        handleAllRowsSelected={handleAllRowsSelect}
                />
                    <div className="data-table-body">
                        {pageOfData.map((row, index) => {
                            return (
                                <Row
                                    key={index}
                                    rowIndex={index}
                                    handleCellClick={(col) => handleCellClick(row, col)}
                                    scrollOffset={scrollOffset}
                                    row={row}
                                    columns={rowColumnData}
                                    handleEdit={(value, row, column) => handleCellEdit(value, row, column)}
                                    selectableRows={selectableRows}
                                    handleRowSelected={() => handleRowSelected([row])}
                                />
                            )
                        })}
                    </div>
                    <TotalFooter
                        show={totalFooter}
                        columns={totalFooterColumnData}
                        rows={data}
                        scrollOffset={scrollOffset}
                        selectableRows={selectableRows}
                    />
                </div>
            </div>
            <Paging show={pagination} rows={data} onPageChange={(pageOfData) => setPageOfData(pageOfData)} />
        </React.Fragment>
    )
}

DataTable.defaultProps = {
    columns: []
}
const columnExact = {
    accessor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'date', 'custom', 'checkbox']),
    showTotal: PropTypes.bool,
    fixedLeft: PropTypes.bool,
    headerInfo: PropTypes.string,
    selectable: PropTypes.bool,
    truncatable: PropTypes.bool,
    editable: PropTypes.bool,
    handleEdit: PropTypes.func,
    hidden: PropTypes.bool,
    isCellAlert: PropTypes.func
}

DataTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    exportable: PropTypes.bool,
    filterable: PropTypes.bool,
    totalFooter: PropTypes.bool,
    pagination: PropTypes.bool,
}