import React, { useState, useEffect, useContext } from "react";
import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import { UserContext } from "../../context/UserContext";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import KendoDropdown from "../../form/KendoDropdown";
export default function AdminRemittanceLookupForm(props) {
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedParentCompany, setSelectedParentCompany] = useState("");
    const [locationFormatedDataSource, setLocationFormatedDataSource] = useState(
        []
    );
    const [locationDataSource, setLocationDataSource] = useState([]);
    const [defaultSelected, setDefaultSelected] = useState(null);
    const [suppressedType, setSuppressedType] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [checkNumber, setCheckNumber] = useState("");
    const [checkDateType, setCheckDateType] = useState(1);
    const [checkAmount, setCheckAmount] = useState("");
    const [selectedPayer, setSelectedPayer] = useState("");
    const [fileName, setFileName] = useState("");
    const [enableButton, setEnableButton] = useState(props.enabledButton);
    const [payerDataSource, setPayerDataSource] = useState([]);

    const userContext = useContext(UserContext);
    const parentCompanyOrganizations = userContext.organizations
        ? userContext.organizations.filter((org) => org.type === "ParentCompany")
        : [];
    const location = useLocation();

    useEffect(() => {
        getPayer();
    }, []);

    useEffect(() => {
        getUserLocation();
    }, [selectedParentCompany, userContext.isUnrestrictedEraPath]);

    useEffect(() => {
        setEnableButton(props.enabledButton);
    }, [props.enabledButton]);

    function clearform() {
        setCheckAmount("");
        setCheckNumber("");
        setCheckDateType(1);
        setEndDate("");
        setFileName("");
        setStartDate("");
        setSelectedLocations("");
        setSelectedParentCompany("");
        setSelectedPayer("");
        setSuppressedType(0);
    }

    function resetOnClick() {
        clearform();
        props.handleFormReset();
    }
    function reloadSelectedOnClick() {
        props.handleForReloadCheck();
    }
    function deleteSelectedOnClick() {
        props.handleForDeleteCheck();
    }
    function suppressButtonOnClick() {
        alert("Suppress button clicked.");
    }

    async function getPayer() {
        setPayerDataSource([]);
        let parameters = {
            email: userContext.email
        };
        let accessToken = props.passedProps.auth.getAccessToken();
        let currentRoute = location.pathname;
        let header = {
            Authorization: `Bearer ${accessToken}`
        };

        let payerResponse = await axios.get(
            `api/UserProgram/GetAllPayers`,
            {
                params: parameters,
                headers: header,
            }
        );

        let PayerList = Object.keys(payerResponse.data).map((key) => {
            return {
                label: payerResponse.data[key],
                value: key,
            };
        });

        let sortedPayers = PayerList.sort((p1, p2) =>
            p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
        );

        setPayerDataSource(sortedPayers);

    }

    function validateForm() {
        var parentCompany = selectedParentCompany
            ? selectedParentCompany.value
            : "";

        if (
            parentCompany == "" &&
            fileName == "" &&
            checkNumber == "" &&
            checkAmount == ""
        ) {
            return "Please enter either Parent Company or Check No. or Check Amount or Filename.";
        }

        if (
            parentCompany == "" &&
            fileName == "" &&
            checkNumber == "" &&
            isNaN(checkAmount)
        ) {
            return "Please enter either Parent Company or Check No. or Check Amount or Filename.";
        }

        if (parentCompany != "") {
            if (startDate == "" || endDate == "") {
                return "Please enter start and end date.";
            }
            var startDateval = new Date(startDate);
            var endDateval = new Date(endDate);
            if (startDateval > endDateval) {
                return "Start date should not greater than end date.";
            }

            var differentTime = endDateval.getTime() - startDateval.getTime();
            var differentDays = differentTime / (1000 * 60 * 60 * 24);
            if (differentDays > (365 * 2)) {
                return "Date range should not be more than 2 years.";
            }
        }
    }

    async function getUserLocation() {
        setLocationFormatedDataSource([])
        if (selectedParentCompany) {
            if (selectedParentCompany.value != undefined) {
                let allLocationsParameters = {
                    email: userContext.email,
                    selectedItem: selectedParentCompany.value,
                    organizationType: 'ParentCompany',
                    programSubscriptions: 'Rec2.0'
                };
                let accessToken = props.passedProps.auth.getAccessToken();
                let allLocationsResponse = await axios.get(
                    `api/UserProgram/GetUserLocations`,
                    {
                        params: allLocationsParameters,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            RequestSrc: '/',
                        },
                    }
                );
                let allLocations = formatDictionaryToArray(allLocationsResponse.data);
                setLocationFormatedDataSource(allLocations);
            }
        }
    }

    function formatDictionaryToArray(data) {
        return Object.keys(data).map((key) => {
            var ncpdptext = data[key];
            var matchPattern = /\((.*)\)/i;
            ncpdptext = ncpdptext.match(matchPattern)[1];

            return {
                label: ncpdptext,
                value: ncpdptext,
            };
        });
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        let errors = validateForm();
        errors ? alert(errors) : submitForm();
    }

    function submitForm() {
        props.handleFormSubmit({
            checkAmount: (checkAmount == "" || isNaN(checkAmount)) ? null : checkAmount,
            fileName: fileName,
            checkNumber: checkNumber,
            endDate: endDate == "" ? null : endDate,
            selectedParentCompany: selectedParentCompany
                ? selectedParentCompany.value
                : null,
            selectedLocations: selectedLocations ? selectedLocations.value : null,
            selectedPayers: selectedPayer ? selectedPayer.value : null,
            startDate: startDate == "" ? null : startDate,
            suppressTypeId: suppressedType,
            dateTypeId: checkDateType,
            fileName: fileName,
        });
    }
    return (
        <Form onSubmit={handleFormSubmit}>
            {" "}
            <Row className="justify-content-start">
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Parent Company</Form.Label>

                    <KendoDropdown
                        dataSource={parentCompanyOrganizations || []}
                        textField="labelNoSuffix"
                        idField="value"
                        value={selectedParentCompany}
                        sortBy="asc"
                        onChange={(e) => {
                            setSelectedParentCompany(e);
                        }}
                    />
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Location (NCPDP)</Form.Label>

                    <KendoDropdown
                        dataSource={locationFormatedDataSource || []}
                        textField="label"
                        idField="value"
                        value={selectedLocations}
                        onChange={(e) => {
                            setSelectedLocations(e);
                        }}
                    />
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Suppressed</Form.Label>
                    <Form.Select
                        area-label="Select"
                        value={suppressedType}
                        onChange={(e) => {
                            setSuppressedType(e.target.value);
                        }}
                    >
                        <option value="0">ALL</option>
                        <option value="1">Suppressed</option>
                        <option value="2">Unsuppressed</option>
                    </Form.Select>
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                        name="startDate"
                        as={DatePicker}
                        selected={startDate}
                        onChange={(e) => {
                            setStartDate(e);
                        }}
                    />
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                        name="endDate"
                        as={DatePicker}
                        selected={endDate}
                        onChange={(e) => {
                            setEndDate(e);
                        }}
                    />
                </Col>
            </Row>
            <Row className="justify-content-start">
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Payer</Form.Label>

                    <KendoDropdown
                        dataSource={payerDataSource || []}
                        textField="label"
                        idField="value"
                        value={selectedPayer}
                        onChange={(e) => {
                            setSelectedPayer(e);
                        }}
                    />
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Check Number</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) => {
                            setCheckNumber(e.target.value);
                        }}
                        value={checkNumber}
                    />
                </Col>

                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Date Type</Form.Label>
                    <Form.Select
                        area-label="Select"
                        value={checkDateType}
                        onChange={(e) => {
                            setCheckDateType(e.target.value);
                        }}
                    >
                        <option value="1">Check Date</option>
                        <option value="2">Remit Date</option>
                        <option value="3">Archive Date</option>
                        <option value="4">Payment Date</option>
                        <option value="5">Export Date</option>
                    </Form.Select>
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>Check Amount</Form.Label>
                    <Form.Control
                        type="number"
                        onBlur={(e) => {
                            setCheckAmount(parseFloat(checkAmount).toFixed(2));
                        }}
                        onChange={(e) => {
                            setCheckAmount(e.target.value);
                        }}
                        value={checkAmount}
                    />
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Form.Label>File Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder=""
                        onChange={(e) => {
                            setFileName(e.target.value);
                        }}
                        value={fileName}
                    />
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Button className="inline-show-result-button" size="sm" type="submit">
                        Search
                    </Button>
                </Col>
                <Col md="auto" className="d-flex flex-column">
                    <Button
                        className="inline-show-result-button"
                        size="sm"
                        type="button"
                        onClick={resetOnClick}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
            {enableButton ? (
                <Row className="justify-content-start">
                    <Col md="auto" className="d-flex flex-column">
                        <Button
                            className="inline-show-result-button"
                            size="sm"
                            type="button"
                            onClick={reloadSelectedOnClick}
                        >
                            Reload Selected
                        </Button>
                    </Col>
                    <Col md="auto" className="d-flex flex-column">
                        <Button
                            className="inline-show-result-button"
                            size="sm"
                            type="button"
                            onClick={deleteSelectedOnClick}
                        >
                            Delete Selected
                        </Button>
                    </Col>
                    {/* <Col md="auto" className="d-flex flex-column">
                        <Button
                            className="inline-show-result-button"
                            size="sm"
                            type="button"
                            onClick={suppressButtonOnClick}
                        >
                            Suppress/UnSuppress
                        </Button>
                    </Col> */}
                </Row>
            ) : (
                ""
            )}
        </Form>
    );
}
