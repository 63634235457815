
/*
NOTE: The column width needs to be at least 250px in most cases
because we need to accommodate the width of filter box and icons.

*/
function DefaultColumnWidth() {
    return 165;
}

function DefaultDateColumnWidth() {
    return 145;
}

function DefaultBooleanOrShortWidthColumnWidth() {
    return 100;
}

function DefaultWideColumnWidth() {
    return 200;
}

function DefaultNoFilterColumnWidth() {
    return 100;
}

function DefaultNoFilterCheckBoxColumnWidth() {
    return 60;
}

export {DefaultColumnWidth, DefaultWideColumnWidth, DefaultDateColumnWidth, DefaultBooleanOrShortWidthColumnWidth, DefaultNoFilterColumnWidth,DefaultNoFilterCheckBoxColumnWidth};