export const FILTER_OPERATOR = {
    'numeric': [
        { text: 'grid.filterGteOperator', operator: "gte" },
        { text: 'grid.filterGtOperator', operator: "gt" },
        { text: 'grid.filterLtOperator', operator: "lt" },
        { text: 'grid.filterLteOperator', operator: "lte" },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterIsNullOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
    ],
    'text': [
        { text: 'grid.filterContainsOperator', operator: 'contains' },
        { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
        { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
    ],
    'date': [
        { text: 'grid.filterAfterOperator', operator: 'gt' },
        { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
        { text: 'grid.filterBeforeOperator', operator: 'lt' },
        { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterIsEmptyOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotnull' }
    ],
    'boolean': [
        { text: 'grid.filterEqOperator', operator: 'eq' }]

}