import React, { useState, useEffect, useReducer, Fragment } from 'react';

import { Form, Col, Row, Button } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import QueryBuilderSelection from './QueryBuilderSelection';
import './QueryBuilder.css';

const queryBuilderBodyState = {
    rows: [
        {
            id: "alpha",
            type: null,
            options: null
        }
    ],
    incrementingRowId: 0
};

//The reducer should only reference the inputState
function reducer(inputState, action) {
    switch (action.type) {
        case 'addRow':
            if (inputState.rows.length >= 5) {
                alert("The maximum number of queries have been added to the QueryBuilder, no more queries may be added.");

                return {
                    ...inputState
                };
            }
            else {

                let newRowId = "newRow" + inputState.incrementingRowId;

                let newRows = inputState.rows;
                newRows.push(
                    {
                        id: newRowId,
                        type: null,
                        options: null
                    }
                );

                action.updateOptionsOnForm(newRows);

                return {
                    ...inputState,
                    incrementingRowId: inputState.incrementingRowId + 1,
                    rows: newRows
                };
            }
        case 'deleteRow':
            let rowsPostDelete = inputState.rows;

            for (let j = 0; j < rowsPostDelete.length; j++) {
                let currentRowForPostDelete = rowsPostDelete[j];

                if (currentRowForPostDelete.id === action.rowId) {
                    rowsPostDelete.splice(j, 1);
                }

            }

            action.updateOptionsOnForm(rowsPostDelete);

            return {
                ...inputState,
                rows: rowsPostDelete
            };
        case 'updateRow':
            let newRowValue = {
                id: action.rowId,
                type: action.selectedType,
                options: action.selectedOptions
            };

            let rowsPostUpdate = inputState.rows;

            for (let k = 0; k < rowsPostUpdate.length; k++) {
                let currentRowForUpdate = rowsPostUpdate[k];

                if (currentRowForUpdate.id === action.rowId) {
                    rowsPostUpdate.splice(k, 1, newRowValue);
                }

            }

            action.updateOptionsOnForm(rowsPostUpdate);

            return {
                ...inputState,
                rows: rowsPostUpdate
            };
        default:
            throw new Error();
    }
}

export default function QueryBuilderBody(props) {
    const { handleOptionChangeOnForm, optionsFromForm } = props;

    const [setStateFromInitial, dispatch] = useReducer(reducer, queryBuilderBodyState);

    function handleOptionChange(selectedType, selectedOptions, controlId) {
        dispatch({
            type: 'updateRow',
            updateOptionsOnForm: handleOptionChangeOnForm,
            rowId: controlId,
            selectedType: selectedType,
            selectedOptions: selectedOptions
        });
    }

    const isOnlyRow = queryBuilderBodyState.rows.length === 1;

    function determineOptionsToDisable(row) {

        let currentSelectedTypes = queryBuilderBodyState.rows.map(a => a.type);

        //NOTE: Remove the type selected on the dropdown itself
        for (let j = 0; j < currentSelectedTypes.length; j++) {
            let currentType = currentSelectedTypes[j];

            if (currentType === row.type) {
                currentSelectedTypes.splice(j, 1);
            }

        }

        let optionsToAllowMultiples = [];

        if (optionsFromForm) {
            optionsFromForm.forEach(option => {
                if (option.isMultipleAllowed) {
                    optionsToAllowMultiples.push(option.id);
                }
            });

        }

        //NOTE: Remove types where multiple options are allowed
        if (optionsToAllowMultiples.length > 0) {
            optionsToAllowMultiples.forEach(multipleOption => {
                while (currentSelectedTypes.indexOf(multipleOption) > -1) {
                    currentSelectedTypes.splice(currentSelectedTypes.indexOf(multipleOption), 1);
                }
            });

        }

        return currentSelectedTypes;
    }

    const listItems = queryBuilderBodyState.rows.map((individualRow, index) =>
        <Row className="justify-content-start reduce-vertical-margin" key={individualRow.id}>
            <Form.Group as={Col} xl="9" lg="11" md="12" className="d-flex flex-column reduced-vertical-margin">
                <Form.Control as={QueryBuilderSelection}
                    onQueryBuilderChange={handleOptionChange}
                    optionsFromForm={optionsFromForm}
                    controlIdFromParent={individualRow.id}
                    manageAddClick={() => dispatch({ type: 'addRow', updateOptionsOnForm: handleOptionChangeOnForm })}
                    manageDeleteClick={() => dispatch({ type: 'deleteRow', updateOptionsOnForm: handleOptionChangeOnForm, rowId: individualRow.id })}
                    shouldShowAddOption={index === queryBuilderBodyState.rows.length - 1}
                    shouldShowDeleteOption={!isOnlyRow}
                    externalDataReference={determineOptionsToDisable(individualRow)}
                />
            </Form.Group>
        </Row>
    );

    return (

        <React.Fragment>
            {listItems}
        </ React.Fragment>

    );
}
