import React, { Component } from 'react';

//NOTE: This is a copy of the Byod.js from the "Byod" folder, the only difference is that I removed unused import lines
export function RunAnalyticsTab(props) {

    const SisenseURL = process.env.REACT_APP_SisenseURI + '/app/main#/home?h=false'

    return (
        <div className="full-sisense-feature temp-larger-size">
            <iframe src={SisenseURL} id="sisense-frame" width="100%" height="100%" allowFullScreen={true}></iframe>
        </div>
    )

    //return (
    //    <div>
    //        <iframe className="responsive-iframe" src={SisenseURL} id="sisense-frame" width="100%" height="100%" allowFullScreen="true"></iframe>
    //    </div>
    //)


}
