import React, { useState, useRef } from "react";
import PropTypes from 'prop-types'

import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'

import classNames from 'classnames'


export default function FilterTypeDropdown(props) {
    const { type, selectedFilterType, handleFilterTypeChange } = props

    const [popup, setPopup] = useState(null);
    const [show, setShow] = useState(false);
    const ref = useRef(null);

    const filters = type === "text" ? ['contains'] : ['contains', '>', '<']

    function handleOverlayClick(event) {
        setShow(!show)
        setPopup(event.target);
    }

    function onFilterTypeChange(event) {
        setShow(false)
        handleFilterTypeChange(event.target.textContent)
    }

    return (
        <span>
            <i ref={ref} onClick={handleOverlayClick} className="filter-icon fa fa-filter" />
            <Overlay rootClose transition
                show={show}
                target={popup}
                placement="right"
                container={ref.current}
                onHide={() => setShow(false)}
            >
                <Popover id="popover-basic">
                    <Popover.Content>
                        <ul className='filter-type-list'>
                            {filters.map((filter, index) => {
                                let className = classNames({ "selected-filter-type": filter === selectedFilterType })
                                return (
                                    <li
                                        key={index}
                                        className={className}
                                        onClick={onFilterTypeChange}
                                    >
                                        {filter}
                                    </li>
                                )
                            })}
                        </ul>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </span>
    )
}

FilterTypeDropdown.propTypes = {
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'checkbox', 'date', 'custom']).isRequired,
    selectedFilterType: PropTypes.string.isRequired,
    handleFilterTypeChange: PropTypes.func.isRequired
}