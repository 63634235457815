import React, { useState, useEffect, useCallback } from "react";

import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";

import { DropDownButton } from "@progress/kendo-react-buttons";

import { process, aggregateBy } from "@progress/kendo-data-query";

import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getter } from "@progress/kendo-react-common";

import GridExport from "../../export/GridExport";

export default function SharedMainSelectableGrid(props) {
  const {
    styleOverride,
    pageSize = 50
  } = props;

  //NOTE: There is a way to set a title for the selectable checkbox column
  //but if that's done then it gets rid of the "Select All" checkbox in the header itself

  const INITIAL_MAIN_GRID_DATA_STATE = { skip: 0, take: pageSize };
  const SELECTED_FIELD = "SelectedId";
  const DATA_ITEM_KEY = props.dataItemKey;
  const idGetter = getter(DATA_ITEM_KEY);
  const selectableButtonTitle = props.selectableButtonTitle;
  const secondButtonTitle = props.secondButtonTitle;
  const hasSecondButtonEnable = props.hasSecondButtonEnable;

  const [mainGridData, setMainGridData] = useState(
    props.data ? props.data : []
  );
  const [mainGridDataState, setMainGridDataState] = useState(
    INITIAL_MAIN_GRID_DATA_STATE
  );
  const [mainGridResultState, setMainGridResultState] = useState(mainGridData);

  const [selectedMainGridState, setSelectedMainGridState] = useState({});

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);
  const [headerCheckBox, setHeaderCheckBox] = useState(false);

  const myCustomHeaderSelection = (props) => {
    return (
      <span>
        Build 835
      </span>
    )
  };

  const exportReportOptions = {
    hasGenerateReportOption: props.hasGenerateReportOption,
    generateReportButtonTitle: props.generateReportButtonTitle,
    generateReportForSelectablesCallback:
      props.generateReportForSelectablesCallback,
  };

  const _grid = React.useRef();
  const _mainExport = React.useRef(null);

  const handleSecondButtonClick = () => {
    if (secondButtonTitle === "Restore Pending Selected") {
      let processedData = process(
        mainGridData.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedMainGridState[idGetter(item)],
        })),
        mainGridDataState
      );
      var selectableKeys = {};

      for (var count = 0; count < processedData.data.length; count++) {
        if (selectedMainGridState[processedData.data[count].pmid] == true) {
          Object.assign(selectableKeys, { [processedData.data[count].pmid]: true });
        }
      }

      props.sendSecondButtonParentCallback(selectableKeys);
    } else {
      props.sendSecondButtonParentCallback(selectedMainGridState);
    }
  }
  const handleSelectedRows = () => {
    if (selectableButtonTitle === "Archive Selected") {
      let processedData = process(
        mainGridData.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedMainGridState[idGetter(item)],
        })),
        mainGridDataState
      );
      var selectableKeys = {};

      for (var count = 0; count < processedData.data.length; count++) {
        if (selectedMainGridState[processedData.data[count].pmid] == true) {
          Object.assign(selectableKeys, { [processedData.data[count].pmid]: true });
        }
      }

      props.sendSelectablesParentCallback(selectableKeys);
    } else {
      props.sendSelectablesParentCallback(selectedMainGridState);
    }
  };

  const onMainGridDataStateChange = (event) => {
    setMainGridDataState(event.dataState);
    let processedData = process(
      mainGridData.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedMainGridState[idGetter(item)],
      })),
      event.dataState
    );

    setMainGridResultState(processedData);
  };

  //NOTE: When a filter is applied, this useEffect hook will kick off to update the aggregates
  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = mainGridDataState.filter;
    let filteredMainGridData = process(mainGridData, filterSettings);

    if (filteredMainGridData.data && props.aggregateColumnSettings) {
      aggregates = aggregateBy(
        filteredMainGridData.data,
        props.aggregateColumnSettings
      );
    }

    props.sendAggregatesParentCallback(aggregates);

    setExportData(filteredMainGridData.data);

    if (_grid.current && _grid.current.columns)
      setExportColumns(_grid.current.columns);

    
    if(mainGridDataState.filter != undefined) {
      // Select  state after applying the filter 
      if(filteredMainGridData.data.length > 0) {
        const newSelectedState = {};
      filteredMainGridData.data.forEach((item) => {
        newSelectedState[idGetter(item)] = headerCheckBox;
      });
      setSelectedMainGridState(newSelectedState);}
    }
  }, [mainGridData, mainGridDataState.filter]);

  //NOTE: This is all for handling the selectable function
  useEffect(() => {
    let processedData = process(
      mainGridData.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedMainGridState[idGetter(item)],
      })),
      mainGridDataState
    );

    setMainGridResultState(processedData);
  }, [selectedMainGridState]);

  const onSelectionChange = useCallback(
    (event) => {
      // console.log(event);
      if (event.startColIndex == 0) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedMainGridState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedMainGridState(newSelectedState);
      }
    },
    [selectedMainGridState]
  );

  //NOTE: This runs when the data gets updated (either on initial page load or new data fetch)
  useEffect(() => {
    setSelectedMainGridState({});
    setMainGridData(props.data);
    let processedData = process(props.data, INITIAL_MAIN_GRID_DATA_STATE);
    setMainGridResultState(processedData);
    setMainGridDataState(INITIAL_MAIN_GRID_DATA_STATE);
  }, [props.data]);

  useEffect(() => {
    if (mainGridDataState.filter != undefined) {
        let filterSettings = {};
        filterSettings.filter = mainGridDataState.filter;
        let filteredMainGridData = process(mainGridData, filterSettings);
        var filteredDataSource = filteredMainGridData.data;
        setHeaderCheckBox(filteredDataSource.findIndex(
          (item) => !selectedMainGridState[idGetter(item)]
        ) === -1)
    } else {
      setHeaderCheckBox(mainGridData.findIndex(
          (item) => !selectedMainGridState[idGetter(item)]
        ) === -1)
      }
}, [selectedMainGridState]);

  const onHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;      
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      if (mainGridDataState.filter != undefined) {
        let filterSettings = {};
        filterSettings.filter = mainGridDataState.filter;
        let filteredMainGridData = process(mainGridData, filterSettings);
        var filteredDataSource = filteredMainGridData.data;
        if (filteredDataSource.length > 0) {
          filteredDataSource.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
          });
        }
      } else {
        if (mainGridData.length > 0) {
          mainGridData.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
          });
        }
      }
      setSelectedMainGridState(newSelectedState);
    },
    [selectedMainGridState, mainGridDataState]
  );

  const ExportReportsDropdownItems = [
    { text: "Excel", id: 1 },
    { text: "CSV", id: 2 },
  ];

  const onItemClick = (event) => {
    exportReportOptions.generateReportForSelectablesCallback(
      selectedMainGridState,
      event.item.text
    );
  };

  return (
    <React.Fragment>
      <ExcelExport ref={_mainExport}>
        <GridExport
          exportData={exportData}
          exportDataColumns={exportColumns}
          exportFileNamePrefix={props.exportFileNamePrefix}
        />

        <Grid
          style={styleOverride ? styleOverride : { height: "700px" }}
          ref={_grid}
          data={mainGridResultState}
          filterable={true}
          sortable={true}
          groupable={true}
          pageable={true}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          onDataStateChange={onMainGridDataStateChange}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          {...mainGridDataState}
        >
          <GridToolbar>
            <button
              title={selectableButtonTitle}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={handleSelectedRows}
            >
              {selectableButtonTitle}
            </button>
            {hasSecondButtonEnable && (<button
              title={secondButtonTitle}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={handleSecondButtonClick}
            >
              {secondButtonTitle}
            </button>)}
            {exportReportOptions.hasGenerateReportOption && (
              <DropDownButton
                text={exportReportOptions.generateReportButtonTitle}
                textField="text"
                items={ExportReportsDropdownItems}
                onItemClick={onItemClick}
                themeColor={"primary"}
              />
            )}
          </GridToolbar>
          <GridColumn
            field={SELECTED_FIELD}
            width={"50px"}
            // headerCell={myCustomHeaderSelection}
            // width={"100px"}
            filterable={false}
            headerSelectionValue={headerCheckBox}
          />

          {props.children}
        </Grid>
      </ExcelExport>
    </React.Fragment>
  );
}
