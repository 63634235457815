import React, { useState } from "react";
import PropTypes from 'prop-types'

import Form from 'react-bootstrap/Form'

import FilterTypeDropdown from './FilterTypeDropdown'
import './InlineFilterCell.css'

export default function InlineFilterCell(props) {
    const { column, type, handleFilterChange, leftOffset, hidden } = props

    const [text, setText] = useState('')
    const [filterType, setFilterType] = useState('contains')

    if (hidden) {
        return null;
    }

    function handleTextChange(e) {
        setText(e.target.value);
        handleFilterChange(column, e.target.value, filterType)
    }

    function handleFilterTypeChange(type) {
        setFilterType(type)
        handleFilterChange(column, text, filterType)
    }

    const styles = leftOffset > 0 ? {
        transform: `translate(${leftOffset}px, 0px)`,
        position: "relative",
        zIndex: 1
    } : null

    const content = type !== 'custom' && (
        <React.Fragment>
            <FilterTypeDropdown type={type} selectedFilterType={filterType} handleFilterTypeChange={handleFilterTypeChange} />
            <Form.Control className="filter-text-input" value={text} type="text" onChange={handleTextChange} />
        </React.Fragment>
    )
    return (
        <div className="inline-filter-cell" style={styles}>
            {content}
        </div>
    )
}

InlineFilterCell.propTypes = {
    column: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'checkbox', 'date', 'custom']).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    selectable: PropTypes.bool,
    truncatable: PropTypes.bool,
    leftOffset: PropTypes.number.isRequired
}
