import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import Accordion from '../../Accordion';
import TabBasedFeature from '../../layout/TabBasedFeature';
import { Overpayments } from './Overpayments';
import { ReversedClaims } from './ReversedClaims';
import { UnappliedCash } from './UnappliedCash';

export default function PaymentException(props) {

    const { passedProps } = props;

    let tabs = [
        { key: 'Overpayments', title: 'Overpayments', Component: Overpayments },
        { key: 'ReversedClaims', title: 'Reversed Claims', Component: ReversedClaims },
        { key: 'UnappliedCash', title: 'Unapplied Cash', Component: UnappliedCash },

    ]

    return (
        <TabBasedFeature tabs={tabs} defaultActiveKey="Overpayments" passedProps={passedProps} />
    );

}

