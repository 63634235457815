import React, { useMemo, useState, useRef, useEffect } from 'react';
import classNames from 'classnames'
import { Form, Col, Button, Overlay, Popover, OverlayTrigger } from 'react-bootstrap'
import './Cell.css'
import { Checkbox } from '../MultiSelectDropdown';
import DatePicker from 'react-datepicker';

export default function Cell(props) {
    const { type, leftOffset, hidden, ...other } = props;

    const styles = leftOffset > 0 ? {
        transform: `translate(${leftOffset}px, 0px)`,
        position: "relative",
        zIndex: 1,
        backgroundColor: "#F5F5F5"
    } : null

    function getCell() {
        if (hidden) {
            return null
        }
        switch (type) {
            case "money":
                return <MoneyCell styles={styles} {...other} />
            case "number":
                return <NumberCell styles={styles} {...other} />
            case "bool":
                return <BoolCell styles={styles}{...other} />
            case "percent":
                return <PercentCell styles={styles} {...other} />
            case "custom":
                return <CustomCell styles={styles} {...other} />
            case "date":
                return <DateCell styles={styles} {...other} />
            case "checkbox":
                return <CheckBoxCell styles={styles} {...other} />
            default:
                return <TextCell styles={styles} {...other} />
        }
    }

    return getCell()
}

function TextCell(props) {
    const { content, styles, handleCellClick, selectable, truncatable, accessor, alert } = props

    let className = classNames('cell', { 'cell--selectable': selectable }, { 'cell--truncatable': truncatable }, { "cell--alert": alert })

    //NOTE: This is to handle cases where a "column with only numeric values" is being treated as a text column
    const value = content ? content.toString().toUpperCase() : ''

    const cellContent = (
        <div style={styles} className={className} onClick={() => handleCellClick(accessor)}>{value}</div>
    );

    const popover = (
        <Popover id="selectableCell">
            <Popover.Content>{value}</Popover.Content>
        </Popover>
    );

    return (truncatable && content) ? (
        <OverlayTrigger rootClose={true} trigger="click" placement="top" overlay={popover}>{cellContent}</OverlayTrigger>
    ) : (cellContent);
}

function MoneyCell(props) {
    const { content, styles, handleCellClick, selectable, accessor, alert } = props
    let num = content || 0
    let isNegative = content < 0
    let className = classNames('money', { 'negative': isNegative }, { 'cell--selectable': selectable }, { "cell--alert": alert })

    if (isNegative) {
        num = Math.abs(num)
    }

    let value = num.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    let whitespace = '';

    if (value.length < 12) {
        whitespace = '\xa0'.repeat(12 - value.length)
    }
    return (
        <div style={styles} className='cell' onClick={() => handleCellClick(accessor)}>
            <pre>
                {whitespace}
            </pre>
            <span className={className}>
                {value}
            </span>
        </div>
    )
}

function NumberCell(props) {
    const { content, styles, handleCellClick, alert, accessor } = props

    let num = content || 0
    let isNegative = content < 0
    let className = classNames('cell', 'number', { 'negative': isNegative }, { "cell--alert": alert })

    if (isNegative) {
        num = Math.abs(num)
    }

    let value = num.toLocaleString()
    let whitespace = '';

    if (value.length < 12) {
        whitespace = '\xa0'.repeat(12 - value.length)
    }
    return (
        <div style={styles} className={className} onClick={() => handleCellClick(accessor)}>
            <pre>
                {whitespace}
            </pre>
            {value}
        </div>
    )
}

function BoolCell(props) {
    const { content, styles, handleCellClick, accessor } = props
    let className = classNames('cell', 'cell--bool--' + (content ? 'true' : 'false'))

    return (
        <div style={styles} className={className} onClick={() => handleCellClick(accessor)}>
            <i className={content ? 'fa fa-check-circle' : 'fa fa-times-circle'} />
        </div>
    )
}

function PercentCell(props) {
    const { content, styles, handleCellClick, accessor, alert } = props

    let num = content
    let isNegative = content < 0
    let className = classNames('cell', 'percent', { 'negative': isNegative }, { "cell--alert": alert })
    return (
        <div style={styles} className={className} onClick={() => handleCellClick(accessor)}>
            {num.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </div>
    )
}

function CheckBoxCell(props) {
    const { content, styles, handleCellClick, key, accessor } = props
    return (
        <div style={styles} className='cell'>
            < Form.Check id={key} type="checkbox" name="select" checked={content} onChange={() => handleCellClick(accessor)} />
        </div>
    )
}

function CustomCell(props) {
    const { styles, handleCellClick, content } = props

    //NOTE: Here, we are cloning the "content" and adding an "onClick" function, which in this case is " (type) => handleCellClick(type) "
    var contentWithOnClick = React.cloneElement(content, { onClick: (customInput) => handleCellClick(customInput) });
    return (
        <div style={styles} className="cell">
            {contentWithOnClick}
        </div>
    )
}

function DateCell(props) {
    const { content, styles, handleCellClick, editable, handleEdit, accessor } = props

    const [popup, setPopup] = useState(null);
    const [show, setShow] = useState(false);
    const ref = useRef(null);

    function handleOverlayClick(event) {
        setShow(!show)
        setPopup(event.target);
    }

    function handleDateChange(date) {
        handleEdit(date)
        setShow(!show)
    }

    function onClear() {
        handleEdit(null)
        setShow(!show);
    }

    let displayValue = content === null ? '' : new Date(content).toLocaleDateString()

    return editable ? (
        <div ref={ref} style={styles} className="cell">
            {displayValue}
            <button onClick={handleOverlayClick} className="datepicker-icon fa fa-calendar " />
            <Overlay rootClose show={show} target={popup} placement="right" container={ref.current} transition onHide={() => setShow(false)}>
                <Popover>
                    <Popover.Content>
                        <DatePicker name="Date" selected={content ? new Date(content) : new Date()} onChange={(date) => handleDateChange(date)} inline />
                        {content ? <Button variant="outline-primary" onClick={onClear} block>Clear</Button> : null}
                    </Popover.Content>
                </Popover>
            </Overlay>

        </div>
    ) : (
            <div style={styles} className="cell" onClick={() => handleCellClick(accessor)}>
                {content === null ? '' : new Date(content).toLocaleDateString()}
            </div>
        )
}