import React from "react";

import MultiSelectDropdownOption from './MultiSelectDropdownOption'

export function MultiSelectDropdownAllOption(props) {
    const { show, allSelected, handleAllSelected } = props
    return show ? (
        <MultiSelectDropdownOption
            selected={allSelected}
            label='All'
            value='All'
            handleOptionClick={handleAllSelected}
        />) : null
}