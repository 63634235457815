function FormatToCurrency(input) {
    const formattedNumber = (input !== null && input !== undefined) &&
        input.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });

    return formattedNumber;
}

export { FormatToCurrency };
