import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormValidation from "../../form/validation/FormValidation";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import { constants } from "./DSOByPayerTrackingConstants";
import { UserContext } from "../../context/UserContext";

export function DSOByPayerTrackingForm(props) {
  const [fromDate, setFromDate] = useState(getFromDate());
  const [toDate, setToDate] = useState(new Date());
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    setLoaded(false);
  }, [
    userContext.locations,
    userContext.metricLocations,
    userContext.recLocations,
    userContext.isUnrestrictedEraPath,
  ]);
  
  useEffect(() => {
    if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations, selectedPayers]);

  function getFromDate() {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    return new Date(date);
  }

  function submitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      selectedLocations: selectedLocations,
      selectedPayers: selectedPayers,
      requiredFields: ["fromDate", "toDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        toDate: toDate.toLocaleDateString(),
        fromDate: fromDate.toLocaleDateString(),
        selectedLocation: selectedLocations.join(";"),
        selectedPayer: selectedPayers.join(";"),
      });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  const popover = <Popover id="popover-basic">{constants.featureInfo}</Popover>;
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-start">
        <Col
          md="auto"
          className="d-flex flex-column"
          controlid="formGridFromDate"
        >
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </Col>
        <Col
          md="auto"
          className="d-flex flex-column"
          controlid="formGridToDate"
        >
          <Form.Label>End Date</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Col>
        <Col md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={"Rec2.0"}
          />
        </Col>
        <Col
          md="auto"
          className="d-flex flex-column"
          controlid="formGridToDate"
        >
          <div className="mb-3 mr-2">
            <Button
              className="inline-show-result-button"
              size="sm"
              type="submit"
            >
              Show Results
            </Button>

            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose
            >
              <i className="additional-info-popover-icon fa fa-info-circle" />
            </OverlayTrigger>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

DSOByPayerTrackingForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
