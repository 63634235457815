import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import axios from "axios";
import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { UserContext } from "../../context/UserContext";

import { useParentCompanyLocationValidation } from "../../hooks/useParentCompanyLocationValidation";

import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";
import { StringToDate, DateWithoutTime } from "../../../helpers/DateHelpers";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";

import {
  ApplicationInsights,
  LogError,
} from "../../../../src/components/AppInsights/TelemetryService";

import {
  DefaultColumnWidth,
  DefaultDateColumnWidth,
  DefaultNoFilterColumnWidth,
} from "../../grid/Kendo/KendoGridAndColumnConstants";

export default function ExcelExportDownloads(props) {
  const { passedProps } = props;
  const [rowKey, setRowKey] = useState("");
  const userContext = useContext(UserContext);

  const [requestsData, setRequestsData] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const { currentLocation } = useParentCompanyLocationValidation();

  useEffect(() => {
    //NOTE: Run this once initially
    if (userContext.email && requestsData.length == 0 && !loaded) {
      setLoaded(true);
      checkForExportRequests();
    }
  }),
    [userContext.email];

  function onFailure(error) {
    LogError(error);
    return;
  }

  async function checkForExportRequests() {
    let accessToken = passedProps.auth.getAccessToken();

    await axios
      .get("api/File/GetAllRequestIds", {
        params: {
          email: userContext.email,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(onSuccess)
      .catch(onFailure);
  }

  async function downloadWithRowKey(rowKey) {
    let accessToken = passedProps.auth.getAccessToken();

    await axios
      .get("api/File/GetExcelExportAsString", {
        params: {
          email: userContext.email,
          rowKey: rowKey,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(onSuccess)
      .catch(onFailure);
  }

  function convertReportParametersToFriendlyString(parametersObject) {
    var finalStringOutput = "";

    for (const [key, value] of Object.entries(parametersObject)) {
      if (key !== "User") {
        finalStringOutput = finalStringOutput + `${key}: ${value}` + ", ";
      }
    }

    finalStringOutput = finalStringOutput.slice(0, -2);

    return finalStringOutput;
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/File/GetAllRequestIds":
        if (response.data) {
          let dataWithCleanedDates = response.data.map((item) => {
            var reportParameters = JSON.parse(item.reportParameters);

            return {
              ...item,
              CleanedCompletedDate: DateWithoutTime(
                StringToDate(item.creationTimeUtc)
              ),
              CleanedDownloadedDate: IsNullOrWhitespace(item.downloadTimeUtc)
                ? ""
                : DateWithoutTime(StringToDate(item.downloadTimeUtc)),
              UserFriendlyParameters:
                convertReportParametersToFriendlyString(reportParameters),
            };
          });

          setRequestsData(dataWithCleanedDates);
          if (response.data.length > 0) {
            setRowKey(response.data[0].rowKey);
          } else {
          }
        }
        break;
      case "api/File/GetExcelExportAsString":
        if (response.data) {
          var dataAsArray = Base64ToArrayBuffer(response.data);
          SaveByteArray(
            "Report" + new Date().toLocaleString() + ".xlsx",
            dataAsArray
          );
        }
        //NOTE: Refetch the data for the grid
        checkForExportRequests();
        break;
      default:
        break;
    }
  }

  let DownloadDataCell = (props) => {
    let dataRow = props.dataItem;

    let downloadableStatuses = ["Completed", "Downloaded"];

    if (downloadableStatuses.indexOf(dataRow.requestStatus) > -1) {
      return (
        <td
          {...props.tdProps}
          colSpan={1}
          style={{
            color: "#015CAB",
          }}
        >
          <a
            style={{
              cursor: "pointer",
            }}
            onClick={() => downloadWithRowKey(dataRow.rowKey)}
          >
            Download
          </a>
        </td>
      );
    } else {
      return (
        <td {...props.tdProps} colSpan={1}>
          <a>Pending</a>
        </td>
      );
    }
  };

  useEffect(() => {
    ApplicationInsights.trackPageView({ name: "ExcelExportDownloads" });
  }, []);

  return (
    <div className="feature">
      <Row>
        <Col md={10} className="tight-grid multi-line-filter">
          <SharedMainGrid
            data={requestsData}
            aggregateColumnSettings={null}
            sendAggregatesParentCallback={() => {
              return null;
            }}
            styleOverride={{ minHeight: "19em", maxHeight: "50em" }}
            exportFileNamePrefix={"ExcelExportDownloads"}
          >
            <GridColumn
              key={""}
              field={""}
              title={"Download"}
              filterable={false}
              cells={{
                data: DownloadDataCell,
              }}
              width={DefaultNoFilterColumnWidth()}
            ></GridColumn>
            <GridColumn
              key={"reportName"}
              field={"reportName"}
              title={"Report Name"}
              width={DefaultColumnWidth()}
            ></GridColumn>
            <GridColumn
              key={"UserFriendlyParameters"}
              field={"UserFriendlyParameters"}
              title={"Report Parameters"}
              width={DefaultColumnWidth()}
            ></GridColumn>
            <GridColumn
              key={"requestStatus"}
              field={"requestStatus"}
              title={"Status"}
              width={DefaultColumnWidth()}
            ></GridColumn>
            <GridColumn
              key={"CleanedCompletedDate"}
              field={"CleanedCompletedDate"}
              filter="date"
              format="{0:d}"
              title={"Completed Date"}
              width={DefaultDateColumnWidth()}
            ></GridColumn>
            <GridColumn
              key={"CleanedDownloadedDate"}
              field={"CleanedDownloadedDate"}
              filter="date"
              format="{0:d}"
              title={"Download Date"}
              width={DefaultDateColumnWidth()}
            ></GridColumn>
          </SharedMainGrid>
        </Col>
      </Row>
    </div>
  );
}
