import React from "react";
import { Form, Col, Row, Button } from "react-bootstrap";


function SharedFormRow(props) {
    return(
        <Row className="justify-content-start" style={{ marginTop: "15px" }}>
            {props.children}
        </Row>
    )
};
function SharedFormColumn(props) {
    return(
        <Col md={props.columnSize ? props.columnSize : "auto"} className="d-flex flex-column">
            {props.children}
        </Col>
    )
};

export { SharedFormRow, SharedFormColumn };
