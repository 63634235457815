import React, { useState, useEffect } from "react";

import {
  Grid,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
import "font-awesome/css/font-awesome.min.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getter } from "@progress/kendo-react-common";
import { FILTER_OPERATOR } from "../../grid/Kendo/CustomGridFilterOperator";

//NOTE: Here we're defining just the common features on the grid;
//the GridColumns are still defined on the individual tabs themselves
export default function SharedPaymentExceptionGrid(props) {
  // console.log(props);
  const INITIAL_MAIN_GRID_DATA_STATE = {
    sort: [
      {
        dir: "desc",
        field: "totalDifference",
      },
    ],
    skip: 0,
    take: 50,
  };

  const [mainGridData, setMainGridData] = useState(
    props.data ? props.data : []
  );
  const [mainGridDataState, setMainGridDataState] = useState(
    INITIAL_MAIN_GRID_DATA_STATE
  );
  const [selectedState, setSelectedState] = React.useState({});

  const [mainGridResultState, setMainGridResultState] = React.useState(
    mainGridData.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  const _grid = React.useRef();

  const DATA_ITEM_KEY = props.DATA_ITEM_KEY;
  const SELECTED_FIELD = props.SELECTED_FIELD;
  const ClearSelection = props.ClearSelection;
  const idGetter = getter(DATA_ITEM_KEY);

  const pushHeaderCheckBox = (value) => {
    props.OnHeaderSelectChangeEvent(value);
  };

  const onMainGridDataStateChange = (event) => {
    setMainGridDataState(event.dataState);
    var processedDatasource = process(
      mainGridData.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      })),
      event.dataState
    );
    setMainGridResultState(processedDatasource);
  };

  useEffect(() => {
    props.OnGridDataStateEvent(mainGridDataState);
  }, [mainGridDataState]);

  useEffect(() => {
    SelectAll(false);
  }, [props.ClearSelection]);

  const onSelectionChange = React.useCallback(
    (event) => {
      var currentState = selectedState;
      if (event.startColIndex == 0) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);
      }
    },
    [selectedState, mainGridResultState]
  );

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    SelectAll(checked);
  };

  function SelectAll(checked) {
    const newSelectedState = {};
    if (mainGridData.length > 0) {
      mainGridData.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
    }
    setSelectedState(newSelectedState);
    pushHeaderCheckBox(checked);
    props.OnStateEventCallback(newSelectedState);
  }

  //NOTE: This runs when the data gets updated (either on initial page load or new data fetch)
  useEffect(() => {
    setMainGridData(props.data);
    let processedData = process(props.data, INITIAL_MAIN_GRID_DATA_STATE);
    setMainGridResultState(processedData);
    setMainGridDataState(INITIAL_MAIN_GRID_DATA_STATE);
  }, [props.data]);

  useEffect(() => {
    var datasource = process(
      props.data.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      })),
      mainGridDataState
    );
    setMainGridResultState(datasource);
    let headerState =
      mainGridData.length > 0
        ? mainGridData.findIndex((item) => !selectedState[idGetter(item)]) ===
          -1
        : false;
    pushHeaderCheckBox(headerState);
    props.OnStateEventCallback(selectedState);
  }, [selectedState]);

  //NOTE: When a filter is applied, this useEffect hook will kick off to update the aggregates
  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = mainGridDataState.filter;
    let filteredMainGridData = process(mainGridData, filterSettings);

    if (filteredMainGridData.data && props.aggregateColumnSettings) {
      aggregates = aggregateBy(
        filteredMainGridData.data,
        props.aggregateColumnSettings
      );

      if (_grid.current && _grid.current.columns && props.SetExportColumns) {
        props.SetExportColumns(_grid.current.columns);
      }
    }

    // console.log(aggregates);

    props.sendAggregatesParentCallback(aggregates);
  }, [mainGridData, mainGridDataState.filter]);

  return (
    <Grid
      ref={_grid}
      style={{
        height: "37em",
      }}
      data={mainGridResultState}
      filterable={true}
      filterOperators={FILTER_OPERATOR}
      sortable={true}
      sort={mainGridDataState.sort}
      groupable={true}
      pageable={true}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        drag: false,
        cell: false,
        mode: "multiple",
      }}
      onDataStateChange={onMainGridDataStateChange}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
      {...mainGridDataState}
    >
      {props.children}
    </Grid>
  );
}
