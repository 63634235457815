import React, { useContext, useEffect, useState } from "react";
import { withOktaAuth } from "@okta/okta-react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import { ScreenHeader } from "./ScreenHeader/ScreenHeader";
import { ContentHeader } from "./ContentHeader";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";

import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import { getWithExpiry } from "../../utils/localStorageAccess";
import { LiveChat } from "./LiveChat";
import { UserTypes } from "../../constants/UserTypes";
import { OrganizationTypes } from "../../constants/OrganizationTypes";
import { LoadingSpinner } from "./LoadingSpinner";

export default withOktaAuth(function Layout(props) {
  const { oktaAuth, authState } = props;

  const userConfig = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);
  const [showLiveChat, setShowLiveChat] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  async function checkAuthentication() {
    if (authState) {
      const fetchedIsAuthenticated = authState.isAuthenticated;

      if (fetchedIsAuthenticated !== isAuthenticated) {
        setIsAuthenticated(fetchedIsAuthenticated);
        if (authState.accessToken) {
          const userInfo = await oktaAuth.getUser();
          userConfig.onEmailChange(userInfo.email);
          setUser(userInfo);
        } else {
          setUser(null);
        }
      }

      if (fetchedIsAuthenticated === false) {
        navigate("/Login");
      }
    }
  }

  function SetPageTitle() {
    document.title = props.pageTitle == undefined ? "RME" : props.pageTitle;
  }

  // useEffect(() => {
  //   checkAuthentication();
  //   SetPageTitle();
  // }, []);

  useEffect(() => {
    checkAuthentication();
    SetPageTitle();
  });

  async function getMemberConfig() {
    if (isAuthenticated) {
      const userInfo = await oktaAuth.getUser();
      const accessToken = await oktaAuth.getAccessToken();

      userConfig.onEmailChange(userInfo.email);

      let organizationDropdownOptions = await axios.get(
        "api/UserProgram/GetUserDropdownValues",
        {
          params: {
            email: userInfo.email,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const organizations = formatOrganizationDropdownOptions(
        organizationDropdownOptions.data
      );

      userConfig.onOrganizationsChange(organizations);

      const cachedConfig = getWithExpiry("rme-userConfig");

      if (
        cachedConfig &&
        JSON.stringify(organizations) ===
          JSON.stringify(cachedConfig.organizations) &&
        cachedConfig.currentOrganization
      ) {
        userConfig.onCurrentOrganizationChange(
          cachedConfig.currentOrganization
        );
      } else {
        userConfig.onCurrentOrganizationChange(organizations[0]);
      }
    }
  }

  async function getLiveChatAccessConfig() {
    if (isAuthenticated) {
      const userInfo = await oktaAuth.getUser();
      const accessToken = await oktaAuth.getAccessToken();

      userConfig.onEmailChange(userInfo.email);

      let livechatAccess = await axios.get(
        "api/UserProgram/GetLiveChatAccess",
        {
          params: {},
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      setShowLiveChat(livechatAccess.data);
    }
  }

  useEffect(() => {
    try {
      getMemberConfig();
      getLiveChatAccessConfig();
    } catch (e) {
      console.log(e);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    try {
      getMemberConfig();
    } catch (e) {
      console.log(e);
    }
  }, [userConfig.contactType]);

  async function getLocations() {
    if (userConfig.currentOrganization && isAuthenticated) {
      const userInfo = await oktaAuth.getUser();
      const accessToken = await oktaAuth.getAccessToken();

      let currentRoute = location.pathname;

      let allLocationsParameters = {
        email: userInfo.email,
        selectedItem: userConfig.currentOrganization.value,
        organizationType: userConfig.currentOrganization.type,
      };

      let recParameters = {
        email: userInfo.email,
        selectedItem: userConfig.currentOrganization.value,
        organizationType: userConfig.currentOrganization.type,
        programSubscriptions: "Rec2.0",
      };

      let metricParameters = {
        email: userInfo.email,
        selectedItem: userConfig.currentOrganization.value,
        organizationType: userConfig.currentOrganization.type,
        programSubscriptions: "Metric2.0,Metric2.0 Premium",
      };

      let header = {
        Authorization: `Bearer ${accessToken}`,
        RequestSrc: currentRoute,
      };

      //NOTE: Depending on the programSubscription passed here, we'll get a different set of members
      let allLocationsResponse = await axios.get(
        `api/UserProgram/GetUserLocations`,
        {
          params: allLocationsParameters,
          headers: header,
        }
      );

      let allLocations = formatDictionaryToArray(allLocationsResponse.data);
      let allNcpdps = formatDictionaryToNcpdps(allLocationsResponse.data);

      let recLocationsResponse = await axios.get(
        `api/UserProgram/GetUserLocations`,
        {
          params: recParameters,
          headers: header,
        }
      );

      let recLocations = formatDictionaryToArray(recLocationsResponse.data);

      let metricLocationsResponse = await axios.get(
        `api/UserProgram/GetUserLocations`,
        {
          params: metricParameters,
          headers: header,
        }
      );

      let metricLocations = formatDictionaryToArray(
        metricLocationsResponse.data
      );

      try {
        await axios.post(
          "api/UserProgram/UpdateSisense",
          {
            email: userConfig.email,
            locations: Object.keys(allLocationsResponse.data),
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
      } catch (e) {
        console.log("sisense server down");
      }

      userConfig.onLocationsChange(allLocations);
      userConfig.onNcpdpsChange(allNcpdps);

      userConfig.onRecLocationsChange(recLocations);
      userConfig.onMetricLocationsChange(metricLocations);
    }
  }

  async function getPayers() {
    if (userConfig.currentOrganization && isAuthenticated) {
      const userInfo = await oktaAuth.getUser();
      const accessToken = await oktaAuth.getAccessToken();

      let currentRoute = location.pathname;

      let parameters = {
        email: userInfo.email,
        selectedItem: userConfig.currentOrganization.value,
        organizationType: userConfig.currentOrganization.type,
      };

      let header = {
        Authorization: `Bearer ${accessToken}`,
        RequestSrc: currentRoute,
      };

      let payerResponse = await axios.get(
        `api/UserProgram/GetUserLocationsPayers`,
        {
          params: parameters,
          headers: header,
        }
      );

      let payers = formatDictionaryToArray(payerResponse.data);

      let sortedPayers = payers.sort((p1, p2) =>
        p1.label > p2.label ? 1 : p1.label < p2.label ? -1 : 0
      );

      userConfig.onPayersChange(sortedPayers);
    }
  }

  async function getUserSubscription() {
    if (userConfig.currentOrganization && isAuthenticated) {
      const userInfo = await oktaAuth.getUser();
      const accessToken = await oktaAuth.getAccessToken();

      let response = await axios.get(`/api/UserProgram/GetUserSubscription`, {
        params: {
          email: userInfo.email,
        },
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      userConfig.onHasMetricSubscriptionChange(
        response.data.isMetricSubscription
      );
      userConfig.onHasRecSubscriptionChange(response.data.isRecSubscription);
      userConfig.onContactTypeChange(response.data.contactType);
    }
  }

  useEffect(() => {
    // if userConfig.currentOrganization or location.pathname is null or empty, we do not proceed

    if (!userConfig.currentOrganization) return;

    try {
      getUserSubscription();
      getLocations();
      getPayers();
    } catch (e) {
      console.log(e);
    }
  }, [userConfig.currentOrganization, userConfig.isUnrestrictedEraPath]);

  function formatDictionaryToArray(data) {
    return Object.keys(data).map((key) => {
      return {
        label: data[key],
        value: key,
      };
    });
  }

  function formatOrganizationDropdownOptions(data) {
    let mappedItems = [];

    if (data) {
      data.map((x) => {
        let item = {
          value: x.itemId,
          type: x.organizationType,
          labelNoSuffix: x.itemName,
        };

        if (userConfig.contactType === UserTypes.UCT) {
          if (x.organizationType === OrganizationTypes.Group) {
            item.label = x.itemName + " - G";
          } else if (x.organizationType === OrganizationTypes.ParentCompany) {
            item.label = x.itemName + " - P";
          } else {
            item.label = x.itemName;
          }
        } else {
          item.label = x.itemName;
        }

        mappedItems.push(item);
      });

      return mappedItems;
    }
  }

  //NOTE: This is for extracting the NCPDP from the label on the frontend. We know that NCPDPs are always 7 characters long, we know that the label always ends with ")"
  function formatDictionaryToNcpdps(data) {
    return Object.keys(data).map((key) => {
      return {
        label: data[key],
        value: data[key].substring(data[key].length - 8, data[key].length - 1),
      };
    });
  }

  function formatPayers(data) {
    return data.map((x) => {
      return {
        label: x.Name,
        value: x.PBID.toString(),
      };
    });
  }

  if (oktaAuth.authenticated === null || !user) return null;

  const childrenWithProps = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { passedProps: { auth: oktaAuth, user: user } })
  );

  return (
    <AuthContext.Provider value={oktaAuth}>
      <Container className="px-0 d-flex flex-column" fluid="true" id="content">
        <header>
          <ScreenHeader user={user} auth={oktaAuth} />
          <ContentHeader featureTitle={props.featureTitle} />
        </header>

        <Row className="flex-fill d-flex no-gutters flex-nowrap">
          {/* <NavBar label={props.selectedNavbarLabel} /> */}
          <NavBar auth={oktaAuth} />
          <Col className="overflow-auto">
            <LoadingSpinner
              isDataLoading={userConfig.locations == null}
              controlsName={"layoutSpinner"}
            />
            <Row className="d-flex no-gutters flex-column" id="main-content">
              {childrenWithProps}
            </Row>
            <Footer />
            {showLiveChat ? <LiveChat /> : ""}
          </Col>
        </Row>
      </Container>
    </AuthContext.Provider>
  );
});
