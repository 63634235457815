import React, { useState, useEffect } from "react";
import classNames from 'classnames'

import Checkbox from './Checkbox'
export default function MultiSelectDropdownOption(props) {
    const { label, value, disabled, maxReached } = props;

    const [selected, setSelected] = useState(props.selected)
    let className = classNames('multi-select-dropdown-option', { 'selected': selected }, { 'disabled': disabled });

    // only runs when props.selected has changed
    useEffect(() => {
        if (props.selected !== selected) {
            setSelected(props.selected)
        }
    }, [props.selected])

    function handleOptionClick() {
        if (!maxReached) {
            props.handleOptionClick(value)
            setSelected(!selected)
        } else if (maxReached && selected) {
            props.handleOptionClick(value)
            setSelected(!selected)
        }
        else {
            alert("You have reached maximum limit of options to select!")
        }

    }

    return (
        <li>
            <button type="button" className={className} onClick={disabled ? null : handleOptionClick}>
                <Checkbox checked={selected} />
                <span className='multi-select-dropdown-option-label'>{label}</span>
            </button>
        </li>
    )
}