import axios from "axios";

import SingleCheckPDFExporter from "./SingleCheckPDFExporter";
import NetRxLogo from '../assets/NetRxLogo.png';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default async function SingleCheckPdfExportWrapper(pmid, accessToken, setIsLoadingFunction) {
    setIsLoadingFunction(true);

    try {
        const response = await axios.get(`api/Check/GetSingleCheckPdfExport`, {
            params: { PMID: pmid },
            headers: { Authorization: `Bearer ${accessToken}` }
        })

        var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
        var img = new Image();
        img.onload = () => {
            ctx.drawImage(img, 0, 0);

            const data = response.data
            let pdfFileName = "REMITADVICE";
            let docDefinition = SingleCheckPDFExporter(data);
            if (docDefinition) {
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(docDefinition).download(pdfFileName);
                setIsLoadingFunction(false)
            }
        }
        img.src = NetRxLogo;
    } catch (e) {
        setIsLoadingFunction(false);
        console.log(e);
    }
}