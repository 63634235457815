import React, { useEffect, useState, useRef } from 'react';
import MultiSelectDropdown from '../../MultiSelectDropdown/MultiSelectDropdown';

export default function LocationDropDown(props) {
    const { onChange, dataSource, defaultSelected, selectAllOption, deSelectAll } = props
    const [defaultLocations, setDefaultLocations] = useState([]);
    const [selectAll, setSelectAll] = useState([]);
    const multiSelectDropdownRef = useRef(null);
    useEffect(() => {
        setDefaultLocations(defaultSelected)
    }, [defaultSelected]);

    useEffect(() => {
        setSelectAll(selectAllOption)
    }, [selectAllOption]);

    useEffect(() => {
        if (deSelectAll) {
            var allListItem = multiSelectDropdownRef.current.querySelectorAll('.multi-select-dropdown-option ');
            var selectAll = false;
            allListItem.forEach((item) => {
                if (item.className.indexOf('selected') > 0 && selectAll == false) {
                    item.click();
                    if (item.querySelector('span').innerText == 'All') {
                        selectAll = true;
                    }
                }
            })
        }
    }, [deSelectAll]);

    if (dataSource && dataSource.length > 0) {
        return <div ref={multiSelectDropdownRef}><MultiSelectDropdown options={dataSource} onChange={onChange} defaultSelected={defaultLocations} selectAllOption={selectAll} filterable onLoadAllSelected={false} /></div>
    }
    else {
        return <div ref={multiSelectDropdownRef}><MultiSelectDropdown options={dataSource} onChange={onChange} defaultSelected={defaultLocations} selectAllOption={selectAll} filterable onLoadAllSelected={false} /></div>
    }
}