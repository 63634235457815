import React, { useState, useEffect } from "react";

import axios from "axios";

import { RequestExports } from "./RequestExports";
import { ExportBatchHistory } from "./ExportBatchHistory";
import TabBasedFeature from "../../layout/TabBasedFeature";
import { UserContext } from "../../context/UserContext";
import { OrganizationTypes } from "../../../constants/OrganizationTypes";
import NoAccess from "../../auth/NoAccess";
import ParentCompanySelectionForGroupUsers from "./ParentCompanySelectionForGroupUsers";

export default function RemittanceExporting(props) {
  const { passedProps } = props;
  let userContext = React.useContext(UserContext);

  const [parentCompanySelectedByGroupUser, setParentCompanySelectedByGroupUser] = useState(null);
  const [membersSelectedByGroupUser, setMembersSelectedByGroupUser] = useState(null);
  const [payersSelectedByGroupUser, setPayersSelectedByGroupUser] = useState(null);
  const [groupUserHasDeterminedValues, setGroupUserHasDeterminedValues] = useState(false);

  const sendParentCompanyFromGroupUser = (parentCompanyId) => {
    setParentCompanySelectedByGroupUser(parentCompanyId);
  };

  const sendMembersFromGroupUser = (members) => {
    setMembersSelectedByGroupUser(members);
  };

  const sendPayersFromGroupUser = (payers) => {
    setPayersSelectedByGroupUser(payers);
  };

  const resetValuesSelectedByGroupUser = () => {
    setParentCompanySelectedByGroupUser(null);
    setMembersSelectedByGroupUser(null);
    setGroupUserHasDeterminedValues(false);
  };

  useEffect(() => {
    resetValuesSelectedByGroupUser();
  }, [userContext.currentOrganization.value]);

  /*
  NOTE: Okay so from here, we can use spread operator to continue passing the existing passedProps, and then add some additional values on top of it
  From here, those props get passed to the TabBasedFeature, and then from there it gets passed to the actual features themselves.
  This is how we can determine the "user determined RPID" and a "user determined set of members".
  */

  let customPassedProps = {
    ...passedProps,
    parentCompanySetByGroupUser: parentCompanySelectedByGroupUser,
    membersSetByGroupUser: membersSelectedByGroupUser,
    payersSetByGroupUser: payersSelectedByGroupUser,
    resetValuesSelectedByGroupUserCallback: () => resetValuesSelectedByGroupUser()
  };

  useEffect(() => {
    if (parentCompanySelectedByGroupUser && membersSelectedByGroupUser.length > 0) {
      setGroupUserHasDeterminedValues(true);
    }
  }, [parentCompanySelectedByGroupUser, membersSelectedByGroupUser]);

  const [
    isCurrentOrganizationAParentCompany,
    setIsCurrentOrganizationAParentCompany,
  ] = useState(false);

  const [currentOrganizationTrigger, setCurrentOrganizationTrigger] =
    useState(false);

  useEffect(() => {
    if (
      userContext !== null &&
      userContext.currentOrganization !== null &&
      userContext.currentOrganization.type !== null
    ) {
      let isParentCompany =
        userContext.currentOrganization.type ===
        OrganizationTypes.ParentCompany;
      setIsCurrentOrganizationAParentCompany(isParentCompany);

    }
  }, [currentOrganizationTrigger]);

  function DetermineCurrentOrganizationTriggerOnPageLoad() {
    if (!userContext.currentOrganization) return;

    if (!userContext.currentOrganization.value) return;

    if (currentOrganizationTrigger === userContext.currentOrganization.value)
      return;

    setCurrentOrganizationTrigger(userContext.currentOrganization.value);
  }

  DetermineCurrentOrganizationTriggerOnPageLoad();

  let tabs = [
    {
      key: "requestExports",
      title: "Request Exports",
      Component: RequestExports,
    },
    {
      key: "exportBatchHistory",
      title: "Export Batch History",
      Component: ExportBatchHistory,
    },
  ];

  if (!isCurrentOrganizationAParentCompany && !groupUserHasDeterminedValues) {
    return (
      (
        <ParentCompanySelectionForGroupUsers
          setParentCompanyCallback={sendParentCompanyFromGroupUser}
          setMembersCallback={sendMembersFromGroupUser}
          setPayersCallback={sendPayersFromGroupUser}
          passedProps={passedProps}
        />
      )
    );
  }
  else {
    return (
      <TabBasedFeature
        tabs={tabs}
        defaultActiveKey="requestExports"
        passedProps={customPassedProps}
      />
    );
  }
}
