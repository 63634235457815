import React, { useState, useEffect } from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { NavDropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";

import { FormatValue } from "../../helpers/DataFormatHelpers";

import { GetExportFileNameDated } from "./ExportFileName";

export default function GridExport(props) {
  const { exportData, exportDataColumns, exportFileNamePrefix } = props;

  const _mainExport = React.useRef(null);

  // const [mainData, setMainData] = useState(exportData);

  const [csvExportData, setCsvExportData] = useState(exportData);

  const [excelExportColumns, setExcelExportColumns] = useState();

  const [csvExportColumns, setCsvExportColumns] = useState();

  //This will clean up the columns that are not needed for the export

  const ProcessExcelColumns = (cols) => {
    // if cols is null or empty, return empty array
    if (!cols || cols.length === 0) return [];

    let result = [];

    //add an array of columns that should not be included in the export
    let excludeColumns = ["id", "selected", "action", "selectedid"];

    //console.log("ProcessExcelColumns", cols);

    cols.forEach((column) => {
      if (column.field === null) return;

      if (column.field === undefined) return;

      if (column.field.trim() == "") return;

      //if column.field is in the excludeColumns array, then skip this column
      if (excludeColumns.includes(column.field.toLowerCase())) return;

      result.push(column);
    });

    return result;
  };

  const ExportExcel = () => {
    //_mainExport.current.save(exportData, exportDataColumns);
    _mainExport.current.save(exportData, excelExportColumns);
  };

  const FormattedExportData = () => {
    let columnsToFormat = GetColumnsToFormatData();

    //if there are no columns to format, then return original dataset
    if (!columnsToFormat || columnsToFormat.length === 0) {
      return exportData;
    }

    let result = [];

    exportData.map((item) => {
      //make a copy of item so we do not alter the original value
      let thisItem = { ...item };
      columnsToFormat.forEach((column) => {
        let thisKey = column.field;

        const formattedValue = FormatValue(item[thisKey], column.format);
        thisItem = { ...thisItem, [thisKey]: formattedValue };
      });

      result.push(thisItem);
    });

    return result;

    //console.log("formattedData", formattedData);
  };

  //All logic on custom formatting is below, building an array of columns that need to be formatted and the format type tht needs to be applied

  const GetColumnsToFormatData = () => {
    let result = [];

    if (!exportDataColumns || exportDataColumns.length === 0) return result;

    exportDataColumns.forEach((column) => {
      //if column.filter contains the word "date", then format it as a date
      //ignore case when searching for the word "date"
      if (column.filter && column.filter.toLowerCase().includes("date")) {
        result.push({ field: column.field, format: "date" });

        //go to next foreach iteration
        return;
      }

      if (column.filter && column.title.toLowerCase().includes("date")) {
        result.push({ field: column.field, format: "date" });

        //go to next foreach iteration
        return;
      }
    });

    return result;
  };

  const GetCsvColumns = () => {
    let result = [];

    if (!excelExportColumns || excelExportColumns.length === 0) return result;

    // var targetColumns = excelExportColumns.filter(
    //   (t) => t.field != null && t.field != undefined && t.field != ""
    // );

    excelExportColumns.forEach((column) => {
      result.push({ label: column.title, key: column.field });
    });

    return result;
  };

  useEffect(() => {
    let formattedData = FormattedExportData();
    setCsvExportData(formattedData);
  }, [exportData]);

  useEffect(() => {
    //Need to process the colummns and exclude those that should not be on the output
    let processColumns = ProcessExcelColumns(exportDataColumns);
    setExcelExportColumns(processColumns);
  }, [exportDataColumns]);

  useEffect(() => {
    //
    let columns = GetCsvColumns();
    setCsvExportColumns(columns);
  }, [excelExportColumns]);

  let shouldDisable = !csvExportData || csvExportData.length === 0;

  //if exportFilePrefix is null or empty, then use the default name of "NetRxExport", else use the prefix
  let exportFileName = exportFileNamePrefix
    ? exportFileNamePrefix
    : "NetRxExport";

  return (
    <ExcelExport
      ref={_mainExport}
      fileName={GetExportFileNameDated(exportFileName, "xlsx")}
    >
      {
        // if shouldDisable is true, opacity is 0.5, else 1  to disable the export buttons and makethe dropdown appera greyed out
      }
      <span style={{ opacity: shouldDisable ? 0.5 : 1 }}>
        <NavDropdown
          title={
            <span>
              <i className="fa fa-download" aria-hidden></i> Download As...
            </span>
          }
          id="basic-nav-dropdown"
          disabled={shouldDisable}
        >
          <NavDropdown.Item onClick={ExportExcel} href="#">
            Excel
          </NavDropdown.Item>

          <CSVLink
            data={csvExportData}
            //filename={"csvExport.csv"}
            filename={GetExportFileNameDated(exportFileName, "csv")}
            className="dropdown-item"
            headers={csvExportColumns}
            // onClick={GetExportDataTypes}
          >
            CSV
          </CSVLink>
        </NavDropdown>
      </span>
    </ExcelExport>
  );
}
