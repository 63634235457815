import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

export class AccordionSection extends Component {
    // static propTypes = {
    //     parentHover: PropTypes.bool.isRequired,
    //     children: PropTypes.instanceOf(Object),
    //     isOpen: PropTypes.bool.isRequired,
    //     icon: PropTypes.string.isRequired,
    //     label: PropTypes.string.isRequired,
    //     onClick: PropTypes.func.isRequired,
    // };

    
    constructor(props) {
        //NOTE: I think I might need this?
        super(props);

        // this.state = {
        //     openSections:
        //     {
        //         [this.props.selected]: true
        //     },
        //     hover: false,
        // };

        // //NOTE: Binding "this" keyword within the following 3 functions;
        // //Otherwise, "this" comes through as being "undefined" within those 3 functions
        // this.hoverOn = this.hoverOn.bind(this);
        // this.hoverOff = this.hoverOff.bind(this);
        this.onClick = this.onClick.bind(this);
    }


    onClick(){
        this.props.onClick(this.props.label);
    };

    render() {
        const {
            onClick,
            props: { isOpen, icon, label, parentHover },
        } = this;

        var className = "";
        if (this.props.children !== undefined) {
            className = isOpen ? "fa fa-angle-up" : "fa fa-angle-down";
        }

        var isSelected = isOpen ? "accordion-section-selected" : "";

        var accordionSectionClass = parentHover ? "accordion-section-expanded " : "accordion-section-collapsed ";
        accordionSectionClass += isSelected;

        var accordionSection = parentHover ? (
            <div className={accordionSectionClass} onClick={onClick}>
                <i className={icon} />
                <span className="accordion-label">
                    {label}
                </span>
                <span className="accordion-section-arrow">
                    <i className={className}></i>
                </span>
            </div>) : (
                <div className={accordionSectionClass} onClick={onClick}>
                    <i className={icon} />
                </div>
            );

        return this.props.children === undefined ? (
            <div className="accordion-section-wrapper">
                <Link to="/">
                    {accordionSection}
                    {isOpen && parentHover && (<div className="sub-menu">
                        {this.props.children}
                        </div>)}

                </Link>
            </div>
        ): ( 
            <div className="accordion-section-wrapper">
                {accordionSection}
                {isOpen && parentHover && (<div className="sub-menu">
                    {this.props.children}
                </div>)}
            </div>
        );
    }
}