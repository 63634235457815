import React from 'react';
import PropTypes from 'prop-types';
import { Nav} from 'react-bootstrap';

export function CustomAction(props) {
    const { label, onClick } = props;

    return (
        <Nav.Item>
            <Nav.Link onClick={onClick}>
                {label}
            </Nav.Link>
        </Nav.Item>
    )
}